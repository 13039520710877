import React, { useEffect, useRef, useState } from "react";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Button, PreviewCard, Icon } from "../../components/Component";
import Logo from "../../assets/images/logo-COE.png";
import LogoDark from "../../assets/images/logo-COE.png";
import { Form, FormGroup, Spinner, Alert } from "reactstrap";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import api from "../../../jwtAuth";

const ResetPassword = () => {
  const { token } = useParams();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({
    status: null,
    message: "",
  });

  useEffect(() => {
    const response = api.post("/auth/checkAuthCode", { code: token });
    response.then((res) => {
      if (!res.data.success) {
        window.location.href = "/login";
      }
    })
    .catch((err) => {
      window.location.href = "/login";
    });
  }, [token]);

  const onFormSubmit = (formData) => {
    setResponse({
      status: null,
      message: "",
    });
    setLoading(true);
    const data = {
      apikey: process.env.REACT_APP_API_KEY,
      token: token,
      password: formData.password,
      confirmPassword: formData.confirmPassword,
    };
    const response = api.post("/auth/resetPassword", data);
    response.then((res) => {
      if (res.data.success) {
        setResponse({
          status: "success",
          message: res.data.message,
        });
      } else {
        setResponse({
          status: "danger",
          message: res.data.message,
        });
      }
    })
    .catch((err) => {
      setResponse({
        status: "danger",
        message: err.response.data.message,
      });
    })
    .finally(() => {
      setLoading(false);
    });
  };

  const { errors, register, handleSubmit, watch } = useForm();
  const password = useRef({});
  password.current = watch("password", "");
  return (
    <React.Fragment>
      <Head title="Reset Password" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
            </Link>
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Reset password</BlockTitle>
                <BlockDes>
                  <p>Silahkan ganti password dengan yang baru.</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            {response.status !== null && (
              <div className="mb-3">
                <Alert color={`${response.status}`} className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {response.message}
                </Alert>
              </div>
            )}
            <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Password Baru
                  </label>
                </div>
                <input
                  type="password"
                  className="form-control form-control-lg"
                  id="password"
                  name="password"
                  ref={register({ required: "Password wajib diisi", minLength: { value: 6, message: "Password minimal 6 karakter" } })}
                  placeholder="Masukkan Password Baru"
                />
                {errors.password && ( <div className="text-danger">{errors.password.message}</div> )}
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Konfirmasi Password Baru
                  </label>
                </div>
                <input
                  type="password"
                  className="form-control form-control-lg"
                  id="confirmPassword"
                  name="confirmPassword"
                  ref={register({ 
                    required: "Konfirmasi Password wajib diisi", 
                    minLength: { value: 6, message: "Konfirmasi Password minimal 6 karakter"}, 
                    validate: value => value === password.current || "Konfirmasi Password tidak sama dengan Password Baru"
                  })}
                  placeholder="Konfirmasi Password Baru"
                />
                {errors.confirmPassword && ( <div className="text-danger">{errors.confirmPassword.message}</div> )}
              </FormGroup>
              <FormGroup>
                <Button type="submit" color="primary" size="lg" className="btn-block" disabled={loading}>
                  {loading ? <Spinner size="sm" color="light" /> : "Kirim"}
                </Button>
              </FormGroup>
            </Form>
            <div className="form-note-s2 text-center pt-4">
              <Link to={`${process.env.PUBLIC_URL}/login`}>
                <strong>Kembali</strong>
              </Link>
            </div>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default ResetPassword;
