import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import Routes from "./Routes";
import ReactGA from 'react-ga';
import { useTranslation } from "react-i18next";

const TRACKING_ID = "UA-277499595-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const App = (props) => {
  const { i18n } = useTranslation();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  // save language to local storage
  useEffect(() => {
    var lang = localStorage.getItem("lang");
    if (!lang) {
      localStorage.setItem("lang", "id");
      lang = localStorage.getItem("lang");
    }
    i18n.changeLanguage(lang);
  }, [i18n]);

  return (
    <Routes />
  );
};
export default withRouter(App);