import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import api from '../../../jwtAuth';
import logo from '../../assets/images/logo-COE.png'
import moment from 'moment';
import Swal from 'sweetalert2';
import { useTranslation } from "react-i18next";

const WebinarSidebar = ({changeSlug, webinar}) => {
  const { t } = useTranslation();
  const [webinars, setWebinar] = useState([]);

  // Get Data
  async function getWebinar() {
    try {
      const response = await api.get('/public/webinar/?limit=3');
      setWebinar(response.data.data.results);
    } catch (error) {
      if(error.response.status === 404) {
        getWebinar();
      }
    }
  }
  useEffect(() => {
    getWebinar();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDownloadFile = (file) => {
    const response = api.post(`/public/webinar/${webinar._id}/download`, { filename: file.filename }, { responseType: 'blob' }, {
      headers: {
        'Content-Disposition': 'attachment'
      }
    });
    response.then((res) => {
      if(res.status === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers['content-type'] }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file.originalname);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else if(res.status === 401) {
        Swal.fire(
          "Gagal!",
          "Anda tidak memiliki akses untuk mengakses halaman ini.",
          "error"
        );
      } else {
        Swal.fire("Gagal!", "Terjadi kesalahan, silahkan coba lagi.", "error");
      }
    });
  };
  return (
    <div className="widget-area">
      <div className="widget widget_tracer_posts_thumb">
        <h3 className="widget-title">{t("webinar.latest")}</h3>
        {webinars.map((item, index) => (
          <article className="item" key={item._id}>
            <NavLink 
              to={`${item.slug}`} 
              className="thumb"
              onClick={() => changeSlug(item.slug)}
            >
              <img className="fullimage cover" src={`${logo}`} alt="bb" />
            </NavLink>
            <div className="info">
              <span>{moment(item.updatedAt).format('LL')}</span>
              <h4 className="title usmall">
                <NavLink 
                  to={`${item.slug}`}
                  onClick={() => changeSlug(item.slug)}
                >
                  {localStorage.getItem('lang') === "en" ? item.title_en : item.title}
                </NavLink>
              </h4>
            </div>

            <div className="clear"></div>
          </article>
        ))}
      </div>

      <div className="download-file">
        <h3>File</h3>

        <ul>
          {webinar && webinar.files && webinar.files.map((item, index) => (
            <li key={index}>
              <Link to="#" onClick={() => handleDownloadFile(item)}>
                {item.originalname.length > 25 ? item.originalname.substring(0, 25) + "..." : item.originalname} <i className='bx bxs-file-pdf'></i>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default WebinarSidebar