import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import PageBanner from "../components/Common/PageBanner"
import ContactInfo from "../components/Contact/ContactInfo"
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <Layout title={t("contact")}>
      <Navbar />
      <PageBanner
        pageTitle={t("contactus")}
        homePageText={t("home")}
        homePageUrl="/"
        activePageText={t("contactus")}
      />
      <ContactInfo />
      <Footer />
    </Layout>
  )
}

export default Contact;