import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Row,
  Icon,
  Block,
} from "../../../components/Component";
import { Alert, Card } from "reactstrap";
import { Link } from "react-router-dom";
import api from "../../../../jwtAuth";
import Swal from "sweetalert2";

const ResearchDetails = ({ match }) => {
  const [borrowItemData, setBorrowItemData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(1);
  const [borrowing_date, setBorrowingDate] = useState("");
  const [return_date, setReturnDate] = useState("");
  const [stok, setStok] = useState(0);

  const [researcher_name, setResearcherName] = useState("");
  const [institution, setInstitution] = useState("");
  const [faculty, setFaculty] = useState("");
  const [email_option, setEmailOption] = useState("");
  const [research_topic, setResearchTopic] = useState("");
  const [library_use, setLibraryUse] = useState("");
  const [type_data, setTypeData] = useState("");
  const [data_size, setDataSize] = useState("");
  const [duration, setDuration] = useState("");
  const [description, setDescription] = useState("");

  const [errors, setErrors] = useState({});

  // increases quantity number
  const increaseCounter = () => {
    if (counter < borrowItemData.stok) {
      setCounter((prevState) => prevState + 1);
    }
  };

  // decreases quantity number
  const decreaseCounter = () => {
    if (counter !== 0) {
      setCounter((prevState) => prevState - 1);
    }
  };

  // grabs the id of the url and loads the corresponding data
  const getBorrowData = () => {
    const id = match.params.id;
    if (id !== undefined || null || "") {
      const response = api.get(`/user/borrow/${id}`);
      response
        .then((res) => {
          if (res.data.success) {
            setBorrowItemData(res.data.data.borrow_item);
            setUserData(res.data.data.user);
            setStok(res.data.data.stok);
          } else {
            Swal.fire({
              title: "Error!",
              text: res.data.message,
              icon: "error",
              confirmButtonText: "Ok",
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            title: "Oops!",
            text: err.response.data.message,
            icon: "error",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = `${process.env.PUBLIC_URL}/panel/borrow`;
            }
          });
        });
    } else {
      window.location.href = `${process.env.PUBLIC_URL}/pages/404`;
    }
  };

  useEffect(() => {
    getBorrowData();
  }, [match.params.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitHandler = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Apakah anda yakin?",
      text: "Anda akan melakukan pengajuan peminjaman barang ini, pastikan data yang anda masukkan sudah benar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, ajukan sekarang!",
      cancelButtonText: "Batal",
    }).then((confirm) => {
      if (confirm.isConfirmed) {
        setLoading(true);
        const id = match.params.id;
        const response = api.post(`/user/borrow/${id}/borrowing`, {
          total_item: counter,
          borrowing_date,
          return_date,
          email: userData.email,
          researcher_name,
          institution,
          faculty,
          email_option,
          research_topic,
          library_use,
          type_data,
          data_size,
          duration,
          description,
        });
        response
          .then((res) => {
            if (res.data.success) {
              Swal.fire({
                title: "Success!",
                text: res.data.message,
                icon: "success",
                confirmButtonText: "Ok",
              }).then((result) => {
                if (result.isConfirmed) {
                  getBorrowData();
                }
              });
            } else {
              Swal.fire({
                title: "Error!",
                text: res.data.message,
                icon: "error",
                confirmButtonText: "Ok",
              });
            }
          })
          .catch((err) => {
            if (err.response && err.response.data.errors) {
              setErrors(
                err.response.data.errors.reduce((acc, error) => {
                  acc[error.param] = error.msg;
                  return acc;
                }, {})
              );
            }
            Swal.fire({
              title: "Oops!",
              text: err.response.data.message,
              icon: "error",
              confirmButtonText: "Ok",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  return (
    <React.Fragment>
      <Head title="Peminjaman Barang"></Head>
      {borrowItemData && (
        <Content>
          <BlockHead size="sm">
            <BlockBetween className="g-3">
              <BlockHeadContent>
                <BlockTitle>Peminjaman Barang</BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <Link to={`${process.env.PUBLIC_URL}/panel/borrow`}>
                  <Button color="light" outline className="bg-white d-none d-sm-inline-flex">
                    <Icon name="arrow-left"></Icon>
                    <span>Kembali</span>
                  </Button>
                </Link>
                <Link to={`${process.env.PUBLIC_URL}/panel/borrow`}>
                  <Button color="light" outline className="btn-icon bg-white d-inline-flex d-sm-none">
                    <Icon name="arrow-left"></Icon>
                  </Button>
                </Link>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <Block>
            <Card>
              <div className="card-inner">
                <Row className="pb-5">
                  <Col lg={12}>
                    <div className="product-info mt-5 mr-xxl-5">
                      <h2 className="product-title">{borrowItemData.name}</h2>
                      <div className="product-excrept text-soft">
                        <div dangerouslySetInnerHTML={{__html: borrowItemData.description}}/>
                      </div>
                      <div className="product-meta">
                        <div className="row">
                          <div className="col-md-1">
                            <div className="fs-14px text-muted">Stok</div>
                            <div className="fs-16px fw-bold text-secondary">{stok}</div>
                          </div>
                          <div className="col-md-2">
                            <div className="fs-14px text-muted">Tanggal Peminjaman</div>
                            <input
                              type="date"
                              className={`form-control ${errors.borrowing_date ? 'is-invalid' : ''}`}
                              id="borrowing_date"
                              name="borrowing_date"
                              onChange={(e) => setBorrowingDate(e.target.value)}
                            />
                            {errors.borrowing_date && (
                              <div className="invalid-feedback">{errors.borrowing_date}</div>
                            )}
                          </div>
                          <div className="col-md-2">
                            <div className="fs-14px text-muted">Tanggal Pengembalian</div>
                            <input
                              type="date"
                              className={`form-control ${errors.return_date ? 'is-invalid' : ''}`}
                              id="return_date"
                              name="return_date"
                              onChange={(e) => setReturnDate(e.target.value)}
                            />
                            {errors.return_date && (
                              <div className="invalid-feedback">{errors.return_date}</div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="product-meta">
                        <div className="additional-fields">
                          <Row>
                            <Col lg={4}>
                              <div className="form-group mb-3">
                                <div className="fs-14px text-muted">Nama Peneliti *</div>
                                <input
                                  type="text"
                                  className={`form-control ${errors.researcher_name ? 'is-invalid' : ''}`}
                                  value={researcher_name}
                                  onChange={(e) => setResearcherName(e.target.value)}
                                  placeholder={"Nama Peneliti"}
                                />
                                {errors.researcher_name && (
                                  <div className="invalid-feedback">{errors.researcher_name}</div>
                                )}
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="form-group">
                                <div className="fs-14px text-muted">Institusi *</div>
                                <input
                                  type="text"
                                  className={`form-control ${errors.institution ? 'is-invalid' : ''}`}
                                  value={institution}
                                  onChange={(e) => setInstitution(e.target.value)}
                                  placeholder={"Nama Institusi"}
                                />
                                {errors.institution && (
                                  <div className="invalid-feedback">{errors.institution}</div>
                                )}
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="form-group">
                                <div className="fs-14px text-muted">Fakultas</div>
                                <input
                                  type="text"
                                  className={`form-control ${errors.faculty ? 'is-invalid' : ''}`}
                                  value={faculty}
                                  onChange={(e) => setFaculty(e.target.value)}
                                  placeholder={"Nama Fakultas"}
                                />
                                {errors.faculty && (
                                  <div className="invalid-feedback">{errors.faculty}</div>
                                )}
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form-group mb-3">
                                <div className="fs-14px text-muted">Email Lain</div>
                                <input
                                  type="email"
                                  className={`form-control ${errors.email_option ? 'is-invalid' : ''}`}
                                  value={email_option}
                                  onChange={(e) => setEmailOption(e.target.value)}
                                  placeholder={"Email opsional selain email yang digunakan untuk login"}
                                />
                                {errors.email_option && (
                                  <div className="invalid-feedback">{errors.email_option}</div>
                                )}
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form-group">
                                <div className="fs-14px text-muted">Topik Penelitian *</div>
                                <input
                                  type="text"
                                  className={`form-control ${errors.research_topic ? 'is-invalid' : ''}`}
                                  value={research_topic}
                                  onChange={(e) => setResearchTopic(e.target.value)}
                                  placeholder={"Nama Topik Riset"}
                                />
                                {errors.research_topic && (
                                  <div className="invalid-feedback">{errors.research_topic}</div>
                                )}
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form-group">
                                <div className="fs-14px text-muted">Library yang digunakan *</div>
                                <input
                                  type="text"
                                  className={`form-control ${errors.library_use ? 'is-invalid' : ''}`}
                                  value={library_use}
                                  onChange={(e) => setLibraryUse(e.target.value)}
                                  placeholder={"Contoh: keras, tensorflow, scikit..."}
                                />
                                {errors.library_use && (
                                  <div className="invalid-feedback">{errors.library_use}</div>
                                )}
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form-group mb-3">
                                <div className="fs-14px text-muted">Jenis Data *</div>
                                <input
                                  type="text"
                                  className={`form-control ${errors.type_data ? 'is-invalid' : ''}`}
                                  value={type_data}
                                  onChange={(e) => setTypeData(e.target.value)}
                                  placeholder={"Contoh: csv, excel, sql..."}
                                />
                                {errors.type_data && (
                                  <div className="invalid-feedback">{errors.type_data}</div>
                                )}
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form-group mb-3">
                                <div className="fs-14px text-muted">Ukuran Data *</div>
                                <input
                                  type="text"
                                  className={`form-control ${errors.data_size ? 'is-invalid' : ''}`}
                                  value={data_size}
                                  onChange={(e) => setDataSize(e.target.value)}
                                  placeholder={"Contoh: 200mb, 2TB..."}
                                />
                                {errors.data_size && (
                                  <div className="invalid-feedback">{errors.data_size}</div>
                                )}
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form-group">
                                <div className="fs-14px text-muted">Durasi *</div>
                                <input
                                  type="text"
                                  className={`form-control ${errors.duration ? 'is-invalid' : ''}`}
                                  value={duration}
                                  onChange={(e) => setDuration(e.target.value)}
                                  placeholder={"Contoh: 3 Tahun"}
                                />
                                {errors.duration && (
                                  <div className="invalid-feedback">{errors.duration}</div>
                                )}
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className="form-group mb-3">
                                <div className="fs-14px text-muted">Deskripsi</div>
                                <textarea
                                  className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                                  value={description}
                                  onChange={(e) => setDescription(e.target.value)}
                                  placeholder={"Deskripsi peminjaman atau penelitian."}
                                />
                                {errors.description && (
                                  <div className="invalid-feedback">{errors.description}</div>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <ul className="d-flex flex-wrap ailgn-center g-2 pt-1">
                          <li className="w-140px">
                            <div className="form-control-wrap number-spinner-wrap">
                              <Button
                                color="light"
                                outline
                                className="btn-icon number-spinner-btn number-minus"
                                onClick={() => decreaseCounter()}
                              >
                                <Icon name="minus"></Icon>
                              </Button>
                              <input
                                type="number"
                                className={`form-control number-spinner ${errors.total_item ? 'is-invalid' : ''}`}
                                value={counter}
                                min={1}
                                max={borrowItemData.stok}
                                onChange={(e) => setCounter(Number(e.target.value))}
                              />
                              <Button
                                color="light"
                                outline
                                className="btn-icon number-spinner-btn number-plus"
                                onClick={() => increaseCounter()}
                              >
                                <Icon name="plus"></Icon>
                              </Button>
                              {errors.total_item && (
                                <div className="invalid-feedback">{errors.total_item}</div>
                              )}
                            </div>
                          </li>
                          {userData.profile && userData.address && userData.education && (
                            userData.profile.phone === "" ||
                            userData.profile.dob === "" ||
                            userData.profile.gender === "" ||
                            userData.address.address === "" ||
                            userData.address.province === "" ||
                            userData.address.city === "" ||
                            userData.address.district === "" ||
                            userData.address.postalCode === "" ||
                            userData.education.identity_number === "" ? (
                              <div>
                                <Alert className="alert-icon" color="danger">
                                  <Icon name="cross-circle"/>
                                  Lengkapi data diri anda terlebih dahulu. Klik <Link
                                  to={`${process.env.PUBLIC_URL}/panel/profile`}>disini</Link> untuk melengkapi data
                                  diri.
                                </Alert>
                              </div>
                            ) : (
                              <li>
                                <Button color="primary" onClick={submitHandler} disabled={loading}>Ajukan
                                  Peminjaman</Button>
                              </li>
                            )
                          )}
                        </ul>
                      </div>

                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Block>
        </Content>
      )}
    </React.Fragment>
  );
};

export default ResearchDetails;
