import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import UserStatistics from "./UserStatistics";
import WebStatistics from "./WebStatistics";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Row,
  Col,
  BlockBetween,
} from "../../../components/Component";
import api from "../../../../jwtAuth";

const Homepage = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const response = api.get("/admin/dashboard");
    response.then((res) => {
      setData(res.data.data);
    })
    .catch((err) => {
      if(err.response.status === 401){
        window.location.href = "/panel";
      }
    });
  }, []);
  return (
    <React.Fragment>
      <Head title="Dashboard Admin"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                Dashboard
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row className="g-gs">
            <Col xxl="3" md="6">
              <UserStatistics data={data} />
            </Col>
            <Col xxl="3" md="6">
              <WebStatistics data={data} />
            </Col>
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default Homepage;