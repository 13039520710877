import React from 'react'
import udinus from '../../../assets/images/partner/udinus.png'
import dikti from '../../../assets/images/partner/dikti.png'
import kedaireka from '../../../assets/images/partner/kedaireka.png'
import nvidia from '../../../assets/images/partner/nvidia.png'

const Partner = () => {
    return (
        <div className="partner-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                        <div className="wow fadeInUp" data-wow-delay=".1s">
                            <div className="single-partner-item">
                                <img src={udinus} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                        <div className="wow fadeInUp" data-wow-delay=".2s">
                            <div className="single-partner-item">
                                <img src={dikti} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                        <div className="wow fadeInUp" data-wow-delay=".3s">
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <img src={kedaireka} alt="about" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                        <div className="wow fadeInUp" data-wow-delay=".4s">
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <img src={nvidia} alt="about" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Partner