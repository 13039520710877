import React from 'react'
import {Link} from 'react-router-dom'
import logo from "../../assets/images/logo-COE.png"
import footerMap from "../../assets/images/footer-map.png"
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();
    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-area bg-color">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <Link to={`${process.env.PUBLIC_URL}/`} className="logo">
                                <img src={logo} width="194px" height="100px" alt="logo" />
                            </Link>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p> */}

                            {/* <ul className="social-link">
                                <li>
                                    <Link to="https://www.facebook.com/" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-facebook'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://twitter.com/" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-twitter'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.instagram.com/" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-instagram'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.linkedin.com/" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-linkedin'></i>
                                    </Link>
                                </li>
                            </ul> */}
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-footer-widget pl-5">
                            <h3>Explore</h3>
                            
                            <ul className="footer-links-list">
                                <li>
                                    <Link to={`${process.env.PUBLIC_URL}/`}>
                                        {t("home")}
                                    </Link>
                                </li>
                                    <li>
                                    <Link to={`${process.env.PUBLIC_URL}/tentang-kami/profil`}>
                                        {t("profile")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`${process.env.PUBLIC_URL}/tentang-kami/fasilitas`}>
                                        {t("facilities")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`${process.env.PUBLIC_URL}/berita`}>
                                        {t("news")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`${process.env.PUBLIC_URL}/kontak-kami`}>
                                        {t("contactus")}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Resources</h3>

                            <ul className="footer-links-list">
                                <li>
                                    <Link to={`${process.env.PUBLIC_URL}/pelatihan`}>
                                        {t("training")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`${process.env.PUBLIC_URL}/agenda/webinar`}>
                                        {t("webinar")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`${process.env.PUBLIC_URL}/dataset`}>
                                        {t("dataset")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/e-health">
                                        {t("ehealth")}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>{t("address")}</h3>

                            <ul className="footer-contact-info">
                                <li>
                                    <i className='bx bx-map'></i> 
                                    Jl. Yudistira No.05, Pendrikan Kidul, Kec. Semarang Tengah, Kota Semarang, Jawa Tengah 50131
                                </li>
                                <li>
                                    <i className='bx bx-phone-call'></i>
                                    +62 856-4144-7936
                                </li>
                                <li>
                                    <i className='bx bx-envelope'></i>
                                    <a href="mailto:centerofexcellence@coe.dinus.ac.id">centerofexcellence@coe.dinus.ac.id</a>
                                </li>                                
                            </ul>
                        </div>
                    </div>

                    <div style={{visibility: "hidden"}}>
                        <a href="https://pelajar.net" rel="dofollow">Pelajar</a>
                    </div>
                </div>

                <div className="footer-bottom-area">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-md-6">
                            <p>Copyright © {currentYear} 
                            <strong> Center of Excellence Universitas Dian Nuswantoro</strong>
                            </p>
                        </div>                       
                    </div>
                </div>
            </div>

            <div className="footer-map">
                <img src={footerMap} alt="footer-logo" />
            </div>
        </footer>
    );
}

export default Footer;