import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import PrivateRoute from "./admin/route/PrivateRoute";
import { RedirectAs404 } from "./admin/utils/Utils";
import Layout from "./admin/layout/Index";

import Login from "./admin/pages/auth/Login";
import Register from "./admin/pages/auth/Register";
import ForgotPassword from "./admin/pages/auth/ForgotPassword";
import ResetPassword from "./admin/pages/auth/ResetPassword";
import Verify from "./admin/pages/auth/Verify";

//Home utama
import Profil from "./landing/pages/about/Profil";
import Homes from "./landing/pages/Index";
import Fasilitas from "./landing/pages/about/Fasilitas";
import DalamNegeri from './landing/pages/kerjasama/DalamNegeri';
import LuarNegeri from './landing/pages/kerjasama/LuarNegeri';
import Kunjungan from './landing/pages/agenda/Kunjungan';
import Webinar from './landing/pages/agenda/webinar/Webinar';
import DetailWebinar from './landing/pages/agenda/webinar/DetailWebinar';
import Berita from "./landing/pages/berita/Berita";
import DetailBerita from "./landing/pages/berita/DetailBerita";
import Contact from './landing/pages/Contact'
import Dataset from "./landing/pages/dataset/Dataset";
import DatasetDetail from "./landing/pages/dataset/DatasetDetail";
import SmartCity from "./landing/pages/penelitian/SmartCity";
import Bawang from "./landing/pages/penelitian/Bawang";
import EHealth from "./landing/pages/penelitian/EHealth";
import EUMKM from "./landing/pages/penelitian/EUMKM";
import GreenEnv from "./landing/pages/penelitian/GreenEnv";
import Pelatihan from "./landing/pages/pelatihan/Pelatihan";
import DetailPelatihan from "./landing/pages/pelatihan/DetailPelatihan";
import Publikasi from "./landing/pages/publikasi/Publikasi";
import PublikasiDetail from "./landing/pages/publikasi/PublikasiDetail";
import api from "./jwtAuth";

const Routes = () => {
  api.post("/auth/generateToken");
  return (
    <Switch>

      {/* Landing Pages */}
      <Route exact path={`${process.env.PUBLIC_URL}/`} component={Homes}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/tentang-kami/profil`} component={Profil}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/tentang-kami/fasilitas`} component={Fasilitas}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/agenda/webinar`} component={Webinar}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/agenda/webinar/:slug`} component={DetailWebinar}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/agenda/kunjungan`} component={Kunjungan}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/pelatihan`} component={Pelatihan}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/pelatihan/:slug`} component={DetailPelatihan}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/berita`} component={Berita}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/berita/:slug`} component={DetailBerita}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/kontak-kami`} component={Contact}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/dataset`} component={Dataset}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/dataset/:slug`} component={DatasetDetail}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/publikasi`} component={Publikasi}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/publikasi/:slug`} component={PublikasiDetail}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/penelitian/bawang`} component={Bawang}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/penelitian/smart-city`} component={SmartCity}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/penelitian/e-health`} component={EHealth}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/penelitian/e-umkm`} component={EUMKM}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/penelitian/green-environment`} component={GreenEnv}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/kerjasama/dalam-negeri`} component={DalamNegeri}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/kerjasama/luar-negeri`} component={LuarNegeri}></Route>

      {/* Auth Pages */}
      <Route exact path={`${process.env.PUBLIC_URL}/verify/:token`} component={Verify}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/forgot-password`} component={ForgotPassword}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/reset-password/:token`} component={ResetPassword}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/register`} component={Register}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/login`} component={Login}></Route>

      {/*Admin Routes*/}
      <PrivateRoute exact path={`${process.env.PUBLIC_URL}/admin*`} component={Layout}></PrivateRoute>
      <PrivateRoute exact path={`${process.env.PUBLIC_URL}/panel*`} component={Layout}></PrivateRoute>
      <Route component={RedirectAs404}></Route>

    </Switch>
  );
};
export default withRouter(Routes);
