import React from "react"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import Layout from "../../components/App/Layout"
import PageBanner from "../../components/Common/PageBanner"
import ListDataset from "../../components/Publikasi/Publikasi"
import { useTranslation } from "react-i18next";

const Publikasi = () => {
  const { t } = useTranslation();
  return (
    <Layout title={t("publication")}>
      <Navbar />
      <PageBanner
        pageTitle={t("publication")}
        homePageText={t("home")}
        homePageUrl="/"
        activePageText={t("publication")}
      />
      <ListDataset />
      <Footer />
    </Layout>
  )
}

export default Publikasi;