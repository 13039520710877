import React, { useEffect, useState } from "react";
import {
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  DropdownItem,
  Form,
  Spinner,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Col,
  PaginationComponent,
  Button,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  PreviewAltCard,
} from "../../../components/Component";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import api from "../../../../jwtAuth";
import Swal from "sweetalert2";

const CategoryList = () => {
  let userData = [];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sm, updateSm] = useState(false);
  const [onSearchText] = useState("");
  const [modal, setModal] = useState({
    edit: false,
    add: false,
    delete: false,
  });
  const [editId, setEditedId] = useState();
  const [deleteId, setDeleteId] = useState();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    slug: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(10);

  // Get Data
  const fetchData = async () => {
    try {
      const response = await api.get("/admin/categories");
      if (response.data.success) {
        userData = response.data.data;
        setData([...userData]);
      } else {
        Swal.fire({
          title: "Oops!",
          text: response.data.message,
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      if(error.response.status === 401) {
        Swal.fire({
          title: "Oops!",
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/";
          }
        });
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // unselects the data on mount
  useEffect(() => {
    let newData;
    newData = userData.map((item) => {
      item.checked = false;
      return item;
    });
    setData([...newData]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = userData.filter((item) => {
        return (
          item.name.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.description.toLowerCase().includes(onSearchText.toLowerCase()) || 
          item.slug.toLowerCase().includes(onSearchText.toLowerCase())
        );
      });
      setData([...filteredObject]);
    } else {
      setData([...userData]);
    }
  }, [onSearchText, setData]); // eslint-disable-line react-hooks/exhaustive-deps

  // function to change the selected property of an item
  const onSelectChange = (e, id) => {
    let newData = data;
    let index = newData.findIndex((item) => item._id === id);
    newData[index].checked = e.currentTarget.checked;
    setData([...newData]);
  };

  // function to reset the form
  const resetForm = () => {
    setFormData({
      name: "",
      description: "",
      slug: "",
    });
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ edit: false, add: false }, { delete: false });
    resetForm();
  };

  // submit function to add a new item
  const onFormSubmit = (submitData) => {
    setLoading(true);
    const { name, description, slug } = submitData;
    let submittedData = {
      name: name,
      description: description,
      slug: slug
    };
    const response = api.post("/admin/categories", submittedData);
    response.then((result) => {
      if (result.data.success) {
        setLoading(false);
        setModal({ add: false });
        resetForm();
        fetchData();
        Swal.fire({
          title: "Success!",
          text: result.data.message,
          icon: "success",
          confirmButtonText: "Ok",
        });
      } else {
        setLoading(false);
        Swal.fire({
          title: "Oops!",
          text: result.data.message,
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    })
    .catch((error) => {
      if(error.response.status === 401) {
        Swal.fire({
          title: "Oops!",
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/";
          }
        });
      }
    });
  };

  // submit function to update a new item
  const onEditSubmit = (submitData) => {
    setLoading(true);
    const { name, slug, description } = submitData;
    let submittedData;
    let newitems = data;
    newitems.forEach((item) => {
      if (item._id === editId) {
        submittedData = {
          name: name,
          description: description,
          slug: slug,
        };
      }
    });
    const response = api.put("/admin/categories/" + editId, submittedData);
    response.then((result) => {
      if (result.data.success) {
        setLoading(false);
        setModal({ edit: false });
        resetForm();
        fetchData();
        Swal.fire({
          title: "Success!",
          text: result.data.message,
          icon: "success",
          confirmButtonText: "Ok",
        });
      } else {
        setLoading(false);
        Swal.fire({
          title: "Oops!",
          text: result.data.message,
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    })
  };

  // function that loads the want to editted data
  const onEditClick = (id) => {
    data.forEach((item) => {
      if (item._id === id) {
        setFormData({
          name: item.name,
          description: item.description,
          slug: item.slug,
        });
        setModal({ edit: true }, { add: false }, { delete: false });
        setEditedId(id);
      }
    });
  };

  const onDeleteClick = (id) => {
    setModal({ delete: true }, { add: false }, { edit: false });
    setDeleteId(id);
  }

  // function to change to suspend property for an item
  const onDeleteSubmit = () => {
    setLoading(true);
    let id = deleteId;
    const response = api.delete("/admin/categories/" + id);
    response.then((result) => {
      if (result.data.success) {
        setLoading(false);
        setModal({ delete: false });
        fetchData();
        Swal.fire({
          title: "Success!",
          text: result.data.message,
          icon: "success",
          confirmButtonText: "Ok",
        });
      } else {
        setLoading(false);
        Swal.fire({
          title: "Oops!",
          text: result.data.message,
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    })
  };

  // function to change the check property of an item
  const selectorCheck = (e) => {
    let newData;
    newData = data.map((item) => {
      item.checked = e.currentTarget.checked;
      return item;
    });
    setData([...newData]);
  };

  // function to delete the seletected item
  const selectorDeleteCategory = () => {
    let newData;
    newData = data.filter((item) => item.checked !== true);
    setData([...newData]);
  };

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const { errors, register, handleSubmit } = useForm();

  return (
    <React.Fragment>
      <Head title="Kategori"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Kategori
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button color="primary" className="btn-icon" onClick={() => setModal({ add: true })}>
                        <Icon name="plus"></Icon>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <div className="nk-tb-list is-separate is-medium mb-3">
            <DataTableHead className="nk-tb-item">
              <DataTableRow className="nk-tb-col-check">
                <div className="custom-control custom-control-sm custom-checkbox notext">
                  <input
                    type="checkbox"
                    className="custom-control-input form-control"
                    onChange={(e) => selectorCheck(e)}
                    id="uid"
                  />
                  <label className="custom-control-label" htmlFor="uid"></label>
                </div>
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text">Nama</span>
              </DataTableRow>
              <DataTableRow size="mb">
                <span className="sub-text">Deskripsi</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Slug</span>
              </DataTableRow>
              <DataTableRow className="nk-tb-col-tools text-right">
                <UncontrolledDropdown>
                  <DropdownToggle color="tranparent" className="dropdown-toggle btn btn-icon btn-trigger mr-n1">
                    <Icon name="more-h"></Icon>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <ul className="link-list-opt no-bdr">
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#"
                          onClick={(ev) => {
                            ev.preventDefault();
                            selectorDeleteCategory();
                          }}
                        >
                          <Icon name="trash"></Icon>
                          <span>Hapus Terpilih</span>
                        </DropdownItem>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </DataTableRow>
            </DataTableHead>
            {/*Head*/}
            {currentItems.length > 0
              ? currentItems.map((item) => (
                  <DataTableItem key={item._id}>
                    <DataTableRow className="nk-tb-col-check">
                      <div className="custom-control custom-control-sm custom-checkbox notext">
                        <input
                          type="checkbox"
                          className="custom-control-input form-control"
                          defaultChecked={item.checked}
                          id={item._id + "uid1"}
                          key={Math.random()}
                          onChange={(e) => onSelectChange(e, item._id)}
                        />
                        <label className="custom-control-label" htmlFor={item._id + "uid1"}></label>
                      </div>
                    </DataTableRow>
                    <DataTableRow>
                      <Link to={`#`}>
                        <div className="user-card">
                          <div className="user-info">
                            <span className="tb-lead">
                              {item.name} <span className="dot dot-success d-md-none ml-1"></span>
                            </span>
                          </div>
                        </div>
                      </Link>
                    </DataTableRow>
                    <DataTableRow size="mb">
                      <span className="tb-amount">
                        {item.description}
                      </span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span>{item.slug}</span>
                    </DataTableRow>
                    <DataTableRow className="nk-tb-col-tools">
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu right>
                              <ul className="link-list-opt no-bdr">
                                <li onClick={() => onEditClick(item._id)}>
                                  <DropdownItem
                                    tag="a"
                                    href="#edit"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                    }}
                                  >
                                    <Icon name="edit"></Icon>
                                    <span>Edit</span>
                                  </DropdownItem>
                                </li>
                                <React.Fragment>
                                  <li className="divider"></li>
                                  <li onClick={() => onDeleteClick(item._id)}>
                                    <DropdownItem
                                      tag="a"
                                      href="#delete"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="trash"></Icon>
                                      <span>Hapus Kategori</span>
                                    </DropdownItem>
                                  </li>
                                </React.Fragment>
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>
                      </ul>
                    </DataTableRow>
                  </DataTableItem>
                ))
              : null}
          </div>
          <PreviewAltCard>
            {currentItems.length > 0 ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={data.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : (
              <div className="text-center">
                <span className="text-silent">Tidak ada data ditemukan</span>
              </div>
            )}
          </PreviewAltCard>
        </Block>

        <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#close"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Tambah Kategori</h5>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Nama Kategori</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder="Masukkan Nama Kategori"
                        ref={register({ required: "Nama Kategori wajib diisi" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Slug </label>
                      <input
                        className="form-control"
                        type="text"
                        name="slug"
                        placeholder="Masukkan Slug (Opsional)"
                        ref={register({ })}
                      />
                      {errors.slug && <span className="invalid">{errors.slug.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">Deskripsi</label>
                      <input
                        className="form-control"
                        type="text"
                        name="description"
                        placeholder="Masukkan Deskripsi (Opsional)"
                        ref={register({ })}
                      />
                      {errors.description && <span className="invalid">{errors.description.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit" disabled={loading}>
                          {loading ? <Spinner size="sm" color="light" /> : "Tambah"}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Batal
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Perbarui Kategori</h5>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={handleSubmit(onEditSubmit)}>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Nama Kategori</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={formData.name}
                        placeholder="Masukkan Nama Kategori"
                        ref={register({ required: "Nama Kategori wajib diisi" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Slug</label>
                      <input
                        className="form-control"
                        type="text"
                        name="slug"
                        defaultValue={formData.slug}
                        placeholder="Masukkan Slug (Opsional)"
                        ref={register({ })}
                      />
                      {errors.slug && <span className="invalid">{errors.balance.slug}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">Deskripsi</label>
                      <input
                        className="form-control"
                        type="text"
                        name="description"
                        defaultValue={formData.description}
                        placeholder="Masukkan Deskripsi"
                        ref={register({ })}
                      />
                      {errors.description && <span className="invalid">{errors.description.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit" disabled={loading}>
                          {loading ? <Spinner size="sm" color="light" /> : "Perbarui"}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Batal
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={modal.delete} toggle={() => setModal({ delete: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Hapus Kategori</h5>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={handleSubmit(onDeleteSubmit)}>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">Apakah Anda yakin ingin menghapus kategori ini?</label>
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="danger" size="md" type="submit" disabled={loading}>
                          {loading ? <Spinner size="sm" color="light" /> : "Hapus"}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Batal
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default CategoryList;
