import React, { Suspense, useLayoutEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { RedirectAs404 } from "../utils/Utils";

import Homepage from "../pages/admin/dashboard/Homepage";
import CategoryList from "../pages/admin/news/CategoryList";
import NewsList from "../pages/admin/news/NewsList";
import NewsAdd from "../pages/admin/news/NewsAdd";
import NewsEdit from "../pages/admin/news/NewsEdit";
import DatasetList from "../pages/admin/datasets/DatasetList";
import DatasetAdd from "../pages/admin/datasets/DatasetAdd";
import DatasetEdit from "../pages/admin/datasets/DatasetEdit";
import UserList from "../pages/admin/users/UsersList";
import UserAdd from "../pages/admin/users/UsersAdd";
import UserEdit from "../pages/admin/users/UsersEdit";
import WebinarList from "../pages/admin/webinars/WebinarList";
import WebinarAdd from "../pages/admin/webinars/WebinarAdd";
import WebinarEdit from "../pages/admin/webinars/WebinarEdit";
import RolesList from "../pages/admin/roles/RolesList";
import RolesAdd from "../pages/admin/roles/RolesAdd";
import RolesEdit from "../pages/admin/roles/RolesEdit";
import ResearchPeriodsList from "../pages/admin/researchPeriods/ResearchPeriodsList";
import ResearchPeriodsAdd from "../pages/admin/researchPeriods/ResearchPeriodsAdd";
import ResearchPeriodsEdit from "../pages/admin/researchPeriods/ResearchPeriodsEdit";
import ResearchList from "../pages/admin/research/ResearchList";
import ResearchAdd from "../pages/admin/research/ResearchAdd";
import ResearchEdit from "../pages/admin/research/ResearchEdit";
import BorrowItemList from "../pages/admin/borrowItems/BorrowItemList";
import BorrowItemAdd from "../pages/admin/borrowItems/BorrowItemAdd";
import BorrowItemEdit from "../pages/admin/borrowItems/BorrowItemEdit";
import BorrowingList from "../pages/admin/borrowing/BorrowingList";
import BorrowingEdit from "../pages/admin/borrowing/BorrowingEdit";
import TrainingList from "../pages/admin/training/TrainingList";
import TrainingAdd from "../pages/admin/training/TrainingAdd";
import TrainingEdit from "../pages/admin/training/TrainingEdit";
import PublicationList from "../pages/admin/publication/PublicationList";
import PublicationAdd from "../pages/admin/publication/PublicationAdd";
import PublicationEdit from "../pages/admin/publication/PublicationEdit";
import InternshipList from "../pages/admin/internship/InternshipList";
import InternshipEdit from "../pages/admin/internship/InternshipEdit";

import UserProfileLayout from "../pages/panel/user-profile/UserProfileLayout";
import UserResearchList from "../pages/panel/research/ResearchList";
import UserResearchDetail from "../pages/panel/research/ResearchDetails";
import UserResearchHistory from "../pages/panel/research/ResearchHistory";
import UserBorrowList from "../pages/panel/borrow/BorrowList";
import UserBorrowDetail from "../pages/panel/borrow/BorrowDetails";
import UserBorrowHistory from "../pages/panel/borrow/BorrowHistory";
import UserTrainingHistory from "../pages/panel/training/TrainingHistory";
import UserInternshipHistory from "../pages/panel/internship/InternshipHistory";
import UserInternshipRegister from "../pages/panel/internship/InternshipRegister";

import HomePageUser from "../pages/panel/Homepage";
const Pages = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/news`} component={NewsList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/news/add`} component={NewsAdd}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/news/:id/edit`} component={NewsEdit}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/news/categories`} component={CategoryList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/datasets`} component={DatasetList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/datasets/add`} component={DatasetAdd}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/datasets/:id/edit`} component={DatasetEdit}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/users`} component={UserList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/users/add`} component={UserAdd}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/users/:id/edit`} component={UserEdit}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/webinars`} component={WebinarList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/webinars/add`} component={WebinarAdd}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/webinars/:id/edit`} component={WebinarEdit}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/roles`} component={RolesList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/roles/add`} component={RolesAdd}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/roles/:id/edit`} component={RolesEdit}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/research/periods`} component={ResearchPeriodsList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/research/periods/add`} component={ResearchPeriodsAdd}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/research/periods/:id/edit`} component={ResearchPeriodsEdit}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/research`} component={ResearchList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/research/add`} component={ResearchAdd}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/research/:id/edit`} component={ResearchEdit}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/borrow`} component={BorrowItemList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/borrow/add`} component={BorrowItemAdd}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/borrow/:id/edit`} component={BorrowItemEdit}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/borrowing`} component={BorrowingList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/borrowing/:id/edit`} component={BorrowingEdit}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/training`} component={TrainingList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/training/add`} component={TrainingAdd}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/training/:id/edit`} component={TrainingEdit}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/publication`} component={PublicationList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/publication/add`} component={PublicationAdd}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/publication/:id/edit`} component={PublicationEdit}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/internship`} component={InternshipList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin/internship/:id/edit`} component={InternshipEdit}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/admin`} component={Homepage}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/panel`} component={HomePageUser}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/panel/profile`} component={UserProfileLayout}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/panel/profile/security`} component={UserProfileLayout}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/panel/research`} component={UserResearchList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/panel/research/history`} component={UserResearchHistory}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/panel/research/:id`} component={UserResearchDetail}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/panel/borrow`} component={UserBorrowList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/panel/borrow/history`} component={UserBorrowHistory}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/panel/borrow/:id`} component={UserBorrowDetail}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/panel/training/history`} component={UserTrainingHistory}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/panel/internship`} component={UserInternshipHistory}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/panel/internship/register`} component={UserInternshipRegister}></Route>
        <Route component={RedirectAs404}></Route>
      </Switch>
    </Suspense>
  );
};
export default Pages;