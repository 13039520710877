import React from 'react'
import {Link} from 'react-router-dom'
import logo from "../../assets/images/logo-COE.png"
import footerMap from "../../assets/images/footer-map.png"
import {useTranslation} from "react-i18next";

const Footer = () => {
  const {t} = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer-area bg-color">
      <div className="container-fluid px-lg-5">
        <div className="row px-lg-5">
          <div className="col-lg-3 col-sm-6">
            <div className="single-footer-widget">
              <Link to={`${process.env.PUBLIC_URL}/`} className="logo">
                <img src={logo} width="194px" height="100px" alt="logo"/>
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-footer-widget">
              <h3>{t("address")}</h3>

              <ul className="footer-contact-info">
                <li>
                  <i className='bx bx-map'></i>
                  Jl. Yudistira No.05, Pendrikan Kidul, Kec. Semarang Tengah, Kota Semarang, Jawa Tengah 50131
                </li>
                <li>
                  <i className='bx bx-phone-call'></i>
                  +62 822-2046-3477
                </li>
                <li>
                  <i class='bx bxl-instagram'></i>
                  <a href="">coe_udinus</a>
                </li>
                <li>
                  <i class='bx bxl-linkedin'></i>
                  <a href="">Udinus Center of Excellence</a>
                </li>
                <li>
                  <i className='bx bx-envelope'></i>
                  <a href="mailto:support@coe.dinus.ac.id">support@coe.dinus.ac.id</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-5 col-sm-6">
            <div className="single-footer-widget">
            <h3>Kirim Pesan</h3>
              <form>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <input type="text" className="form-control form-control-lg" placeholder="Name" required/>
                  </div>
                  <div className="form-group col-md-6">
                    <input type="text" className="form-control form-control-lg mb-4" placeholder="Email"/>
                  </div>
                </div>
                <div className="form-group">
                  <input type="text" className="form-control form-control-lg " placeholder="Subject"/>
                </div>
                <div className="form-group">
                  <textarea className="form-control form-control-lg" placeholder="Message"></textarea>
                </div>
                <button type="submit" className="btn btn-primary">Send Message</button>
              </form>
            </div>
          </div>


          <div style={{visibility: "hidden"}}>
            <a href="https://pelajar.net" rel="dofollow">Pelajar</a>
          </div>
        </div>

        <div className="footer-bottom-area">
          <div className="row align-items-center text-center">
            <div className="col-lg-12 col-md-6">
              <p>Copyright © {currentYear}
                <strong> Center of Excellence Universitas Dian Nuswantoro</strong>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-map">
        <img src={footerMap} alt="footer-logo"/>
      </div>
    </footer>
  );
}

export default Footer;