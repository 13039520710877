import React from "react";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className="nk-footer">
      <div className="container-fluid">
        <div className="nk-footer-wrap">
          <div className="nk-footer-copyright">
            <p className="text-soft">&copy; {year} {process.env.REACT_APP_COPYRIGHT}. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;