import React from 'react'
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from '../components/Index/Banner'
import Tentang from '../components/Index/Tentang'
import Pelatihan from '../components/Index/Pelatihan'
import Kajian from '../components/Index/Kajian'
import Partner from '../components/AboutUs/Partner'
import Berita from '../components/Index/Berita'
import { useTranslation } from "react-i18next";
import VisiMisi from "../components/Index/VisiMisi";
import Kajian2 from "../components/Index/Kajian2";
import ContactInfo from "../components/Contact/ContactInfo";
import Footer2 from "../components/App/Footer2";
import Tentang2 from "../components/Index/Tentang2";

const Home = () => {
    const { t } = useTranslation();
    return (
        <Layout title={t('home')}>
            <Navbar />
            <Banner />

            {/*<Tentang2 />*/}
            <Tentang />

            <VisiMisi />

            <Berita />

            {/*<Pelatihan />*/}

            <Kajian2 />
            {/*<Kajian />*/}

            <Partner />


            {/*<ContactInfo/>*/}

            {/*<Footer />*/}
            <Footer2 />
        </Layout>
    )
}

export default Home