import React from 'react'
import {useTranslation} from "react-i18next";
import './../../assets/css/custom.css';

const VisiMisi = () => {
  const {t} = useTranslation();
  return (
    <section className="featured-services-area pt-100 pb-70 dotted-section">

      <div className="container">
        <div className="section-title">
          <h2>
            {t("vision.title")}
          </h2>
          <p style={{fontSize: "21px"}} className="text-secondary fst-italic">
            {t("vision.desc")}
          </p>
        </div>

        <div className="container px-lg-5">
          <div className="section-title mb-4">
            <h2>{t("mission.title")}</h2>
          </div>
          <div className="px-lg-5 overview-box mb-5 align-items-start">
            <div className="overview-content">
              <div className="content">
                <ul class="list-group list-group-flush">
                  <li className="list-group-item bg-transparent" style={{fontSize: "18px"}}>
                    {t("mission.desc1")}
                  </li>
                  <li className="list-group-item bg-transparent" style={{fontSize: "18px"}}>
                    {t("mission.desc2")}
                  </li>
                  <li className="list-group-item bg-transparent" style={{fontSize: "18px"}}>
                    {t("mission.desc3")}
                  </li>
                  <li className="list-group-item bg-transparent" style={{fontSize: "18px"}}>
                    {t("mission.desc4")}
                  </li>
                  <li className="list-group-item bg-transparent" style={{fontSize: "18px"}}>
                    {t("mission.desc5")}
                  </li>
                </ul>
              </div>
            </div>
            <div className="overview-content">
              <div className="content">
                <ul class="list-group list-group-flush">
                  <li className="list-group-item bg-transparent" style={{fontSize: "18px"}}>
                    {t("mission.desc6")}
                  </li>
                  <li className="list-group-item bg-transparent" style={{fontSize: "18px"}}>
                    {t("mission.desc7")}
                  </li>
                  <li className="list-group-item bg-transparent" style={{fontSize: "18px"}}>
                    {t("mission.desc8")}
                  </li>
                  <li className="list-group-item bg-transparent" style={{fontSize: "18px"}}>
                    {t("mission.desc9")}
                  </li>
                  <li className="list-group-item bg-transparent" style={{fontSize: "18px"}}>
                    {t("mission.desc10")}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}

export default VisiMisi