import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Row,
  Icon,
  Block,
} from "../../../components/Component";
import { Alert, Card } from "reactstrap";
import { Link } from "react-router-dom";
import api from "../../../../jwtAuth";
import Swal from "sweetalert2";

const ResearchDetails = ({ match }) => {
  const [researchData, setResearchData] = useState([]);
  const [researchPeriods, setResearchPeriods] = useState([]);
  const [userData, setUserData] = useState([]);
  const [userRegistration, setUserRegistration] = useState([]);
  const [loading, setLoading] = useState(false);

  // grabs the id of the url and loads the corresponding data
  const getResearchData = () => {
    const id = match.params.id;
    if (id !== undefined || null || "") {
      const response = api.get(`/user/research/${id}`);
      response.then((res) => {
        if(res.data.success){
          setResearchData(res.data.data.research);
          setResearchPeriods(res.data.data.researchPeriods);
          setUserData(res.data.data.user);
          setUserRegistration(res.data.data.userRegistration);
        } else {
          Swal.fire({
            title: "Error!",
            text: res.data.message,
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Oops!",
          text: err.response.data.message,
          icon: "error",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = `${process.env.PUBLIC_URL}/panel/research`;
          }
        });
      });
    } else {
      window.location.href = `${process.env.PUBLIC_URL}/pages/404`;
    }
  }

  useEffect(() => {
    getResearchData();
  }, [match.params.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitHandler = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Apakah anda yakin?",
      text: "Anda tidak dapat membatalkan pendaftaran yang sudah dikirim!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, daftar sekarang!",
      cancelButtonText: "Batal",
    }).then((confirm) => {
      if(confirm.isConfirmed){
        setLoading(true);
        const id = match.params.id;
        const response = api.post(`/user/research/${id}/registration`, {
          researchPeriod_id : researchPeriods[0]._id,
          email: userData.email,
        });
        response.then((res) => {
          if(res.data.success){
            Swal.fire({
              title: "Success!",
              text: res.data.message,
              icon: "success",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                getResearchData();
              }
            });
          } else {
            Swal.fire({
              title: "Error!",
              text: res.data.message,
              icon: "error",
              confirmButtonText: "Ok",
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            title: "Oops!",
            text: err.response.data.message,
            icon: "error",
            confirmButtonText: "Ok",
          });
        })
        .finally(() => {
          setLoading(false);
        });
      }
    });
  };

  return (
    <React.Fragment>
      <Head title="Penelitian"></Head>
      {researchData && (
        <Content>
          <BlockHead size="sm">
            <BlockBetween className="g-3">
              <BlockHeadContent>
                <BlockTitle>Penelitian</BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <Link to={`${process.env.PUBLIC_URL}/panel/research`}>
                  <Button color="light" outline className="bg-white d-none d-sm-inline-flex">
                    <Icon name="arrow-left"></Icon>
                    <span>Kembali</span>
                  </Button>
                </Link>
                <Link to={`${process.env.PUBLIC_URL}/panel/research`}>
                  <Button color="light" outline className="btn-icon bg-white d-inline-flex d-sm-none">
                    <Icon name="arrow-left"></Icon>
                  </Button>
                </Link>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <Block>
            <Card>
              <div className="card-inner">
                <Row className="pb-5">
                  <Col lg={12}>
                    <div className="product-info mt-5 mr-xxl-5">
                      <h2 className="product-title">{researchData.name}</h2>
                      <div className="product-excrept text-soft">
                        <div dangerouslySetInnerHTML={{ __html: researchData.description }} />
                      </div>
                      <div className="product-meta">
                        <ul className="d-flex flex-wrap ailgn-center g-2 pt-1">
                          {researchPeriods.length === 0 ? (
                            <div>
                              <Alert className="alert-icon" color="danger">
                                <Icon name="cross-circle" />
                                Periode pendaftaran belum dibuka. Silahkan hubungi koordinator.
                              </Alert>
                            </div>         
                          ) : userData.profile && userData.address && userData.education &&(
                            userData.profile.phone === "" || userData.profile.dob === "" || userData.profile.gender === "" || userData.address.address === "" || userData.address.province === "" || userData.address.city === "" || userData.address.district === "" || userData.address.postalCode === "" || userData.education.identity_number === "" || userData.education.ipk === "" || userData.education.study === "" || userData.education.faculty === "" ? (
                              <div>
                                <Alert className="alert-icon" color="danger">
                                  <Icon name="cross-circle" />
                                  Lengkapi data diri anda terlebih dahulu. Klik <Link to={`${process.env.PUBLIC_URL}/panel/profile`}>disini</Link> untuk melengkapi data diri.
                                </Alert>
                              </div>  
                            ) : userRegistration !== null ? (
                              <div>
                                <Alert className="alert-icon" color="success">
                                  <Icon name="check-circle" />
                                  Selamat, Anda sudah terdaftar pada penelitian ini. Silahkan cek status pendaftaran pada menu <Link to={`${process.env.PUBLIC_URL}/panel/research/history`}>riwayat penelitian</Link>.
                                </Alert>
                              </div>
                            ) : (
                              <Button color="primary" onClick={submitHandler} disabled={loading}>Daftar</Button>
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Block>
        </Content>
      )}
    </React.Fragment>
  );
};

export default ResearchDetails;
