import React, { useEffect, useState } from "react"
import Head from "../../../layout/head/Head"
import Content from "../../../layout/content/Content"
import Dropzone from "react-dropzone"
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BackTo,
  PreviewCard,
  PreviewAltCard,
} from "../../../components/Component"
import { Button, Col, Form, FormGroup, Label, Row, Spinner } from "reactstrap"
import { useForm } from "react-hook-form"
import { useQuill } from "react-quilljs"
import Swal from "sweetalert2"
import { NavLink, useParams } from "react-router-dom"
import moment from "moment"
import api from "../../../../jwtAuth"

const PublicationEdit = () => {
  const [loading, setLoading] = useState(false)
  const { errors, register, handleSubmit } = useForm()
  const [content, setContent] = useState("<p></p>")
  const [content_en, setContent_en] = useState("<p></p>")
  const [year, setYear] = useState("")
  const [author, setAuthor] = useState("")
  const { quill, quillRef } = useQuill()
  const { quill: quill_en, quillRef: quillRef_en } = useQuill()
  const [files, setFiles] = useState([])
  const [category, setCategory] = useState([])
  const [selectedOption, setSelectedOption] = useState("")
  const [publication, setPublication] = useState({
    title: "",
    title_en: "",
    description: "",
    description_en: "",
    category: "",
    // year: "",
    // author: "",
    files: [],
    status: "",
    createdBy: "",
  })
  const [deletedFiles, setDeletedFiles] = useState([])
  const [banner, setBanner] = useState(null)
  const { id } = useParams()

  useEffect(() => {
    async function fetchData() {
      const response = api.get(`/admin/publication/${id}`)
      response.then(res => {
        if (res.status === 200) {
          setPublication(res.data.data)
          setSelectedOption(res.data.data.category)
          setAuthor(res.data.data.author)
          setYear(res.data.data.year)
        } else if (res.status === 401) {
          Swal.fire(
            "Gagal!",
            "Anda tidak memiliki akses untuk mengakses halaman ini.",
            "error"
          ).then(result => {
            if (result.isConfirmed) {
              window.location.href = "/admin/publication"
            }
          })
        } else {
          Swal.fire("Gagal!", "Terjadi kesalahan, silahkan coba lagi.", "error")
        }
      })
    }
    fetchData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleDropChange = acceptedFiles => {
    setFiles(files.concat(acceptedFiles))
  }

  const handleDropRemove = file => {
    const newFiles = files.filter(f => f !== file)
    setFiles(newFiles)
  }

  useEffect(() => {
    const category = api.get("/admin/categories")
    category.then(res => {
      if (res.data.success) {
        setCategory(res.data.data)
      }
    })
  }, [])

  const handleDeleteFile = file => {
    Swal.fire({
      title: "Apakah Anda yakin?",
      text: "Ketika data diperbarui, file yang dihapus tidak akan bisa dikembalikan!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, hapus file ini!",
      cancelButtonText: "Batal",
    }).then(result => {
      if (result.isConfirmed) {
        const newFiles = publication.files.filter(f => f !== file)
        setPublication({ ...publication, files: newFiles })
        setDeletedFiles(deletedFiles.concat(file.filename))
        Swal.fire(
          "Berhasil!",
          "File Anda masuk kedalam antrean untuk dihapus, file akan dihapus setelah data diperbarui.",
          "success"
        )
      }
    })
  }

  const handleBannerChange = event => {
    setBanner(event.target.files[0])
  }

  const handleDownloadFile = file => {
    const response = api.post(
      `/admin/publication/${id}/download`,
      { filename: file.filename },
      { responseType: "blob" },
      {
        headers: {
          "Content-Disposition": "attachment",
        },
      }
    )
    response.then(res => {
      if (res.status === 200) {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: res.headers["content-type"] })
        )
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", file.originalname)
        document.body.appendChild(link)
        link.click()
        link.remove()
      } else if (res.status === 401) {
        Swal.fire(
          "Gagal!",
          "Anda tidak memiliki akses untuk mengakses halaman ini.",
          "error"
        ).then(result => {
          if (result.isConfirmed) {
            window.location.href = "/admin/publication"
          }
        })
      } else {
        Swal.fire("Gagal!", "Terjadi kesalahan, silahkan coba lagi.", "error")
      }
    })
  }

  const formatFileSize = bytes => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024,
      dm = 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  useEffect(() => {
    if (quill) {
      quill.on("text-change", (delta, oldDelta, source) => {
        setContent(quill.root.innerHTML)
      })
      quill.clipboard.dangerouslyPasteHTML(publication.description)
    }
  }, [quill, publication.description])

  useEffect(() => {
    if (quill_en) {
      quill_en.on("text-change", (delta, oldDelta, source) => {
        setContent_en(quill_en.root.innerHTML)
      })
      quill_en.clipboard.dangerouslyPasteHTML(publication.description_en)
    }
  }, [quill_en, publication.description_en])

  const onFormSubmit = e => {
    setLoading(true)
    const formData = new FormData()
    formData.append("title", e.title)
    formData.append("title_en", e.title_en)
    formData.append("banner", banner)
    formData.append("description", content)
    formData.append("description_en", content_en)
    formData.append("category", selectedOption)
    formData.append("year", year)
    formData.append("author", author)
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i])
    }
    formData.append("deletedFiles", deletedFiles)
    formData.append("status", "published")
    formData.append("createdBy", localStorage.getItem("email"))
    const response = api.put(`/admin/publication/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    response
      .then(res => {
        if (res.data.success) {
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: res.data.message,
          }).then(result => {
            if (result.isConfirmed) {
              window.location.href = "/admin/publication"
            }
          })
        } else {
          Swal.fire({
            icon: "error",
            title: "Gagal",
            text: res.data.message,
          })
        }
      })
      .catch(err => {
        Swal.fire({
          icon: "error",
          title: "Gagal",
          text: err.response.data.message,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <React.Fragment>
      <Head title="Ubah Publikasi" />
      <Content page="component">
        <BlockHead size="lg" wide="sm">
          <BlockHeadContent>
            <BackTo link="/admin/publication" icon="arrow-left">
              Semua Publikasi
            </BackTo>
          </BlockHeadContent>
        </BlockHead>

        <Block size="lg">
          <Form
            className="is-alter"
            onSubmit={handleSubmit(onFormSubmit)}
            encType="multipart/form-data"
          >
            <Row>
              <Col lg="9">
                <PreviewCard>
                  <FormGroup>
                    <Label htmlFor="title" className="form-label" required>
                      Judul Publikasi
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="title"
                        name="title"
                        placeholder="Masukkan judul publikasi"
                        defaultValue={publication.title}
                        ref={register({
                          required: "Judul Publikasi wajib diisi",
                        })}
                      />
                    </div>
                    {errors.title && (
                      <span className="text-danger">
                        {errors.title.message}
                      </span>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="title" className="form-label" required>
                      Judul Publikasi (English)
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="title_en"
                        name="title_en"
                        placeholder="Masukkan judul publikasi"
                        defaultValue={publication.title_en}
                        ref={register({
                          required: "Judul Publikasi wajib diisi",
                        })}
                      />
                    </div>
                    {errors.title_en && (
                      <span className="text-danger">
                        {errors.title_en.message}
                      </span>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="title" className="form-label" required>
                      Penulis
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        defaultValue={author || ""}
                        className="form-control"
                        type="text"
                        id="author"
                        name="author"
                        placeholder="Masukkan penulis publikasi"
                        onChange={e => setAuthor(e.target.value)}
                      />
                    </div>
                    {errors.title && (
                      <span className="text-danger">
                        {errors.title.message}
                      </span>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="title" className="form-label" required>
                      Banner
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="file"
                        name="image"
                        onChange={handleBannerChange}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label className="form-label">Abstrak</Label>
                    <div className="form-control-wrap">
                      <div style={{ width: "100%", height: "100%" }}>
                        <div ref={quillRef} />
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label className="form-label">Abstrak (English)</Label>
                    <div className="form-control-wrap">
                      <div style={{ width: "100%", height: "100%" }}>
                        <div ref={quillRef_en} />
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label className="form-label">File Publikasi</Label>
                    <div className="form-control-wrap">
                      <Dropzone
                        onDrop={acceptedFiles =>
                          handleDropChange(acceptedFiles)
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div
                              {...getRootProps()}
                              className="dropzone upload-zone dz-clickable"
                            >
                              <input {...getInputProps()} />
                              <div className="dz-message">
                                <span className="dz-message-text">
                                  Drag and drop file
                                </span>
                                <span className="dz-message-or">or</span>
                                <span className="dz-message-text">
                                  Browse File
                                </span>
                              </div>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      <aside>
                        {files.length > 0 && (
                          <Label htmlFor="title" className="form-label">
                            File Belum Terupload
                          </Label>
                        )}
                        {/* <Label htmlFor="title" className="form-label">File Belum Terupload</Label> */}
                        <ul>
                          <table className="table table-tranx is-compact">
                            <tbody>
                              {files.map(file => (
                                <tr key={file.name} className="tb-tnx-item">
                                  <td style={{ width: "75vw" }}>
                                    {file.name} - {formatFileSize(file.size)}
                                  </td>
                                  <td>
                                    <Button
                                      color="danger"
                                      onClick={() => handleDropRemove(file)}
                                    >
                                      Hapus
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </ul>
                      </aside>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    {publication.files.length > 0 && (
                      <Label htmlFor="title" className="form-label">
                        File Terupload
                      </Label>
                    )}
                    <Row className="g-gs" key={"file-row"}>
                      {publication.files.map((file, index) => (
                        <Col key={`${file.filename}_${index}`} lg="4">
                          <PreviewAltCard>
                            <div className="team">
                              <div className="user-card user-card-s2">
                                <div className="user-info">
                                  <h6>{file.originalname}</h6>
                                </div>
                              </div>
                              <ul className="team-info">
                                <li>
                                  <span>Ukuran File</span>
                                  <span>{formatFileSize(file.size)}</span>
                                </li>
                                <li>
                                  <span>Tanggal Upload</span>
                                  <span>
                                    {moment(file.uploadDate).format(
                                      "DD MMMM YYYY"
                                    )}
                                  </span>
                                </li>
                                <li>
                                  <span>Download</span>
                                  <span>{file.downloaded}</span>
                                </li>
                              </ul>
                              <div className="team-view">
                                <Row>
                                  <Col lg="6">
                                    <Button
                                      color="primary"
                                      onClick={() => handleDownloadFile(file)}
                                    >
                                      Unduh
                                    </Button>
                                  </Col>
                                  <Col lg="6">
                                    <Button
                                      color="danger"
                                      onClick={() => handleDeleteFile(file)}
                                    >
                                      Hapus
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </PreviewAltCard>
                        </Col>
                      ))}
                    </Row>
                  </FormGroup>
                </PreviewCard>
              </Col>
              <Col lg="3">
                <PreviewCard>
                  <FormGroup>
                    <Label htmlFor="title" className="form-label" required>
                      Kategori Publikasi
                    </Label>
                    <div className="form-control-wrap">
                      <select
                        value={selectedOption}
                        onChange={e => setSelectedOption(e.target.value)}
                        className="form-control"
                        id="category"
                        name="category"
                      >
                        <option value="">Pilih Kategori</option>
                        {category.map((item, index) => (
                          <option key={index} value={`${item._id}`}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="title" className="form-label" required>
                      Tahun Publikasi
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        defaultValue={year || ""}
                        className="form-control"
                        type="number"
                        id="year"
                        name="year"
                        placeholder="Masukkan tahun publikasi"
                        onChange={e => setYear(e.target.value)}
                      />
                    </div>
                    {errors.title && (
                      <span className="text-danger">
                        {errors.title.message}
                      </span>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col lg="12">
                        <Button
                          size="lg"
                          className="btn-block"
                          type="submit"
                          color="primary"
                          disabled={loading}
                        >
                          {loading ? (
                            <Spinner size="sm" color="light" />
                          ) : (
                            "Perbarui"
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                </PreviewCard>
              </Col>
            </Row>
          </Form>
        </Block>
      </Content>
    </React.Fragment>
  )
}

export default PublicationEdit
