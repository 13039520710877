import React, { useEffect, useState } from 'react'
import { NumericFormat } from 'react-number-format';
import { Button, Spinner } from 'reactstrap';
import api from '../../../jwtAuth';
import Swal from 'sweetalert2';
import { useTranslation } from "react-i18next";

const EventSidebar = ({ training, refreshData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [userRegistration, setUserRegistration] = useState(null);

  useEffect(() => {
    setUser(training.user);
    setUserRegistration(training.userRegistration);
  }, [training])

  const submitHandler = (e) => {
    e.preventDefault();
    Swal.fire({
      title: t("areYouSure"),
      text: t("registrationWarning"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("yes"),
      cancelButtonText: t("cancel"),
    }).then((confirm) => {
      if (confirm.isConfirmed) {
        setLoading(true);
        const slug = training.training.slug;
        const response = api.post(`/public/training/${slug}/register`, {
          email: user.email,
        });
        response.then((res) => {
          if (res.data.success) {
            Swal.fire({
              title: "Success!",
              text: res.data.message,
              icon: "success",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                refreshData();
              }
            });
          } else {
            Swal.fire({
              title: "Error!",
              text: res.data.message,
              icon: "error",
              confirmButtonText: "Ok",
            });
          }
        })
          .catch((err) => {
            Swal.fire({
              title: "Oops!",
              text: err.response.data.message,
              icon: "error",
              confirmButtonText: "Ok",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };
  return (
    <div className="events-details-info">
      <ul className="info">
        <li className="price">
          <div className="d-flex justify-content-between align-items-center">
            <span>{t('price')}</span>
            <NumericFormat
              value={training && training.training && training.training.price}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'Rp '}
            />
          </div>
        </li>
        <li>
          <div className="d-flex justify-content-between align-items-center">
            <span>{t('quota')}</span>
            {training && training.training && training.training.quota}
          </div>
        </li>
        <li>
          <div className="d-flex justify-content-between align-items-center">
            <span>{t('registrant')}</span>
            {training && training.countRegistration}
          </div>
        </li>
      </ul>

      <div className="btn-box">
        {user && user.profile && user.address && user.education ? (
          user.profile.phone === "" || user.profile.dob === "" || user.profile.gender === "" || user.address.address === "" || user.address.province === "" || user.address.city === "" || user.address.district === "" || user.address.postalCode === "" || user.education.identity_number === "" || user.education.ipk === "" || user.education.study === "" || user.education.faculty === "" ? (
            <div>
              <p>{t('training.profile.notCompleted')}</p>
            </div>
          ) : userRegistration !== null ? (
            <p>{t('training.registered')}</p>
          ) : (
            // <Link to="#" className="default-btn">
            //     <i className="flaticon-user"></i> 
            //     Daftar Sekarang <span></span>
            // </Link>
            <Button size="lg" className="btn-block" type="submit" color="primary" onClick={submitHandler} disabled={loading}>
              {loading ? <Spinner size="sm" color="light" /> : t('register')}
            </Button>
          )
        ) : (
          <p>{t('training.notLogin')}</p>
        )}
      </div>
    </div>
  )
}

export default EventSidebar