import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import AboutUsContent from '../../components/Penelitian/SmartCity/AboutUsContent'
import OurHistory from '../../components/Penelitian/SmartCity/OurHistory'
import WhyChooseUs from '../../components/Penelitian/SmartCity/WhyChooseUs'
import HowItWork from '../../components/Penelitian/SmartCity/HowItWork'
import TeamMembers from '../../components/Penelitian/SmartCity/TeamMembers'
import Testimonials from '../../components/Penelitian/SmartCity/Testimonials'
import Partner from '../../components/Penelitian/SmartCity/Partner'
 
const SmartCity = () => {
    return (
        <Layout title={'Penelitian - SmartCity'}>
            <Navbar />
            <PageBanner
                pageTitle="Penelitian - SmartCity" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="About Us" 
            />
            <AboutUsContent />
            <OurHistory />
            <WhyChooseUs />
            <HowItWork />
            <TeamMembers />
            <Testimonials />
            <Partner />
            <Footer />
        </Layout>
    );
}

export default SmartCity;