import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Row,
  Col,
  PaginationComponent,
} from "../../../components/Component";
import api from "../../../../jwtAuth";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Card } from "reactstrap";

const BorrowList = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(9);

  useEffect(() => {
    const response = api.get("/user/borrow");
    response.then((res) => {
      if(res.data.success){
        setData(res.data.data);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: res.data.message,
        });
      }
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err.response.data.message,
      });
    });
  }, []);

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <Head title="Barang Pinjaman"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page> Barang Pinjaman</BlockTitle>
              <BlockDes className="text-soft">Terdapat {data.length} barang yang dapat Anda pinjam.</BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <Row className="g-gs">
            {currentItems &&
              currentItems.map((item, idx) => {
                return (
                  <Col xxl={3} lg={4} sm={6} key={item._id}>
                    <Card className="product-card">
                      <div className="product-thumb">
                        <Link to={`${process.env.PUBLIC_URL}/panel/borrow/${item._id}`}>
                          <img className="card-img-top" src={`${process.env.REACT_APP_API_URL}/${item.images[0].path}`} alt="" />
                        </Link>
                      </div>
                      <div className="card-inner text-center">
                        <h5 className="product-title">
                          <Link to={`${process.env.PUBLIC_URL}/panel/borrow/${item._id}`}>{item.name}</Link>
                        </h5>
                      </div>
                    </Card>
                  </Col>
                );
              })}
          </Row>
          <div className="mt-3">
            <PaginationComponent
              itemPerPage={itemPerPage}
              totalItems={data.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default BorrowList;
