import React from 'react'
import logo from '../../assets/images/logo-COE.png'
import moment from 'moment'
import EventSidebar from './EventSidebar'

const PelatihanDetail = ({ training, refreshData }) => {
  return (
    <section className="events-details-area pb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="article-image">
              {training && training.training && training.training.banner !== "null" ? (
                <img src={`${process.env.REACT_APP_API_URL + "/" + training.training.banner}`} style={{ height: "500px", width: "1920px" }} alt="bb" />
              ) : (
                <img src={`${logo}`} style={{ height: "500px", width: "1920px" }} alt="bb" />
              )}
            </div>

            <div className="events-details-header">
              <ul>
                <li><i className='bx bx-calendar'></i>{training && training.training && moment(training.training.event_date).format("LL")}</li>
                <li><i className='bx bx-map'></i>{training && training.training && training.training.location}</li>
                {/* <li><i className='bx bx-time'></i>12.00PM</li> */}
              </ul>
            </div>

            <div className="events-details-desc">
              {localStorage.getItem("lang") === "en" ? (
                <div>
                  <h1>{training && training.training && training.training.name_en}</h1>
                  <div dangerouslySetInnerHTML={{ __html: training && training.training && training.training.description_en }} />
                </div>
              ) : (
                <div>
                  <h1>{training && training.training && training.training.name}</h1>
                  <div dangerouslySetInnerHTML={{ __html: training && training.training && training.training.description }} />
                </div>
              )}
            </div>
          </div>

          <div className="col-lg-4 col-md-12">
            <EventSidebar training={training} refreshData={refreshData} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default PelatihanDetail