import React from "react"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import Layout from "../../components/App/Layout"
import PageBanner from "../../components/Common/PageBanner"
import ListPelatihan from "../../components/Pelatihan/Pelatihan"
import { useTranslation } from "react-i18next";

const Pelatihan = () => {
  const { t } = useTranslation();
  return (
    <Layout title={t("training")}>
      <Navbar />
      <PageBanner
        pageTitle={t("training")}
        homePageText={t("home")}
        homePageUrl="/"
        activePageText={t("training")}
      />
      <ListPelatihan />
      <Footer />
    </Layout>
  )
}

export default Pelatihan;