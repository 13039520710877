import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import Dropzone from "react-dropzone";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BackTo,
  PreviewCard,
} from "../../../components/Component";
import { Button, Col, Form, FormGroup, Label, Row, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { useQuill } from "react-quilljs";
import Swal from "sweetalert2";
import { NavLink } from "react-router-dom";
import api from "../../../../jwtAuth";

const BorrowItemAdd = () => {
  const [loading, setLoading] = useState(false);
  const { errors, register, handleSubmit } = useForm();
  const [content, setContent] = useState('<p></p>');
  const { quill, quillRef } = useQuill();
  const [images, setImages] = useState([]);

  const handleDropChange = (acceptedFiles) => {
    setImages(images.concat(acceptedFiles));
  };

  const handleDropRemove = (file) => {
    const newImages = images.filter((f) => f !== file);
    setImages(newImages);
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024,
      dm = 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };


  useEffect(() => {
    if (quill) {
      quill.on('text-change', (delta, oldDelta, source) => {
        setContent(quill.root.innerHTML);
      });
    }
  }, [quill]);

  const onFormSubmit = (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", e.name);
    formData.append("description", content);
    formData.append("stok", e.stok);
    for (let i = 0; i < images.length; i++) {
      formData.append('images', images[i]);
    }
    formData.append("status", "published");
    formData.append("createdBy", localStorage.getItem('email'));
    const response = api.post("/admin/borrow", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    response.then((res) => {
      if(res.data.success){
        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: res.data.message,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/borrow";
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Gagal",
          text: res.data.message,
        });
      }
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Gagal",
        text: err.response.data.message,
      });
    });
    setLoading(false);
  };
  return (
    <React.Fragment>
      <Head title="Tambahkan Barang Pinjaman" />
      <Content page="component">
        <BlockHead size="lg" wide="sm">
          <BlockHeadContent>
            <BackTo link="/admin/borrow" icon="arrow-left">
              Semua Barang Pinjaman
            </BackTo>
          </BlockHeadContent>
        </BlockHead>

        <Block size="lg">
          <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)} encType="multipart/form-data">
            <Row>
              <Col lg="12">
                <PreviewCard>
                  <FormGroup>
                    <Label htmlFor="name" className="form-label" required>Nama Barang</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="text" 
                        id="name" 
                        name="name" 
                        placeholder="Masukkan nama barang" 
                        ref={register({ required: "Nama Barang wajib diisi" })}
                      />
                    </div>
                    {errors.name && <span className="text-danger">{errors.name.message}</span>}
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="stok" className="form-label" required>Stok Barang</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="number" 
                        id="stok" 
                        name="stok" 
                        placeholder="Masukkan nama barang" 
                        ref={register({ required: "Stok Barang wajib diisi" })}
                      />
                    </div>
                    {errors.stok && <span className="text-danger">{errors.stok.message}</span>}
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="description" className="form-label">Deskripsi Barang</Label>
                    <div className="form-control-wrap">
                      <div style={{ width: "100%", height: "100%" }}>
                        <div ref={quillRef} />
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="title" className="form-label">Foto Barang</Label>
                    <div className="form-control-wrap">
                      <Dropzone onDrop={(acceptedFiles) => handleDropChange(acceptedFiles)}>
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div {...getRootProps()} className="dropzone upload-zone dz-clickable">
                              <input {...getInputProps()} />
                              <div className="dz-message">
                                <span className="dz-message-text">Drag and drop file</span>
                                <span className="dz-message-or">or</span>
                                <span className="dz-message-text">Browse File</span>
                              </div>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      <aside>
                        {images.length > 0 && <Label htmlFor="title" className="form-label">Foto Belum Terupload</Label>}
                        <ul>
                          <table className="table table-tranx is-compact">
                            <tbody>
                              {images.map((file) => (
                                <tr key={file.name} className="tb-tnx-item">
                                  <td style={{width: '75vw'}}>
                                    {file.name} - {formatFileSize(file.size)}
                                  </td>
                                  <td>
                                    <Button color="danger" onClick={() => handleDropRemove(file)}>Hapus</Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </ul>
                      </aside>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col lg="6">
                        <Button size="lg" className="btn-block" type="submit" color="primary" disabled={loading}>
                          {loading ? <Spinner size="sm" color="light" /> : "Simpan"}
                        </Button>
                      </Col>
                      <Col lg="6">
                        <NavLink to="/admin/borrow">
                          <Button size="lg" className="btn-block" type="reset" color="light">
                            Kembali
                          </Button>
                        </NavLink>
                      </Col>
                    </Row>
                  </FormGroup>
                </PreviewCard>
              </Col>
            </Row>
          </Form>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default BorrowItemAdd;
