import React, { useEffect, useState } from "react"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import Layout from "../../components/App/Layout"
import PageBanner from "../../components/Common/PageBanner"
import DatasetDetails from "../../components/Dataset/DatasetDetail"
import { RedirectAs404 } from "../../../admin/utils/Utils"
import api from "../../../jwtAuth"
import { Route, useParams } from "react-router"
import { useTranslation } from "react-i18next";

const DatasetDetail = () => {
  const { t } = useTranslation();
  const [datasets, setDatasets] = useState([]);
  const [prevPage, setPrevPage] = useState([]);
  const [nextPage, setNextPage] = useState([]);  
  const [title, setTitle] = useState(t("dataset"));
  const { slug } = useParams();
  const [slugDatasets, setSlugDatasets] = useState(slug);
  const [errorCode, setErrorCode] = useState(0);

  // Get Data
  async function getData() {
    try {
      const response = await api.get('/public/dataset/' + slugDatasets);
      setDatasets(response.data.data);
      setPrevPage(response.data.previousDatasets);
      setNextPage(response.data.nextDatasets);
      setTitle(response.data.data.title);
    } catch (error) {
      // console.error(error);
      if (error.response.status === 401) {
        getData();
      }
      setErrorCode(error.response.status);
    }
  }
  useEffect(() => {
    getData();
  }, [slugDatasets]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    changeSlug(slug);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Change Slug
  const changeSlug = (slug) => {
    setSlugDatasets(slug);
  }

  if(errorCode === 404) {
    return <Route component={RedirectAs404}></Route>
  }
  return (
    <Layout title={title}>
      <Navbar />
      <PageBanner
        pageTitle={t("dataset")}
        homePageText={t("home")}
        homePageUrl="/"
        activePageText={t("dataset")}
      />
      <DatasetDetails 
        datasets={datasets} 
        prevPage={prevPage}
        nextPage={nextPage}
        changeSlug={changeSlug}
      />
      <Footer />
    </Layout>
  )
}

export default DatasetDetail;