import { Card } from "reactstrap";
import { Doughnut } from "react-chartjs-2";

const DefaultOrderStatistics = ({data}) => {
  var orderStatistics = {
    labels: ["Aktif", "Pending", "Blokir"],
    dataUnit: "People",
    legend: false,
    datasets: [
      {
        borderColor: "#fff",
        backgroundColor: ["#816bff", "#13c9f2", "#ff82b7"],
        data: [data.userActive, data.userPending, data.userBlocked]
      },
    ],
  };
  return (
    <Doughnut
      data={orderStatistics}
      options={{
        legend: {
          display: false,
          labels: {
            boxWidth: 12,
            padding: 20,
            fontColor: "#6783b8",
          },
        },
        rotation: 0,
        cutoutPercentage: 70,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          callbacks: {
            title: function (tooltipItem, data) {
              return data["labels"][tooltipItem[0]["index"]];
            },
            label: function (tooltipItem, data) {
              return data.datasets[tooltipItem.datasetIndex]["data"][tooltipItem["index"]] + " ";
            },
          },
          backgroundColor: "#1c2b46",
          titleFontSize: 13,
          titleFontColor: "#fff",
          titleMarginBottom: 6,
          bodyFontColor: "#fff",
          bodyFontSize: 12,
          bodySpacing: 4,
          yPadding: 10,
          xPadding: 10,
          footerMarginTop: 0,
          displayColors: false,
        },
      }}
    />
  );
};

const UserStatistics = ({data}) => {
  return (
    <Card className="card-full overflow-hidden">
      <div className="nk-ecwg nk-ecwg7 h-100">
        <div className="card-inner flex-grow-1">
          <div className="card-title-group mb-4">
            <div className="card-title">
              <h6 className="title">Statistik Pengguna</h6>
            </div>
          </div>
          <div className="nk-ecwg7-ck">
            <DefaultOrderStatistics data={data} />
          </div>
          <ul className="nk-ecwg7-legends">
            <li>
              <div className="title">
                <span className="dot dot-lg sq" style={{ background: "#816bff" }}></span>
                <span>Aktif</span>
              </div>
            </li>
            <li>
              <div className="title">
                <span className="dot dot-lg sq" style={{ background: "#13c9f2" }}></span>
                <span>Pending</span>
              </div>
            </li>
            <li>
              <div className="title">
                <span className="dot dot-lg sq" style={{ background: "#ff82b7" }}></span>
                <span>Blokir</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Card>
  );
};
export default UserStatistics;
