import React from 'react'
import WOW from 'wowjs'
import { Link } from 'react-router-dom'
import bannerImg2 from '../../assets/images/about/udinus.jpg'
import { useTranslation } from "react-i18next";

const Banner = () => {
    const { t } = useTranslation();

    new WOW.WOW({
        live: false
    }).init();

    return (
        <div className="seo-agency-banner">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-12">
                        <div className="banner-wrapper-content w-100">
                            <div className="wow fadeInLeft" data-wow-delay="1s">
                                <h1 style={{
                                    // fontFamily: "Nunito, sans-serif",
                                    fontWeight: "700",
                                    textShadow: "2px 2px 4px rgba(255, 255, 255, 0.6)"
                                }}>{t("home.banner.title")}</h1>
                            </div>

                            <div className="wow fadeInLeft" data-wow-delay="1s">
                                <p style={{
                                    fontFamily: "Nunito, sans-serif",
                                    fontWeight: "600",
                                    fontSize: "20px",
                                    textShadow: "2px 2px 4px rgba(255, 255, 255, 0.8)"
                                }}>
                                    {t("home.banner.subtitle")}
                                </p>
                            </div>


                            <div className="wow fadeInRight" data-wow-delay="1s">
                                <div className="btn-box">
                                    <Link to="/tentang-kami/profil" className="default-btn">
                                        <i className="flaticon-right"></i>
                                        {t("aboutus")}
                                        <span></span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*<div className="col-lg-7 col-md-12">*/}
                    {/*    <div className="wow fadeInUp" data-wow-delay="1s">*/}
                    {/*        <div    className="banner-wrapper-image">*/}
                    {/*            <img src={bannerImg2} alt="banner" />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    )
}

export default Banner