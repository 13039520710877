import axios from 'axios';
import { isExpired } from 'react-jwt';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

// Membuat sebuah function untuk mengambil token dari local storage
const getToken = () => {
  var token = localStorage.getItem('token');
  if (!token) {
    const response = axios.post(`${process.env.REACT_APP_API_URL + "/auth/generateToken"}`, {
      headers: {
        "Content-Type": "application/json",
        "email": localStorage.getItem("email"),
      },
    })
    response.then((res) => {
      localStorage.setItem("token", res.data.token);
      token = res.data.token;
    });
  } else {
    if(isExpired(token)){
      localStorage.removeItem("token");
      getToken();
    }
  }
  return token;
};

// Membuat middleware untuk menambahkan token ke header request
const authMiddleware = (config) => {
  const token = getToken();
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `${token}`,
    },
  };
};

// Menambahkan middleware ke instance axios
api.interceptors.request.use(authMiddleware);

export default api;