import React, { useState } from "react";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Button, PreviewCard, Icon } from "../../components/Component";
import Logo from "../../assets/images/logo-COE.png";
import LogoDark from "../../assets/images/logo-COE.png";
import { Form, FormGroup, Spinner, Alert } from "reactstrap";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import api from "../../../jwtAuth";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({
    status: null,
    message: "",
  });

  const onFormSubmit = (formData) => {
    setResponse({
      status: null,
      message: "",
    });
    setLoading(true);
    const email = formData.email;
    const data = {
      apikey: process.env.REACT_APP_API_KEY,
      email: email,
    };
    const response = api.post("/auth/forgotPassword", data);
    response.then((res) => {
      if (res.data.success) {
        setResponse({
          status: "success",
          message: res.data.message,
        });
      } else {
        setResponse({
          status: "danger",
          message: res.data.message,
        });
      }
    })
    .catch((err) => {
      setResponse({
        status: "danger",
        message: err.response.data.message,
      });
    })
    .finally(() => {
      setLoading(false);
    });
  };

  const { errors, register, handleSubmit } = useForm();
  return (
    <React.Fragment>
      <Head title="Lupa Password" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
            </Link>
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Lupa password</BlockTitle>
                <BlockDes>
                  <p>Jika Anda lupa kata sandi Anda, kami akan mengirimkan email petunjuk untuk mengatur ulang kata sandi Anda.</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            {response.status !== null && (
              <div className="mb-3">
                <Alert color={`${response.status}`} className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {response.message}
                </Alert>
              </div>
            )}
            <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Email
                  </label>
                </div>
                <input
                  type="email"
                  className="form-control form-control-lg"
                  id="email"
                  name="email"
                  ref={register({ required: "Email wajib diisi" })}
                  placeholder="Masukkan Email"
                />
                {errors.name && <span className="invalid">{errors.email.message}</span>}
              </FormGroup>
              <FormGroup>
                <Button type="submit" color="primary" size="lg" className="btn-block" disabled={loading}>
                  {loading ? <Spinner size="sm" color="light" /> : "Kirim"}
                </Button>
              </FormGroup>
            </Form>
            <div className="form-note-s2 text-center pt-4">
              <Link to={`${process.env.PUBLIC_URL}/login`}>
                <strong>Kembali</strong>
              </Link>
            </div>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default ForgotPassword;
