import React from "react"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import Layout from "../../components/App/Layout"
import PageBanner from "../../components/Common/PageBanner"
import ListDataset from "../../components/Dataset/Dataset"
import { useTranslation } from "react-i18next";

const Dataset = () => {
  const { t } = useTranslation();
  return (
    <Layout title={t("dataset")}>
      <Navbar />
      <PageBanner
        pageTitle={t("dataset")}
        homePageText={t("home")}
        homePageUrl="/"
        activePageText={t("dataset")}
      />
      <ListDataset />
      <Footer />
    </Layout>
  )
}

export default Dataset;