import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import howitsWork from '../../assets/images/how-its-work.png'
import echnh from "../../assets/images/echnh.png"
import ehealth from "../../assets/images/ehealth.png"
import eumkm from "../../assets/images/eumkm.png"
import grenenv from "../../assets/images/greenenv.png"
import smartfarming from "../../assets/images/smartfarming.png"
import { useTranslation } from "react-i18next";

const Kajian = () => {
  const { t } = useTranslation();
  return (
    <section className="how-its-work-area ptb-100">
      <div className="container">
        <div className="align-items-center px-lg-5">

            <div className="how-its-work-content">
                            <span className="sub-title">
                                <img src={starIcon} alt="choose" />
                              {t("home.study.title")}
                            </span>
              <h2>{t("home.study.title")}</h2>

              <div className="inner-box px-lg-5">
                <div className="single-item">
                  <div className="count-box">
                    <img src={echnh} alt="" />
                  </div>
                  <h3>E-CHNH</h3>
                  <p style={{fontSize:"20px"}} dangerouslySetInnerHTML={{ __html: t('home.study.desc1') }}></p>
                </div>
                <div className="single-item">
                  <div className="count-box">
                    <img src={ehealth} alt="" />
                  </div>
                  <h3>E-HEALTH</h3>
                  <p style={{fontSize:"20px"}} dangerouslySetInnerHTML={{ __html: t('home.study.desc2') }}></p>
                </div>
                <div className="single-item">
                  <div className="count-box">
                    <img src={eumkm} alt="" />
                  </div>
                  <h3>E-UMKM</h3>
                  <p style={{fontSize:"20px"}} dangerouslySetInnerHTML={{ __html: t('home.study.desc3') }}></p>
                </div>
                <div className="single-item">
                  <div className="count-box">
                    <img src={smartfarming} alt="" />
                  </div>
                  <h3>Smart Farming</h3>
                  <p style={{fontSize:"20px"}} dangerouslySetInnerHTML={{ __html: t('home.study.desc4') }}></p>
                </div>
                <div className="single-item">
                  <div className="count-box">
                    <img src={grenenv} alt="" />
                  </div>
                  <h3>Green Environment</h3>
                  <p style={{fontSize:"20px"}} dangerouslySetInnerHTML={{ __html: t('home.study.desc5') }}></p>
                </div>
              </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Kajian