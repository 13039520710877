import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BackTo,
  PreviewCard,
} from "../../../components/Component";
import { Button, Col, Form, FormGroup, Label, Row, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { NavLink } from "react-router-dom";
import api from "../../../../jwtAuth";

const ResearchPeriodsAdd = () => {
  const [loading, setLoading] = useState(false);
  const { errors, register, handleSubmit } = useForm();

  const onFormSubmit = (e) => {
    setLoading(true);    
    const response = api.post("/admin/researchPeriods", {
      name: e.name,
      start_date: e.start_date,
      end_date: e.end_date,
    });
    response.then((res) => {
      if (res.data.success) {
        Swal.fire({
          title: "Berhasil",
          text: res.data.message,
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/research/periods";
          }
        });
      } else {
        Swal.fire({
          title: "Gagal",
          text: res.data.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    })
    .catch((err) => {
      Swal.fire({
        title: "Gagal",
        text: err.response.data.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    })
    .finally(() => {
      setLoading(false);
    });
  };
  return (
    <React.Fragment>
      <Head title="Tambahkan Periode Pendaftaran" />
      <Content page="component">
        <BlockHead size="lg" wide="sm">
          <BlockHeadContent>
            <BackTo link="/admin/research/periods" icon="arrow-left">
              Semua Periode Pendaftaran
            </BackTo>
          </BlockHeadContent>
        </BlockHead>

        <Block size="lg">
          <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)} encType="multipart/form-data">
            <PreviewCard>
              <Row className="g-2">
                <Col lg="4">
                  <FormGroup>
                    <Label htmlFor="name" className="form-label" required>Nama Periode</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="text" 
                        id="name" 
                        name="name" 
                        placeholder="Masukkan nama hak akses" 
                        ref={register({ required: "Nama Periode wajib diisi" })}
                      />
                    </div>
                    {errors.name && <span className="text-danger">{errors.name.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <Label htmlFor="name" className="form-label" required>Tanggal Mulai</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="date" 
                        id="start_date" 
                        name="start_date" 
                        placeholder="Masukkan Tanggal Mulai" 
                        ref={register({ required: "Tanggal Mulai wajib diisi" })}
                      />
                    </div>
                    {errors.start_date && <span className="text-danger">{errors.start_date.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <Label htmlFor="name" className="form-label" required>Tanggal Akhir</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="date" 
                        id="end_date" 
                        name="end_date" 
                        placeholder="Masukkan Tanggal Akhir" 
                        ref={register({ required: "Tanggal Akhir wajib diisi" })}
                      />
                    </div>
                    {errors.end_date && <span className="text-danger">{errors.end_date.message}</span>}
                  </FormGroup>
                </Col>
                <FormGroup>
                  <Row>
                    <Col lg="6">
                      <Button size="lg" className="btn-block" type="submit" color="primary" disabled={loading}>
                        {loading ? <Spinner size="sm" color="light" /> : "Simpan"}
                      </Button>
                    </Col>
                    <Col lg="6">
                      <NavLink to="/admin/research/periods">
                        <Button size="lg" className="btn-block" type="reset" color="light">
                          Kembali
                        </Button>
                      </NavLink>
                    </Col>
                  </Row>
                </FormGroup>
              </Row>                  
            </PreviewCard>
          </Form>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default ResearchPeriodsAdd;
