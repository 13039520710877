import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import Dropzone from "react-dropzone";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BackTo,
  PreviewCard,
  PreviewAltCard,
} from "../../../components/Component";
import { Button, Col, Form, FormGroup, Label, Row, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { useQuill } from "react-quilljs";
import Swal from "sweetalert2";
import { NavLink, useParams } from "react-router-dom";
import moment from "moment";
import api from "../../../../jwtAuth";

const BorrowItemEdit = () => {
  const [loading, setLoading] = useState(false);
  const { errors, register, handleSubmit } = useForm();
  const [content, setContent] = useState('<p></p>');
  const { quill, quillRef } = useQuill();
  const [images, setImages] = useState([]);
  const [borrowData, setBorrowData] = useState({
    name: "",
    stok: "",
    description: "",
    images: [],
    status: "",
  });
  const [deletedImages, setDeletedFiles] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    async function fetchData() {
      const response = api.get(`/admin/borrow/${id}`);
      response.then((res) => {
        if(res.status === 200) {
          setBorrowData(res.data.data);
        } else if(res.status === 401) {
          Swal.fire(
            "Gagal!",
            "Anda tidak memiliki akses untuk mengakses halaman ini.",
            "error"
          ).then((result) => {
            if (result.isConfirmed) {
              window.location.href = "/admin/borrow";
            }
          });
        } else {
          Swal.fire("Gagal!", "Terjadi kesalahan, silahkan coba lagi.", "error");
        }
      });      
    }
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDropChange = (acceptedFiles) => {
    setImages(images.concat(acceptedFiles));
  };

  const handleDropRemove = (file) => {
    const newFiles = images.filter((f) => f !== file);
    setImages(newFiles);
  };

  const handleDeleteFile = (file) => {
    Swal.fire({
      title: "Apakah Anda yakin?",
      text: "Ketika data diperbarui, file yang dihapus tidak akan bisa dikembalikan!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, hapus file ini!",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.isConfirmed) {
        const newFiles = borrowData.images.filter((f) => f !== file);
        setBorrowData({ ...borrowData, images: newFiles });
        setDeletedFiles(deletedImages.concat(file.filename));
        Swal.fire("Berhasil!", "File Anda masuk kedalam antrean untuk dihapus, file akan dihapus setelah data diperbarui.", "success");
      }
    });
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024,
      dm = 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  useEffect(() => {
    if (quill) {
      quill.on('text-change', (delta, oldDelta, source) => {
        setContent(quill.root.innerHTML);
      });
      quill.clipboard.dangerouslyPasteHTML(borrowData.description);
    }
  }, [quill, borrowData.description]);

  const onFormSubmit = (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", e.name);
    formData.append("description", content);
    formData.append("stok", e.stok);
    for (let i = 0; i < images.length; i++) {
      formData.append('images', images[i]);
    }
    formData.append("deletedImages", deletedImages);
    formData.append("status", "published");
    const response = api.put(`/admin/borrow/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    response.then((res) => {
      if(res.data.success){
        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: res.data.message,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/borrow";
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Gagal",
          text: res.data.message,
        });
      }
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Gagal",
        text: err.response.data.message,
      });
    })
    .finally(() => {
      setLoading(false);
    });
  };
  return (
    <React.Fragment>
      <Head title="Ubah Barang Pinjaman" />
      <Content page="component">
        <BlockHead size="lg" wide="sm">
          <BlockHeadContent>
            <BackTo link="/admin/borrow" icon="arrow-left">
              Semua Barang Pinjaman
            </BackTo>
          </BlockHeadContent>
        </BlockHead>

        <Block size="lg">
          <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)} encType="multipart/form-data">
            <Row>
              <Col lg="12">
                <PreviewCard>
                  <FormGroup>
                    <Label htmlFor="name" className="form-label" required>Nama Barang</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="text" 
                        id="name" 
                        name="name" 
                        placeholder="Masukkan nama barang" 
                        defaultValue={borrowData.name}
                        ref={register({ required: "Nama Barang wajib diisi" })}
                      />
                    </div>
                    {errors.name && <span className="text-danger">{errors.name.message}</span>}
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="stok" className="form-label" required>Stok Barang</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="number" 
                        id="stok" 
                        name="stok" 
                        placeholder="Masukkan nama barang" 
                        defaultValue={borrowData.stok}
                        ref={register({ required: "Stok Barang wajib diisi" })}
                      />
                    </div>
                    {errors.stok && <span className="text-danger">{errors.stok.message}</span>}
                  </FormGroup>
                  <FormGroup>
                    <Label className="form-label">Deskripsi</Label>
                    <div className="form-control-wrap">
                      <div style={{ width: "100%", height: "100%" }}>
                        <div ref={quillRef} />
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label className="form-label">Foto Barang</Label>
                    <div className="form-control-wrap">
                      <Dropzone onDrop={(acceptedFiles) => handleDropChange(acceptedFiles)}>
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div {...getRootProps()} className="dropzone upload-zone dz-clickable">
                              <input {...getInputProps()} />
                              <div className="dz-message">
                                <span className="dz-message-text">Drag and drop file</span>
                                <span className="dz-message-or">or</span>
                                <span className="dz-message-text">Browse File</span>
                              </div>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      <aside>
                        {images.length > 0 && <Label htmlFor="title" className="form-label">Foto Belum Terupload</Label>}
                        {/* <Label htmlFor="title" className="form-label">File Belum Terupload</Label> */}
                        <ul>
                          <table className="table table-tranx is-compact">
                            <tbody>
                              {images.map((file) => (
                                <tr key={file.name} className="tb-tnx-item">
                                  <td style={{width: '75vw'}}>
                                    {file.name} - {formatFileSize(file.size)}
                                  </td>
                                  <td>
                                    <Button color="danger" onClick={() => handleDropRemove(file)}>Hapus</Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </ul>
                      </aside>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    {borrowData.images.length > 0 && <Label htmlFor="title" className="form-label">Foto Terupload</Label>}
                    <Row className="g-gs" key={'file-row'}>
                      {borrowData.images.map((file) => (
                        <Col key={file.filename} lg="4">
                          <PreviewAltCard>
                            <div className="team">
                              <div className="user-card user-card-s2">
                                <div className="user-info">
                                  <h6>{file.originalname}</h6>
                                </div>
                              </div>
                              <ul className="team-info">
                                <li>
                                  <span>Ukuran File</span>
                                  <span>{formatFileSize(file.size)}</span>
                                </li>
                                <li>
                                  <span>Tanggal Upload</span>
                                  <span>{moment(file.uploadDate).format("DD MMMM YYYY")}</span>
                                </li>
                              </ul>
                              <div className="team-view">
                                <Row>
                                  <Col lg="12">
                                    <Button color="danger" onClick={() => handleDeleteFile(file)}>Hapus</Button>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </PreviewAltCard>
                        </Col>
                      ))}
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col lg="6">
                        <Button size="lg" className="btn-block" type="submit" color="primary" disabled={loading}>
                          {loading ? <Spinner size="sm" color="light" /> : "Perbarui"}
                        </Button>
                      </Col>
                      <Col lg="6">
                        <NavLink to="/admin/borrow">
                          <Button size="lg" className="btn-block" type="reset" color="light">
                            Kembali
                          </Button>
                        </NavLink>
                      </Col>
                    </Row>
                  </FormGroup>
                </PreviewCard>
              </Col>
            </Row>
          </Form>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default BorrowItemEdit;
