import React from 'react'
import facility1 from '../../assets/images/blog/blog-img11-transparant.png'
import facility2 from '../../assets/images/about/about-img12.png'
import facility3 from '../../assets/images/about/about-img5.png'
import { useTranslation } from "react-i18next";

const AboutUs = () => {
    const { t } = useTranslation();
    return (
        <section className="overview-area ptb-100 pt-0">
            <div className="container">
                <div className="section-title">
                    {/* <span className="sub-title">About Us</span> */}
                    <h2>{t("facilities.title")}</h2>
                    {/* <p  style={{fontSize:"20px"}}>Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.</p> */}
                </div>

                <div className="overview-box">
                    <div className="overview-content">
                        <div className="content">
                            <span className="sub-title">{t("facilities")}</span>
                            <h2>{t("facilities.box.title")}</h2>
                            {/* <p  style={{fontSize:"20px"}}>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication.</p> */}

                            <div className="features-text">
                                <p  style={{fontSize:"20px"}}><i className="flaticon-tick"></i> {t("facilities.box.desc1")}</p>
                            </div>

                            <div className="features-text">
                                <p  style={{fontSize:"20px"}}><i className="flaticon-tick"></i> {t("facilities.box.desc2")}</p>
                            </div>

                            <div className="features-text">
                                <p  style={{fontSize:"20px"}}><i className="flaticon-tick"></i> {t("facilities.box.desc3")}</p>
                            </div>

                            <div className="features-text">
                                <p  style={{fontSize:"20px"}}><i className="flaticon-tick"></i> {t("facilities.box.desc4")}</p>
                            </div>
                            <div className="features-text">
                                <p  style={{fontSize:"20px"}}><i className="flaticon-tick"></i> {t("facilities.box.desc5")}</p>
                            </div>

                            <div className="features-text">
                                <p  style={{fontSize:"20px"}}><i className="flaticon-tick"></i> {t("facilities.box.desc6")}</p>
                            </div>
                        </div>
                    </div>

                    <div className="overview-image">
                        <div className="image">
                            <img src={facility1} alt="about" />
                        </div>
                    </div>
                </div>

                <div className="overview-box">
                    <div className="overview-image">
                        <div className="image">
                            <img src={facility2} alt="features" />
                        </div>
                    </div>
                    
                    <div className="overview-content">
                        <div className="content right-content">
                            <span className="sub-title">{t("facilities")}</span>
                            <h2>{t("facilities.sc.title")}</h2>
                            {/* <p  style={{fontSize:"20px"}}>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication.</p> */}

                            <div className="features-text">
                                <p  style={{fontSize:"20px"}}><i className="flaticon-tick"></i> {t("facilities.sc.desc1")}</p>
                            </div>

                            <div className="features-text">
                                <p  style={{fontSize:"20px"}}><i className="flaticon-tick"></i> {t("facilities.sc.desc2")}</p>
                            </div>

                            <div className="features-text">
                                <p  style={{fontSize:"20px"}}><i className="flaticon-tick"></i> {t("facilities.sc.desc3")}</p>
                            </div>

                            <div className="features-text">
                                <p  style={{fontSize:"20px"}}><i className="flaticon-tick"></i> {t("facilities.sc.desc4")}</p>
                            </div>

                            <div className="features-text">
                                <p  style={{fontSize:"20px"}}><i className="flaticon-tick"></i> {t("facilities.sc.desc5")}</p>
                            </div>

                            <div className="features-text">
                                <p  style={{fontSize:"20px"}}><i className="flaticon-tick"></i> {t("facilities.sc.desc6")}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="overview-box">
                    <div className="overview-content">
                        <div className="content">
                            <span className="sub-title">{t("facilities")}</span>
                            <h2>{t("facilities.lab.title")}</h2>
                            {/* <p  style={{fontSize:"20px"}}>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication.</p> */}

                            <div className="features-text">
                                <p  style={{fontSize:"20px"}}><i className="flaticon-tick"></i> {t("facilities.lab.desc1")}</p>
                            </div>

                            <div className="features-text">
                                <p  style={{fontSize:"20px"}}><i className="flaticon-tick"></i> {t("facilities.lab.desc2")}</p>
                            </div>

                            <div className="features-text">
                                <p  style={{fontSize:"20px"}}><i className="flaticon-tick"></i> {t("facilities.lab.desc3")}</p>
                            </div>

                            <div className="features-text">
                                <p  style={{fontSize:"20px"}}><i className="flaticon-tick"></i> {t("facilities.lab.desc4")}</p>
                            </div>
                            
                            <div className="features-text">
                                <p  style={{fontSize:"20px"}}><i className="flaticon-tick"></i> {t("facilities.lab.desc5")}</p>
                            </div>

                            <div className="features-text">
                                <p  style={{fontSize:"20px"}}><i className="flaticon-tick"></i> {t("facilities.lab.desc6")}</p>
                            </div>
                            
                            <div className="features-text">
                                <p  style={{fontSize:"20px"}}><i className="flaticon-tick"></i> {t("facilities.lab.desc7")}</p>
                            </div>

                            <div className="features-text">
                                <p  style={{fontSize:"20px"}}><i className="flaticon-tick"></i> {t("facilities.lab.desc8")}</p>
                            </div>
                            
                            <div className="features-text">
                                <p  style={{fontSize:"20px"}}><i className="flaticon-tick"></i> {t("facilities.lab.desc9")}</p>
                            </div>

                            <div className="features-text">
                                <p  style={{fontSize:"20px"}}><i className="flaticon-tick"></i> {t("facilities.lab.desc10")}</p>
                            </div>
                        </div>
                    </div>

                    <div className="overview-image">
                        <div className="image">
                            <img src={facility3} alt="features" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutUs