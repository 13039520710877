import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import TeamMembers from '../../components/Penelitian/EUMKM/TeamMembers'
import { useTranslation } from "react-i18next";

const EUMKM = () => {
    const { t } = useTranslation();
    return (
        <Layout title={t("research.eumkm")}>
            <Navbar />
            <PageBanner
                pageTitle={t("research.eumkm")}
                homePageText={t("home")}
                homePageUrl="/" 
                activePageText={t("research.eumkm")}
            />
            <section className="privacy-policy-area ptb-100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12">
                            <div className="privacy-policy-content">
                                <h2>{t("research.eumkm.title")}</h2>
                                <p  style={{fontSize:"20px"}}>{t("research.eumkm.p1")}</p>
                                <p  style={{fontSize:"20px"}}>{t("research.eumkm.p2")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <TeamMembers />
            <Footer />
        </Layout>
    )
}

export default EUMKM;