import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import { Modal, ModalBody, FormGroup, Spinner, Card, Form } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  Button,
} from "../../../components/Component";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import api from "../../../../jwtAuth";

const UserProfileSettingPage = ({ sm, updateSm }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { errors, register, handleSubmit } = useForm();

  const onFormSubmit = (e) => {
    setLoading(true);
    const response = api.post("/user/profile/changePassword", {
      oldPassword: e.oldPassword,
      newPassword: e.newPassword,
      confirmPassword: e.confirmPassword,
      email: `${localStorage.getItem("email")}`,
    });
    response.then((res) => {
      if (res.data.success) {
        Swal.fire({
          title: "Berhasil",
          text: res.data.message,
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            setModal(false);
          }
        });
      } else {
        Swal.fire({
          title: "Gagal",
          text: res.data.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    })
    .catch((err) => {
      Swal.fire({
        title: "Gagal",
        text: err.response.data.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    })
    .finally(() => {
      setLoading(false);
    });
  };
  return (
    <React.Fragment>
      <Head title="Profil"></Head>

      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Pengaturan Keamanan</BlockTitle>
            <BlockDes>
              <p>Pengaturan ini akan membantu Anda menjaga keamanan akun Anda.</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <Card className="card-bordered">
          <div className="card-inner-group">
            <div className="card-inner">
              <div className="between-center flex-wrap g-3">
                <div className="nk-block-text">
                  <h6>Ubah Kata Sandi</h6>
                  <p>Tetapkan kata sandi unik untuk melindungi akun Anda.</p>
                </div>
                <div className="nk-block-actions flex-shrink-sm-0">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                    <li className="order-md-last">
                      <Button color="primary" onClick={() => setModal(true)}>Ubah Kata Sandi</Button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Block>
      <Modal isOpen={modal} className="modal-dialog-centered" size="lg" toggle={() => setModal(false)}>
        <ModalBody>
          <a
            href="#dropdownitem"
            onClick={(ev) => {
              ev.preventDefault();
              setModal(false);
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Ubah Kata Sandi</h5>
            <div className="tab-content">
              <div className={`tab-pane active`} id="password">
                <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
                  <Row className="gy-4">
                    <Col md="4">
                      <FormGroup>
                        <label className="form-label" htmlFor="oldPassword">
                          Kata sandi lama
                        </label>
                        <input
                          type="password"
                          id="oldPassword"
                          className="form-control"
                          name="oldPassword"
                          ref={register({ required: "Kata sandi lama wajib diisi" })}
                          placeholder="Masukkan kata sandi lama"
                        />
                        {errors.oldPassword && <span className="text-danger">{errors.oldPassword.message}</span>}
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="form-label" htmlFor="newPassword">
                          Kata sandi baru
                        </label>
                        <input
                          type="password"
                          id="newPassword"
                          className="form-control"
                          name="newPassword"
                          ref={register({ required: "Kata sandi baru wajib diisi", minLength: { value: 6, message: "Kata sandi minimal 6 karakter" } })}
                          placeholder="Masukkan kata sandi baru"
                        />
                        {errors.newPassword && <span className="text-danger">{errors.newPassword.message}</span>}
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label className="form-label" htmlFor="confirmPassword">
                          Konfirmasi kata sandi baru
                        </label>
                        <input
                          type="password"
                          id="confirmPassword"
                          className="form-control"
                          name="confirmPassword"
                          ref={register({ required: "Konfirmasi kata sandi baru wajib diisi", minLength: { value: 6, message: "Kata sandi minimal 6 karakter" }})}
                          placeholder="Masukkan konfirmasi kata sandi baru"
                        />
                        {errors.confirmPassword && <span className="text-danger">{errors.confirmPassword.message}</span>}
                      </FormGroup>
                    </Col>
                    <Col size="12">
                      <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                          <Button
                            color="primary"
                            type="submit"
                            size="lg"
                            disabled={loading}
                          >
                            {loading ? <Spinner size="sm" color="light" /> : "Ubah Kata Sandi"}
                          </Button>
                        </li>
                        <li>
                          <a
                            href="#dropdownitem"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setModal(false);
                            }}
                            className="link link-light"
                          >
                            Batal
                          </a>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default UserProfileSettingPage;
