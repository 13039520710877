import React, { useEffect, useState } from "react"
import Navbar from "../../../components/App/Navbar"
import Footer from "../../../components/App/Footer"
import Layout from "../../../components/App/Layout"
import PageBanner from "../../../components/Common/PageBanner"
import WebinarDetail from "../../../components/Webinar/WebinarDetail"
import { RedirectAs404 } from "../../../../admin/utils/Utils"
import api from "../../../../jwtAuth"
import { Route, useParams } from "react-router"
import { useTranslation } from "react-i18next";

const DetailWebinar = () => {
  const { t } = useTranslation();
  const [webinar, setWebinar] = useState([]);
  const [prevPage, setPrevPage] = useState([]);
  const [nextPage, setNextPage] = useState([]);  
  const [title, setTitle] = useState(t("webinar"));
  const { slug } = useParams();
  const [slugWebinar, setSlugWebinar] = useState(slug);
  const [errorCode, setErrorCode] = useState(0);

  // Get Data
  async function getData() {
    try {
      const response = await api.get('/public/webinar/' + slugWebinar);
      setWebinar(response.data.data);
      setPrevPage(response.data.previousWebinar);
      setNextPage(response.data.nextWebinar);
      setTitle(response.data.data.title);
    } catch (error) {
      // console.error(error);
      if (error.response.status === 401) {
        getData();
      }
      setErrorCode(error.response.status);
    }
  }
  useEffect(() => {
    getData();
  }, [slugWebinar]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    changeSlug(slug);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Change Slug
  const changeSlug = (slug) => {
    setSlugWebinar(slug);
  }

  if(errorCode === 404) {
    return <Route component={RedirectAs404}></Route>
  }
  return (
    <Layout title={title}>
      <Navbar />
      <PageBanner
        pageTitle={t("webinar.pageTitle")}
        homePageText={t("home")}
        homePageUrl="/"
        activePageText={t("webinar.pageTitle")}
      />
      <WebinarDetail 
        webinar={webinar} 
        prevPage={prevPage}
        nextPage={nextPage}
        changeSlug={changeSlug}
      />
      <Footer />
    </Layout>
  )
}

export default DetailWebinar;