import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import shape1 from '../../assets/images/shape/vector-shape1.png'
import shape2 from '../../assets/images/shape/vector-shape2.png'
import logo from '../../assets/images/logo-COE.png'
import api from '../../../jwtAuth'
import moment from 'moment'
import {useTranslation} from "react-i18next";

const Berita = () => {
  const {t} = useTranslation();
  const [news, setNews] = useState([]);

  // Get Data
  async function getData() {
    try {
      const response = await api.get('/public/news');
      setNews(response.data.data.results);
    } catch (error) {
      // console.error(error);
      if (error.response.status === 401) {
        getData();
      }
    }
  }

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <section className="blog-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">{t("blog")}</span>
          <h2>Berita Terbaru</h2>
          <p>Informasi terbaru dari CoE Udinus.</p>
        </div>

        <div className="row">
          {news.map((item, index) => (
            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" key={item._id}>
              <div className="single-blog-post bg-fffbf5 d-flex flex-column" style={{width: "100%"}}>
                <div className="post-image">
                  <Link to={`berita/${item.slug}`}>
                    {item.image !== "null" ? (
                      <img src={`${process.env.REACT_APP_API_URL + "/" + item.image}`} alt="banner"
                           style={{height: "237px"}}/>
                    ) : (
                      <img src={logo} alt="banner" style={{height: "237px"}}/>
                    )}
                  </Link>
                </div>

                <div className="post-content">
                  <ul className="post-meta d-flex justify-content-between align-items-center">
                    <li>
                      <div className="post-author d-flex align-items-center">
                        {/* <img src={user1} className="rounded-circle" alt="partner" /> */}
                        <span>{item.createdBy && item.createdBy.name}</span>
                      </div>
                    </li>
                    <li>
                      <i className='flaticon-calendar'></i> {moment(item.createdAt).format("DD MMMM YYYY")}
                    </li>
                  </ul>
                  <h3>
                    <Link to={`berita/${item.slug}`}>
                      {item.title.length > 50 ? item.title.substring(0, 50) + "..." : item.title}
                    </Link>
                  </h3>
                </div>
              </div>
            </div>
          ))}
        </div>

      </div>

      <div className="vector-shape1">
        <img src={shape1} alt="partner"/>
      </div>
      <div className="vector-shape2">
        <img src={shape2} alt="partner"/>
      </div>
    </section>
  )
}

export default Berita