import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  Icon,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  RSelect,
  BackTo,
  PreviewCard,
} from "../../../components/Component";
import { Button, Col, Form, FormGroup, Label, Row, Spinner, DropdownMenu, DropdownItem, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { useForm } from "react-hook-form";
import { useQuill } from "react-quilljs";
import { NavLink, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../../../jwtAuth";

const TrainingEdit = () => {
  const [loading, setLoading] = useState(false);
  const { errors, register, handleSubmit } = useForm();
  const [content, setContent] = useState('<p></p>');
  const { quill, quillRef } = useQuill();
  const [selectedFile, setSelectedFile] = useState(null);
  const [training, setTraining] = useState({
    name: "",
    description: "",
    quota: "",
    event_date: "",
    registration_close_date: "",
    price: "",
    location: "",
    status: "",
  });
  const [registration, setRegistration] = useState([]);
  const [data, setData] = useState([]);
  const bulkActionTraining = [
    { value: "delete", label: "Hapus Peserta" },
    { value: "confirmed", label: "Konfirmasikan Peserta" },
    { value: "rejected", label: "Tolak Peserta" },
  ];

  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [actionText, setActionText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");
  const { id } = useParams();

  async function fetchData(){
    const response = api.get(`/admin/training/${id}`);
    response.then((res) => {
      if (res.data.success) {
        setTraining(res.data.data.training);
        setRegistration(res.data.data.registration);
        setData(res.data.data.registration);
      }
    });      
  }
  
  useEffect(() => {
    fetchData();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    if (quill) {
      quill.on('text-change', (delta, oldDelta, source) => {
        setContent(quill.root.innerHTML);
      });
      quill.clipboard.dangerouslyPasteHTML(training.description);
    }
  }, [quill, training.description]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const onFormSubmit = (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", e.name);
    formData.append("description", content);
    formData.append("quota", e.quota);
    formData.append("event_date", e.event_date);
    formData.append("registration_close_date", e.registration_close_date);
    formData.append("price", e.price);
    formData.append("location", e.location);
    formData.append("banner", selectedFile);
    formData.append("status", e.status);
    
    const response = api.put(`/admin/training/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    response.then((res) => {
      if (res.data.success) {
        Swal.fire({
          title: "Berhasil!",
          text: res.data.message,
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/training";
          }
        });
      } else {
        Swal.fire({
          title: "Gagal!",
          text: res.data.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    })
    .catch((err) => {
      Swal.fire({
        title: "Gagal!",
        text: err.response.data.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    })
    .finally(() => {
      setLoading(false);
    });
  };

  // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.name.localeCompare(b.name));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.name.localeCompare(a.name));
      setData([...sortedData]);
    }
  };

  // unselects the data on mount
  useEffect(() => {
    let newData;
    newData = registration.map((item) => {
      item.checked = false;
      return item;
    });
    setData([...newData]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = registration.filter((item) => {
        return (
          item.name.toLowerCase().includes(onSearchText.toLowerCase())
        );
      });
      setData([...filteredObject]);
    } else {
      setData([...registration]);
    }
  }, [onSearchText, setData]); // eslint-disable-line react-hooks/exhaustive-deps

  // function to set the action to be taken in table header
  const onActionText = (e) => {
    setActionText(e.value);
  };

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to change the selected property of an item
  const onSelectChange = (e, id) => {
    let newData = data;
    let index = newData.findIndex((item) => item._id === id);
    newData[index].checked = e.currentTarget.checked;
    setData([...newData]);
  };

  // function to change the check property of an item
  const selectorCheck = (e) => {
    let newData;
    newData = data.map((item) => {
      item.checked = e.currentTarget.checked;
      return item;
    });
    setData([...newData]);
  };

  // function which fires on applying selected action
  const onActionClick = (e) => {
    setLoading(true);
    if (actionText !== "") {
      let newData;
      newData = data.filter((item) => item.checked === true);
      const response = api.put(`/admin/training/${id}/updateRegistration`, {
        idRegistration: newData.map((item) => item._id),
        status: actionText
      });
      response.then((res) => {
        if(res.data.success){
          Swal.fire({
            title: "Berhasil!",
            text: "Data berhasil diubah!",
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              fetchData();
            }
          });
        } else {
          Swal.fire({
            title: "Oops!",
            text: res.data.message,
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      });
    } else {
      Swal.fire({
        title: "Gagal!",
        text: "Pilih aksi yang akan dilakukan terhadap data",
        icon: "error",
        showCancelButton: false,
        confirmButtonText: "OK",
      });
    }
    setLoading(false);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <Head title="Edit Pelatihan" />
      <Content page="component">
        <BlockHead size="lg" wide="sm">
          <BlockHeadContent>
            <BackTo link="/admin/training" icon="arrow-left">
              Semua Pelatihan
            </BackTo>
          </BlockHeadContent>
        </BlockHead>

        <Block size="lg">
          <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)} encType="multipart/form-data">
            <PreviewCard>
              <Row className="g-2">
                <Col lg="4">
                  <FormGroup>
                    <Label htmlFor="name" className="form-label" required>Nama Pelatihan</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="text" 
                        id="name" 
                        name="name" 
                        placeholder="Masukkan nama pelatihan" 
                        defaultValue={training.name}
                        ref={register({ required: "Nama Pelatihan wajib diisi" })}
                      />
                    </div>
                    {errors.name && <span className="text-danger">{errors.name.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <Label htmlFor="quota" className="form-label" required>Kuota Peserta</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="number" 
                        id="quota" 
                        name="quota" 
                        placeholder="Masukkan jumlah kuota" 
                        defaultValue={training.quota}
                        ref={register({ required: "Kuota Peserta wajib diisi", min: { value: 1, message: "Kuota Peserta minimal 1" } })}
                      />
                    </div>
                    {errors.quota && <span className="text-danger">{errors.quota.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <Label htmlFor="price" className="form-label" required>Harga</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="number" 
                        id="price" 
                        name="price" 
                        placeholder="Masukkan harga pelatihan (0 jika gratis)" 
                        defaultValue={training.price}
                        ref={register({ required: "Harga wajib diisi" })}
                      />
                    </div>
                    {errors.price && <span className="text-danger">{errors.price.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="event_date" className="form-label" required>Tanggal Acara</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="date" 
                        id="event_date" 
                        name="event_date" 
                        placeholder="Masukkan Tanggal Acara" 
                        defaultValue={training.event_date ? training.event_date.split("T")[0] : ""}
                        ref={register({ required: "Tanggal Acara wajib diisi" })}
                      />
                    </div>
                    {errors.event_date && <span className="text-danger">{errors.event_date.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="registration_close_date" className="form-label" required>Tanggal Terakhir Pendaftaran</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="date" 
                        id="registration_close_date" 
                        name="registration_close_date" 
                        placeholder="Masukkan Tanggal Terakhir Pendaftaran" 
                        defaultValue={training.registration_close_date ? training.registration_close_date.split("T")[0] : ""}
                        ref={register({ required: "Tanggal Terakhir Pendaftaran wajib diisi" })}
                      />
                    </div>
                    {errors.registration_close_date && <span className="text-danger">{errors.registration_close_date.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <Label htmlFor="location" className="form-label" required>Lokasi</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="text" 
                        id="location" 
                        name="location" 
                        placeholder="Masukkan lokasi acara" 
                        defaultValue={training.location}
                        ref={register({ required: "Lokasi wajib diisi" })}
                      />
                    </div>
                    {errors.location && <span className="text-danger">{errors.location.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <Label htmlFor="banner" className="form-label" required>Banner Pelatihan (Opsional)</Label>
                    <div className="form-control-wrap">
                      <input className="form-control" type="file" name="banner" onChange={handleFileChange} />
                    </div>
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <Label htmlFor="status" className="form-label" required>Status</Label>
                    <div className="form-control-wrap">
                      <select
                        className="form-select form-control form-control-lg"
                        id="status"
                        name="status"
                        value={training.status}
                        onChange={(e) => setTraining({ ...training, status: e.target.value })}
                        ref={register({ required: "Status wajib diisi" })}
                      >
                        <option value="">Pilih Status</option>
                        <option value="published">Publikasi</option>
                        <option value="draft">Draft</option>
                      </select>
                    </div>
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <Label htmlFor="title" className="form-label">Isi Pelatihan</Label>
                    <div className="form-control-wrap">
                      <div style={{ width: "100%", height: "100%" }}>
                        <div ref={quillRef} />
                      </div>
                    </div>
                  </FormGroup>
                </Col>
                <FormGroup>
                  <Row>
                    <Col lg="6">
                      <Button size="lg" className="btn-block" type="submit" color="primary" disabled={loading}>
                        {loading ? <Spinner size="sm" color="light" /> : "Simpan"}
                      </Button>
                    </Col>
                    <Col lg="6">
                      <NavLink to="/admin/training">
                        <Button size="lg" className="btn-block" type="reset" color="light">
                          Kembali
                        </Button>
                      </NavLink>
                    </Col>
                  </Row>
                </FormGroup>
              </Row>              
            </PreviewCard>
          </Form>
        </Block>
        <Block>
          <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="card-tools">
                  <div className="form-inline flex-nowrap gx-3">
                    <div className="form-wrap">
                      <RSelect
                        options={bulkActionTraining}
                        className="w-200px"
                        placeholder="Tindakan Massal"
                        onChange={(e) => onActionText(e)}
                      />
                    </div>
                    <div className="btn-wrap">
                      <span className="d-none d-md-block">
                        <Button
                          disabled={actionText === "" || loading ? true : false}
                          color="light"
                          outline
                          className="btn-dim"
                          onClick={(e) => onActionClick(e)}
                        >
                          {loading ? <Spinner size="sm" color="light" /> : "Terapkan"}
                        </Button>
                      </span>
                      <span className="d-md-none">
                        <Button
                          color="light"
                          outline
                          disabled={actionText === "" || loading ? true : false}
                          className="btn-dim btn-icon"
                          onClick={(e) => onActionClick(e)}
                        >
                          {loading ? <Spinner size="sm" color="light" /> : <Icon name="arrow-right"></Icon>}
                        </Button>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card-tools mr-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                                <Icon name="arrow-left"></Icon>
                              </Button>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle color="tranparent" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <Icon name="setting"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right className="dropdown-menu-xs">
                                  <ul className="link-check">
                                    <li>
                                      <span>Tampilkan</span>
                                    </li>
                                    <li className={itemPerPage === 10 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(10);
                                        }}
                                      >
                                        10
                                      </DropdownItem>
                                    </li>
                                    <li className={itemPerPage === 15 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(15);
                                        }}
                                      >
                                        15
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                  <ul className="link-check">
                                    <li>
                                      <span>Urutkan</span>
                                    </li>
                                    <li className={sort === "dsc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("dsc");
                                          sortFunc("dsc");
                                        }}
                                      >
                                        DESC
                                      </DropdownItem>
                                    </li>
                                    <li className={sort === "asc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("asc");
                                          sortFunc("asc");
                                        }}
                                      >
                                        ASC
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Cari berdasarkan judul"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <DataTableBody>
              <DataTableHead>
                <DataTableRow className="nk-tb-col-check">
                  <div className="custom-control custom-control-sm custom-checkbox notext">
                    <input
                      type="checkbox"
                      className="custom-control-input form-control"
                      onChange={(e) => selectorCheck(e)}
                      id="uid"
                    />
                    <label className="custom-control-label" htmlFor="uid"></label>
                  </div>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">Nama Peserta</span>
                </DataTableRow>
                <DataTableRow size="mb">
                  <span className="sub-text">NIM / NPP</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Email</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Status</span>
                </DataTableRow>
              </DataTableHead>
              {/*Head*/}
              {currentItems.length > 0
                ? currentItems.map((item) => {
                    return (
                      <DataTableItem key={item._id}>
                        <DataTableRow className="nk-tb-col-check">
                          <div className="custom-control custom-control-sm custom-checkbox notext">
                            <input
                              type="checkbox"
                              className="custom-control-input form-control"
                              defaultChecked={item.checked}
                              id={item._id + "uid1"}
                              key={Math.random()}
                              onChange={(e) => onSelectChange(e, item._id)}
                            />
                            <label className="custom-control-label" htmlFor={item._id + "uid1"}></label>
                          </div>
                        </DataTableRow>
                        <DataTableRow>
                          <span className="tb-amount">
                            {item && item.user_id && item.user_id.name ? item.user_id.name : "Belum ada nama"}
                          </span>
                        </DataTableRow>
                        <DataTableRow size="mb">
                          {item && item.user_id && item.user_id.education && item.user_id.education.identity_number ? item.user_id.education.identity_number : "Belum ada NIM"}
                        </DataTableRow>
                        <DataTableRow size="md">
                          {item && item.user_id && item.user_id.email ? item.user_id.email : "Belum ada email"}
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span
                            className={`tb-status text-${
                              item.status === "pending" ? "warning" : item.status === "confirmed" ? "success" : "danger"
                            }`}
                          >
                            {item.status === "pending" ? "Menunggu Pembayaran" : item.status === "confirmed" ? "Terkonfirmasi" : "Ditolak"}
                          </span>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={data.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">Tidak ada data ditemukan</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default TrainingEdit;
