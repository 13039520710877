import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PaginationComponent from '../App/Pagination'
import logo from '../../assets/images/logo-COE.png'
import api from '../../../jwtAuth'
import moment from 'moment'
import { useTranslation } from "react-i18next";

const Pelatihan = () => {
  const { t } = useTranslation();
  const [training, setTraining] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(6);
  const [totalItems, setTotalItems] = useState(0);
  // Get Data
  async function getData() {
    try {
      const response = await api.get('/public/training/?page=' + currentPage + '&limit=' + itemPerPage);
      setTraining(response.data.data.results);
      setTotalItems(response.data.data.count);
    } catch (error) {
      getData();
    }
  }
  useEffect(() => {
    getData();
  }, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  // Change Page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div className="blog-area ptb-100">
      <div className="container">
        <div className="row">
          {training.map((item, index) => (
            <div className="col-lg-4 col-sm-6 col-md-6" key={item._id}>
              <div className="single-events-box">
                <div className="image">
                  <Link to={`pelatihan/${item.slug}`} className="d-block">
                    {item.banner !== "null" ? (
                      <img src={`${process.env.REACT_APP_API_URL + "/" + item.banner}`} alt="banner" />
                    ) : (
                      <img src={logo} alt="banner" />
                    )}
                  </Link>
                  <span className="date">{moment(item.event_date).format("LL")}</span>
                </div>

                <div className="content">
                  <h3>
                    <Link to={`pelatihan/${item.slug}`}>
                      {localStorage.getItem("lang") === "en" ? item.name_en : item.name}
                    </Link>
                  </h3>
                  <span className="location">
                    <i className="bx bx-map"></i> {item.location}
                  </span>
                </div>
              </div>
            </div>
          ))}

          <div className="col-lg-12 col-md-12">
            {/* Pagination */}
            <div className="pagination-area text-center">
              {training.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={totalItems}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">{t('dataNotFound')}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pelatihan