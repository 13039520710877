import React from 'react';
import { NavLink } from 'react-router-dom';
import { useRecoilState } from 'recoil'
import { collapsedState } from '../../utils/recoil-atoms'
import logo from "../../assets/images/logo-COE.png"
import { useTranslation } from "react-i18next";

const Navbar = () => {
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem("lang", lng);
    };
    const [collapsed, setCollapsed] = useRecoilState(collapsedState);
    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }

    React.useEffect(() => {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
    })

    const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return (
        <React.Fragment>
            <div id="navbar" className="navbar-area">
                <div className="tarn-nav">
                    <div className="w-95 container-fluid">
                        <nav className="navbar navbar-expand-lg navbar-dark">
                            <NavLink
                                to={`${process.env.PUBLIC_URL}/`}
                                onClick={() => setCollapsed(true)}
                                className="navbar-brand logo text-dark h2 mb-0"
                            >
                                <img src={logo} width="100px" height="200px" alt="logo" />
                            </NavLink>

                            <button
                                onClick={toggleNavbar}
                                className={classTwo}
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>

                            <div className={classOne} id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <NavLink
                                            exact
                                            to={`${process.env.PUBLIC_URL}/`}
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="nav-link"
                                        > {t("menu.home")} </NavLink>
                                    </li>

                                    <li className="nav-item">
                                        <NavLink
                                            exact
                                            to={`${process.env.PUBLIC_URL}/pelatihan`}
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="nav-link"
                                        > {t("menu.training")} </NavLink>
                                    </li>

                                    <li className="nav-item">
                                        <NavLink
                                            to={`${process.env.PUBLIC_URL}/tentang-kami`}
                                            activeClassName="active"
                                            onClick={e => e.preventDefault()}
                                            className="nav-link"
                                        >{t("menu.about")} <i className='bx bx-chevron-down'></i>
                                        </NavLink>

                                        <ul className="dropdown-menu">
                                            <li className="nav-item">
                                                <NavLink
                                                    exact
                                                    to={`${process.env.PUBLIC_URL}/tentang-kami/profil`}
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >{t("menu.about.profile")}
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink
                                                    exact
                                                    to={`${process.env.PUBLIC_URL}/tentang-kami/fasilitas`}
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    {t("menu.about.facilities")}
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>

                                    {/*<li className="nav-item">*/}
                                    {/*    <NavLink*/}
                                    {/*        to="/agenda"*/}
                                    {/*        activeClassName="active"*/}
                                    {/*        onClick={e => e.preventDefault()}*/}
                                    {/*        className="nav-link"*/}
                                    {/*    >*/}
                                    {/*        {t("menu.eventSchedule")} <i className='bx bx-chevron-down'></i>*/}
                                    {/*    </NavLink>*/}

                                    {/*    <ul className="dropdown-menu">*/}
                                    {/*        <li className="nav-item">*/}
                                    {/*            <NavLink*/}
                                    {/*                exact*/}
                                    {/*                to={`${process.env.PUBLIC_URL}/agenda/webinar`}*/}
                                    {/*                activeClassName="active"*/}
                                    {/*                onClick={() => setCollapsed(true)}*/}
                                    {/*                className="nav-link"*/}
                                    {/*            >*/}
                                    {/*                {t("menu.eventSchedule.webinar")}*/}
                                    {/*            </NavLink>*/}
                                    {/*        </li>*/}

                                    {/*        /!* <li className="nav-item">*/}
                                    {/*            <NavLink*/}
                                    {/*                exact*/}
                                    {/*                to={`${process.env.PUBLIC_URL}/agenda/kunjungan`}*/}
                                    {/*                activeClassName="active"*/}
                                    {/*                onClick={() => setCollapsed(true)}*/}
                                    {/*                className="nav-link"*/}
                                    {/*            >*/}
                                    {/*                Kunjungan*/}
                                    {/*            </NavLink>*/}
                                    {/*        </li> *!/*/}
                                    {/*    </ul>*/}
                                    {/*</li>*/}

                                    <li className="nav-item">
                                        <NavLink
                                            exact
                                            to={`${process.env.PUBLIC_URL}/berita`}
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="nav-link"
                                        > {t("menu.news")} </NavLink>
                                    </li>

                                    {/*<li className="nav-item">*/}
                                    {/*    <NavLink*/}
                                    {/*        exact*/}
                                    {/*        to={`${process.env.PUBLIC_URL}/dataset`}*/}
                                    {/*        activeClassName="active"*/}
                                    {/*        onClick={() => setCollapsed(true)}*/}
                                    {/*        className="nav-link"*/}
                                    {/*    > {t("menu.dataset")} </NavLink>*/}
                                    {/*</li>*/}

                                    <li className="nav-item">
                                        <NavLink
                                            to="/penelitian"
                                            activeClassName="active"
                                            onClick={e => e.preventDefault()}
                                            className="nav-link"
                                        >
                                            {t("menu.research")} <i className='bx bx-chevron-down'></i>
                                        </NavLink>

                                        <ul className="dropdown-menu">

                                            <li className="nav-item">
                                                <NavLink
                                                  exact
                                                  to={`${process.env.PUBLIC_URL}/dataset`}
                                                  activeClassName="active"
                                                  onClick={() => setCollapsed(true)}
                                                  className="nav-link"
                                                > {t("menu.dataset")} </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink
                                                  exact
                                                  to={`${process.env.PUBLIC_URL}/publikasi`}
                                                  activeClassName="active"
                                                  onClick={() => setCollapsed(true)}
                                                  className="nav-link"
                                                >
                                                    {t("menu.research.publication")}
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink
                                                  exact
                                                  to={`${process.env.PUBLIC_URL}/penelitian/green-environment`}
                                                  activeClassName="active"
                                                  onClick={() => setCollapsed(true)}
                                                  className="nav-link"
                                                >
                                                    {t("menu.research.ge")}
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink
                                                  exact
                                                  to={`${process.env.PUBLIC_URL}/penelitian/bawang`}
                                                  activeClassName="active"
                                                  onClick={() => setCollapsed(true)}
                                                  className="nav-link"
                                                >
                                                    {t("menu.research.onion")}
                                                </NavLink>
                                            </li>

                                            {/* <li className="nav-item">
                                                <NavLink
                                                    exact
                                                    to={`${process.env.PUBLIC_URL}/penelitian/smart-city`}
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Smart City
                                                </NavLink>
                                            </li> */}
                                            {/* <li className="nav-item">
                                                <NavLink
                                                    exact
                                                    to="/e-chnh"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    E-CHNH
                                                </NavLink>
                                            </li> */}

                                            <li className="nav-item">
                                                <NavLink
                                                  exact
                                                  to={`${process.env.PUBLIC_URL}/penelitian/e-health`}
                                                  activeClassName="active"
                                                  onClick={() => setCollapsed(true)}
                                                  className="nav-link"
                                                >
                                                    {t("menu.research.ehealth")}
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink
                                                  exact
                                                  to={`${process.env.PUBLIC_URL}/penelitian/e-umkm`}
                                                  activeClassName="active"
                                                  onClick={() => setCollapsed(true)}
                                                  className="nav-link"
                                                >
                                                    {t("menu.research.eumkm")}
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>

                                    {/* <li className="nav-item">
                                        <NavLink
                                            to="/kerjasama"
                                            activeClassName="active"
                                            onClick={e => e.preventDefault()}
                                            className="nav-link"
                                        >
                                            Kerjasama <i className='bx bx-chevron-down'></i>
                                        </NavLink>

                                        <ul className="dropdown-menu">
                                            <li className="nav-item">
                                                <NavLink
                                                    exact
                                                    to={`${process.env.PUBLIC_URL}/kerjasama/dalam-negeri`}
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Dalam Negeri
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink
                                                    exact
                                                    to={`${process.env.PUBLIC_URL}/kerjasama/luar-negeri`}
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Luar Negeri
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li> */}

                                    {/*<li className="nav-item">*/}
                                    {/*    <NavLink*/}
                                    {/*        exact*/}
                                    {/*        to={`${process.env.PUBLIC_URL}/kontak-kami`}*/}
                                    {/*        activeClassName="active"*/}
                                    {/*        onClick={() => setCollapsed(true)}*/}
                                    {/*        className="nav-link"*/}
                                    {/*    > {t("menu.contact")} </NavLink>*/}
                                    {/*</li>*/}

                                    <li className="nav-item">
                                        <NavLink
                                            to={`#`}
                                            activeClassName=""
                                            onClick={e => e.preventDefault()}
                                            className="nav-link"
                                        >{t("menu.language")} <i className='bx bx-chevron-down'></i>
                                        </NavLink>

                                        <ul className="dropdown-menu">
                                            <li className="nav-item">
                                                <NavLink
                                                    exact
                                                    to={`#`}
                                                    activeClassName=""
                                                    onClick={() => changeLanguage('id')}
                                                    className="nav-link"
                                                >Indonesia
                                                </NavLink>
                                            </li>

                                            <li className="nav-item">
                                                <NavLink
                                                    exact
                                                    to={`#`}
                                                    activeClassName=""
                                                    onClick={() => changeLanguage('en')}
                                                    className="nav-link"
                                                >
                                                    English
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>

                                <div className="others-option d-flex align-items-center">
                                    {localStorage.getItem('email') === null ? (
                                    <div className="option-item">
                                        <NavLink
                                            exact
                                            to={`${process.env.PUBLIC_URL}/login`}
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="default-btn"
                                        >
                                            <i className="flaticon-right"></i> {t("menu.login")} <span></span>
                                        </NavLink>
                                    </div>
                                    ) : (
                                    <div className="option-item">
                                        <NavLink
                                            exact
                                            to={`${process.env.PUBLIC_URL}/panel`}
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="default-btn"
                                        >
                                            <i className="flaticon-user"></i> {t("menu.dashboard")} <span></span>
                                        </NavLink>
                                    </div>
                                    )}
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Navbar;
