import React, { useEffect, useState } from "react";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle } from "../../components/Component";
import Logo from "../../assets/images/logo-COE.png";
import LogoDark from "../../assets/images/logo-COE.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Link, useParams } from "react-router-dom";
import api from "../../../jwtAuth";

const Verify = () => {
  const { token } = useParams();
  const [ message, setMessage ] = useState("");
  useEffect(() => {
    const data = {
      code: token,
    };
    const response = api.post("/auth/verify", data);
    response.then((res) => {
      if (res.data.success) {
        setMessage(res.data.message);
      } else {
        setMessage(res.data.message);
      }
    })
    .catch((err) => {
      setMessage(err.response.data.message);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <React.Fragment>
      <Head title="Verifikasi Email" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body">
          <div className="brand-logo pb-5">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
            </Link>
          </div>
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h4">Verifikasi Email</BlockTitle>
              <BlockDes className="text-success">
                <p>{message}</p>
              </BlockDes>
            </BlockContent>
          </BlockHead>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default Verify;
