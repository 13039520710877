import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BackTo,
  PreviewCard,
} from "../../../components/Component";
import { Button, Col, Form, FormGroup, Label, Row, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { NavLink, useParams } from "react-router-dom";
import api from "../../../../jwtAuth";
import moment from "moment";

const BorrowingEdit = () => {
  const [loading, setLoading] = useState(false);
  const { errors, register, handleSubmit } = useForm();
  const [data, setData] = useState({});
  const [status, setStatus] = useState();
  const { id } = useParams();

  useEffect(() => {
    const response = api.get(`/admin/borrowing/${id}`);
    response.then((res) => {
      if(res.data.success) {
        setData(res.data.data);
        setStatus(res.data.data.status);
      } else {
        Swal.fire({
          title: "Gagal",
          text: res.data.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFormSubmit = (e) => {
    setLoading(true);
    const response = api.put(`/admin/borrowing/${id}`, {
      status: status,
      borrowing_date: e.borrowing_date,
      return_date: e.return_date,
      total_item: e.total_item,
    });
    response.then((res) => {
      if (res.data.success) {
        Swal.fire({
          title: "Berhasil",
          text: res.data.message,
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/borrowing";
          }
        });
      } else {
        Swal.fire({
          title: "Gagal",
          text: res.data.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    })
    .catch((err) => {
      Swal.fire({
        title: "Gagal",
        text: err.response.data.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    })
    .finally(() => {
      setLoading(false);
    });
  };
  return (
    <React.Fragment>
      <Head title="Ubah Data Peminjam" />
      <Content page="component">
        <BlockHead size="lg" wide="sm">
          <BlockHeadContent>
            <BackTo link="/admin/borrowing" icon="arrow-left">
              Semua Data Peminjam
            </BackTo>
          </BlockHeadContent>
        </BlockHead>

        <Block size="lg">
          <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)} encType="multipart/form-data">
            <PreviewCard>
              <Row className="g-2">

                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="name" className="form-label" required>Nama Peminjam</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="text" 
                        id="name" 
                        name="name" 
                        defaultValue={data.user_id && data.user_id.name}
                        readOnly
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="borrowing_name" className="form-label" required>Nama Barang</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="text" 
                        id="borrowing_name" 
                        name="borrowing_name" 
                        defaultValue={data.item_id && data.item_id.name}
                        readOnly
                      />
                    </div>
                    {errors.name && <span className="text-danger">{errors.name.message}</span>}
                  </FormGroup>
                </Col>

                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="borrowing_name" className="form-label" required>Nama Peneliti</Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="borrowing_name"
                        name="borrowing_name"
                        defaultValue={data.researcher_name}
                        readOnly
                      />
                    </div>
                    {errors.researcher_name && <span className="text-danger">{errors.researcher_name.message}</span>}
                  </FormGroup>
                </Col>

                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="borrowing_name" className="form-label" required>Institusi</Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="institution"
                        name="institution"
                        defaultValue={data.institution}
                        readOnly
                      />
                    </div>
                    {errors.institution && <span className="text-danger">{errors.institution.message}</span>}
                  </FormGroup>
                </Col>

                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="borrowing_name" className="form-label" required>Fakultas</Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="faculty"
                        name="faculty"
                        defaultValue={data.faculty}
                        readOnly
                      />
                    </div>
                    {errors.faculty && <span className="text-danger">{errors.faculty.message}</span>}
                  </FormGroup>
                </Col>

                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="borrowing_name" className="form-label" required>Email Lain</Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="email_option"
                        name="email_option"
                        defaultValue={data.email_option}
                        readOnly
                      />
                    </div>
                    {errors.email_option && <span className="text-danger">{errors.email_option.message}</span>}
                  </FormGroup>
                </Col>

                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="borrowing_name" className="form-label" required>Topik Riset</Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="research_topic"
                        name="research_topic"
                        defaultValue={data.research_topic}
                        readOnly
                      />
                    </div>
                    {errors.research_topic && <span className="text-danger">{errors.research_topic.message}</span>}
                  </FormGroup>
                </Col>

                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="borrowing_name" className="form-label" required>Library yang digunakan</Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="library_use"
                        name="library_use"
                        defaultValue={data.library_use}
                        readOnly
                      />
                    </div>
                    {errors.library_use && <span className="text-danger">{errors.library_use.message}</span>}
                  </FormGroup>
                </Col>

                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="borrowing_name" className="form-label" required>Tipe data</Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="type_data"
                        name="type_data"
                        defaultValue={data.type_data}
                        readOnly
                      />
                    </div>
                    {errors.type_data && <span className="text-danger">{errors.type_data.message}</span>}
                  </FormGroup>
                </Col>

                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="borrowing_name" className="form-label" required>Ukuran Data</Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="data_size"
                        name="data_size"
                        defaultValue={data.data_size}
                        readOnly
                      />
                    </div>
                    {errors.data_size && <span className="text-danger">{errors.data_size.message}</span>}
                  </FormGroup>
                </Col>

                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="borrowing_name" className="form-label" required>Durasi Riset</Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="duration"
                        name="duration"
                        defaultValue={data.duration}
                        readOnly
                      />
                    </div>
                    {errors.duration && <span className="text-danger">{errors.duration.message}</span>}
                  </FormGroup>
                </Col>

                <Col lg="12">
                  <FormGroup>
                    <Label htmlFor="borrowing_name" className="form-label" required>Deskripsi</Label>
                    <div className="form-control-wrap">
                      <textarea
                        className="form-control"
                        id="description"
                        name="description"
                        defaultValue={data.description}
                        readOnly
                      />
                    </div>
                    {errors.description && <span className="text-danger">{errors.description.message}</span>}
                  </FormGroup>
                </Col>

                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="borrowing_date" className="form-label" required>Tanggal Peminjaman</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="date" 
                        id="borrowing_date" 
                        name="borrowing_date" 
                        defaultValue={data.borrowing_date && moment(data.borrowing_date).format("YYYY-MM-DD")}
                        ref={register({ required: "Tanggal Peminjaman wajib diisi" })}
                      />
                    </div>
                    {errors.borrowing_date && <span className="text-danger">{errors.borrowing_date.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="name" className="form-label" required>Tanggal Pengembalian</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="date" 
                        id="return_date" 
                        name="return_date" 
                        defaultValue={data.return_date && moment(data.return_date).format("YYYY-MM-DD")}
                        ref={register({ required: "Tanggal Pengembalian wajib diisi" })}
                      />
                    </div>
                    {errors.return_date && <span className="text-danger">{errors.return_date.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="total_item" className="form-label" required>Jumlah Barang</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="number" 
                        id="total_item" 
                        name="total_item" 
                        defaultValue={data.total_item}
                        ref={register({ required: "Jumlah Barang wajib diisi" })}
                      />
                    </div>
                    {errors.total_item && <span className="text-danger">{errors.total_item.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="status" className="form-label" required>Status</Label>
                    <div className="form-control-wrap">
                      <select
                        className="form-control"
                        id="status"
                        name="status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        ref={register({ required: "Status wajib diisi" })}
                      >
                        <option value="">Pilih Status</option>
                        <option value="accepted">Setujui</option>
                        <option value="rejected">Tolak</option>
                        <option value="returned">Dikembalikan</option>
                        <option value="pending">Menunggu Persetujuan</option>
                      </select>
                    </div>
                    {errors.status && <span className="text-danger">{errors.status.message}</span>}
                  </FormGroup>
                </Col>
                <FormGroup>
                  <Row>
                    <Col lg="6">
                      <Button size="lg" className="btn-block" type="submit" color="primary" disabled={loading}>
                        {loading ? <Spinner size="sm" color="light" /> : "Simpan"}
                      </Button>
                    </Col>
                    <Col lg="6">
                      <NavLink to="/admin/borrowing">
                        <Button size="lg" className="btn-block" type="reset" color="light">
                          Kembali
                        </Button>
                      </NavLink>
                    </Col>
                  </Row>
                </FormGroup>
              </Row>                  
            </PreviewCard>
          </Form>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default BorrowingEdit;
