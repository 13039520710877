import React from "react"
import Navbar from "../../../components/App/Navbar"
import Footer from "../../../components/App/Footer"
import Layout from "../../../components/App/Layout"
import PageBanner from "../../../components/Common/PageBanner"
import ListWebinar from "../../../components/Webinar/Webinar"
import { useTranslation } from "react-i18next";

const Webinar = () => {
  const { t } = useTranslation();
  return (
    <Layout title={t("webinar")}>
      <Navbar />
      <PageBanner
        pageTitle={t("webinar.pageTitle")}
        homePageText={t("home")}
        homePageUrl="/"
        activePageText={t("webinar.pageTitle")}
      />
      <ListWebinar />
      <Footer />
    </Layout>
  )
}

export default Webinar;