import React from "react"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import Layout from "../../components/App/Layout"
import PageBanner from "../../components/Common/PageBanner"
import ContactInfo from "../../components/Contact/ContactInfo"
import BlogCard from "../../components/Berita/Berita"

const Kunjungan = () => {
  return (
    <Layout title={"Kunjungan"}>
      <Navbar />
      <PageBanner
        pageTitle="Agenda - Kunjungan"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Kunjungan"
      />
      <BlogCard />
      <ContactInfo />
      <Footer />
    </Layout>
  )
}

export default Kunjungan;