import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PaginationComponent from '../App/Pagination'
import logo from '../../assets/images/logo-COE.png'
import api from '../../../jwtAuth'
import moment from 'moment'
import { useTranslation } from "react-i18next";

const Berita = () => {
  const { t } = useTranslation();
  const [news, setNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(6);
  const [totalItems, setTotalItems] = useState(0);
  // Get Data
  async function getData() {
    try {
      const response = await api.get('/public/news/?page=' + currentPage + '&limit=' + itemPerPage);
      setNews(response.data.data.results);
      setTotalItems(response.data.data.count);
    } catch (error) {
      getData();
    }
  }
  useEffect(() => {
    getData();
  }, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  // Change Page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div className="blog-area ptb-100">
      <div className="container">
        <div className="row">
          {news.map((item, index) => (
            <div className="col-lg-4 col-md-6" key={item._id}>
              <div className="single-blog-post bg-fffbf5">
                <div className="post-image">
                  <Link to={`berita/${item.slug}`}>
                    {item.image !== "null" ? (
                      <img src={`${process.env.REACT_APP_API_URL + "/" + item.image}`} alt="banner" style={{height: "237px"}}/>
                    ) : (
                      <img src={logo} alt="banner" style={{height: "237px"}}/>
                    )}
                  </Link>
                </div>

                <div className="post-content">
                  <ul className="post-meta d-flex justify-content-between align-items-center">
                    <li>
                      <div className="post-author d-flex align-items-center">
                        {/* <img src={user1} className="rounded-circle" alt="partner" /> */}
                        <span>{item.createdBy && item.createdBy.name}</span>
                      </div>
                    </li>
                    <li>
                      <i className='flaticon-calendar'></i> {moment(item.createdAt).format("DD MMMM YYYY")}
                    </li>
                  </ul>
                  <h3>
                    <Link to={`berita/${item.slug}`}>
                      {/* {item.title.length > 50 ? item.title.substring(0, 50) + "..." : item.title} */}
                      {localStorage.getItem('lang') === "en" ? item.title_en : item.title}
                    </Link>
                  </h3>
                </div>
              </div>
            </div>
          ))}

          <div className="col-lg-12 col-md-12">
            {/* Pagination */}
            <div className="pagination-area text-center">
              {news.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={totalItems}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">{t("dataNotFound")}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Berita