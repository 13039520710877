import React, { useEffect, useState } from "react"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import Layout from "../../components/App/Layout"
import PageBanner from "../../components/Common/PageBanner"
import PelatihanDetail from "../../components/Pelatihan/PelatihanDetail"
import { RedirectAs404 } from "../../../admin/utils/Utils"
import api from "../../../jwtAuth"
import { Route, useParams } from "react-router"
import { useTranslation } from "react-i18next";

const DetailPelatihan = () => {
  const { t } = useTranslation();
  const [training, setTraining] = useState([]);
  const [title, setTitle] = useState("Pelatihan");
  const { slug } = useParams();
  const [slugTraining, setSlugTraining] = useState(slug);
  const [errorCode, setErrorCode] = useState(0);

  // Get Data
  async function getData() {
    try {
      const response = await api.get('/public/training/' + slugTraining);
      setTraining(response.data.data);
      setTitle(response.data.data.training.name);
    } catch (error) {
      // console.error(error);
      if (error.response.status === 401) {
        getData();
      }
      setErrorCode(error.response.status);
    }
  }
  useEffect(() => {
    getData();
  }, [slugTraining]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    changeSlug(slug);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Change Slug
  const changeSlug = (slug) => {
    setSlugTraining(slug);
  }

  if(errorCode === 404) {
    return <Route component={RedirectAs404}></Route>
  }
  return (
    <Layout title={title}>
      <Navbar />
      <PageBanner
        pageTitle={t("training")}
        homePageText={t("home")}
        homePageUrl="/"
        activePageText={t("training")}
      />
      <PelatihanDetail 
        training={training} 
        refreshData={getData}
      />
      <Footer />
    </Layout>
  )
}

export default DetailPelatihan;