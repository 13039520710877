import React from 'react'
import starIcon from '../../../assets/images/star-icon.png'
import scientist1 from '../../../assets/images/team/research/zainal.png'
import scientist2 from '../../../assets/images/team/research/pulung.png'
import scientist3 from '../../../assets/images/team/research/farrikh.png'
import scientist4 from '../../../assets/images/team/research/Sri_Handayani.png'
import scientist5 from '../../../assets/images/team/research/Mila_Sartika.png'
import scientist6 from '../../../assets/images/team/research/Jumanto.png'
import scientist7 from '../../../assets/images/team/research/Rindra.png'
import scientist8 from '../../../assets/images/team/research/Rama.png'
import scientist9 from '../../../assets/images/team/research/Budi.png'
import scientist10 from '../../../assets/images/team/research/Ramani.png'
import { useTranslation } from "react-i18next";

const TeamMembers = () => {
    const { t } = useTranslation();
    return (
        <section className="scientist-area bg-color pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="about" /> 
                        {t("researchMember")}
                    </span>
                    <h2>{t("researcher")}</h2>
                </div>

                <div className="row">
                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={scientist1} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Prof. Zainal A. Hasibuan, Ph.D</h3>
                                <span>{t("chiefResearcher")}</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={scientist2} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Dr. Pulung Nurtantio A,M.Kom</h3>
                                <span>{t("member")} 1</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={scientist3} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Dr. Farrikh Alzami, M.Kom</h3>
                                <span>{t("member")} 2</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={scientist4} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Sri Handayani, SKM, M.Kes</h3>
                                <span>{t("member")} 3</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={scientist5} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Mila Sartika, SEI, MSI </h3>
                                <span>{t("member")} 4</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={scientist6} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Prof. Dr. Drs. Jumanto, M.Pd</h3>
                                <span>{t("member")} 5</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={scientist7} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Dr.Rindra Yusianto, S.Kom, M.T</h3>
                                <span>{t("member")} 6</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={scientist8} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Rama Aria Megantara, M.Kom</h3>
                                <span>{t("member")} 7</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={scientist9} alt="about" />
                            </div>
                            <div className="content">
                                <h3>L. Budi Handoko, M.Kom</h3>
                                <span>{t("member")} 8</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={scientist10} alt="about" />
                            </div>
                            <div className="content">
                                <h3>BV. RAMANI </h3>
                                <span>{t("partners")}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TeamMembers