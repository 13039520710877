import React from "react"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import Layout from "../../components/App/Layout"
import PageBanner from "../../components/Common/PageBanner"
import ListBerita from "../../components/Berita/Berita"
import { useTranslation } from "react-i18next";

const Berita = () => {
  const { t } = useTranslation();
  return (
    <Layout title={t("news")}>
      <Navbar />
      <PageBanner
        pageTitle={t("news")}
        homePageText={t("home")}
        homePageUrl="/"
        activePageText={t("news")}
      />
      <ListBerita />
      <Footer />
    </Layout>
  )
}

export default Berita;