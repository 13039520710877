import React from "react"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import Layout from "../../components/App/Layout"
import PageBanner from '../../components/Common/PageBanner'
import AboutUs from '../../components/Fasilitas/AboutUs'
import { useTranslation } from "react-i18next";

const Fasilitas = () => {
  const { t } = useTranslation();
  return (
    <Layout title={t("facilities")}>
      <Navbar />
      <PageBanner
        pageTitle={t("facilities.pageTitle")}
        homePageText={t("home")}
        homePageUrl="/"
        activePageText={t("facilities")}
      />
      <AboutUs />
      <Footer />
    </Layout>
  )
}

export default Fasilitas;