import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BackTo,
  PreviewCard,
} from "../../../components/Component";
import { Button, Col, Form, FormGroup, Label, Row, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { useQuill } from "react-quilljs";
import Swal from "sweetalert2";
import api from "../../../../jwtAuth";
import { NavLink } from "react-router-dom";

const TrainingAdd = () => {
  const [loading, setLoading] = useState(false);
  const { errors, register, handleSubmit } = useForm();
  const [content, setContent] = useState('<p></p>');
  const [content_en, setContent_en] = useState('<p></p>');
  const { quill, quillRef } = useQuill();
  const { quill: quill_en, quillRef: quillRef_en } = useQuill();
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (quill) {
      quill.on('text-change', (delta, oldDelta, source) => {
        setContent(quill.root.innerHTML);
      });
    }
  }, [quill]);

  useEffect(() => {
    if (quill_en) {
      quill_en.on('text-change', (delta, oldDelta, source) => {
        setContent_en(quill_en.root.innerHTML);
      });
    }
  }, [quill_en]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const onFormSubmit = (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", e.name);
    formData.append("name_en", e.name_en);
    formData.append("description", content);
    formData.append("description_en", content_en);
    formData.append("quota", e.quota);
    formData.append("event_date", e.event_date);
    formData.append("registration_close_date", e.registration_close_date);
    formData.append("price", e.price);
    formData.append("location", e.location);
    formData.append("banner", selectedFile);
    formData.append("status", e.status);
            
    const response = api.post("/admin/training", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    response.then((res) => {
      if (res.data.success) {
        Swal.fire({
          title: "Berhasil!",
          text: res.data.message,
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/training";
          }
        });
      } else {
        Swal.fire({
          title: "Gagal!",
          text: res.data.message,
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    })
    .catch((err) => {
      Swal.fire({
        title: "Gagal!",
        text: err.response.data.message,
        icon: "error",
        confirmButtonText: "Ok",
      });
    })
    .finally(() => {
      setLoading(false);
    });
  };
  return (
    <React.Fragment>
      <Head title="Tambahkan Pelatihan Baru" />
      <Content page="component">
        <BlockHead size="lg" wide="sm">
          <BlockHeadContent>
            <BackTo link="/admin/training" icon="arrow-left">
              Semua Pelatihan
            </BackTo>
          </BlockHeadContent>
        </BlockHead>

        <Block size="lg">
          <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)} encType="multipart/form-data">
            <PreviewCard>
              <Row className="g-2">
                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="name" className="form-label" required>Nama Pelatihan</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="text" 
                        id="name" 
                        name="name" 
                        placeholder="Masukkan nama pelatihan" 
                        ref={register({ required: "Nama Pelatihan wajib diisi" })}
                      />
                    </div>
                    {errors.name && <span className="text-danger">{errors.name.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="name_en" className="form-label" required>Nama Pelatihan (English)</Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="name_en"
                        name="name_en"
                        placeholder="Masukkan nama pelatihan (English)"
                        ref={register({ required: "Nama Pelatihan (English) wajib diisi" })}
                      />
                    </div>
                    {errors.name_en && <span className="text-danger">{errors.name_en.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="quota" className="form-label" required>Kuota Peserta</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="number" 
                        id="quota" 
                        name="quota" 
                        placeholder="Masukkan jumlah kuota" 
                        ref={register({ required: "Kuota Peserta wajib diisi", min: { value: 1, message: "Kuota Peserta minimal 1" } })}
                      />
                    </div>
                    {errors.quota && <span className="text-danger">{errors.quota.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="price" className="form-label" required>Harga</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="number" 
                        id="price" 
                        name="price" 
                        placeholder="Masukkan harga pelatihan (0 jika gratis)" 
                        ref={register({ required: "Harga wajib diisi" })}
                      />
                    </div>
                    {errors.price && <span className="text-danger">{errors.price.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="event_date" className="form-label" required>Tanggal Acara</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="date" 
                        id="event_date" 
                        name="event_date" 
                        placeholder="Masukkan Tanggal Acara" 
                        ref={register({ required: "Tanggal Acara wajib diisi" })}
                      />
                    </div>
                    {errors.event_date && <span className="text-danger">{errors.event_date.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="registration_close_date" className="form-label" required>Tanggal Terakhir Pendaftaran</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="date" 
                        id="registration_close_date" 
                        name="registration_close_date" 
                        placeholder="Masukkan Tanggal Terakhir Pendaftaran" 
                        ref={register({ required: "Tanggal Terakhir Pendaftaran wajib diisi" })}
                      />
                    </div>
                    {errors.registration_close_date && <span className="text-danger">{errors.registration_close_date.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <Label htmlFor="location" className="form-label" required>Lokasi</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="text" 
                        id="location" 
                        name="location" 
                        placeholder="Masukkan lokasi acara" 
                        ref={register({ required: "Lokasi wajib diisi" })}
                      />
                    </div>
                    {errors.location && <span className="text-danger">{errors.location.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <Label htmlFor="banner" className="form-label" required>Banner Pelatihan (Opsional)</Label>
                    <div className="form-control-wrap">
                      <input className="form-control" type="file" name="banner" onChange={handleFileChange} />
                    </div>
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <Label htmlFor="status" className="form-label" required>Status</Label>
                    <div className="form-control-wrap">
                      <select
                        className="form-select form-control form-control-lg"
                        id="status"
                        name="status"
                        ref={register({ required: "Status wajib diisi" })}
                      >
                        <option value="">Pilih Status</option>
                        <option value="published">Publikasi</option>
                        <option value="draft">Draft</option>
                      </select>
                    </div>
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <Label htmlFor="title" className="form-label">Isi Pelatihan</Label>
                    <div className="form-control-wrap">
                      <div style={{ width: "100%", height: "100%" }}>
                        <div ref={quillRef} />
                      </div>
                    </div>
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <Label htmlFor="title" className="form-label">Isi Pelatihan (English)</Label>
                    <div className="form-control-wrap">
                      <div style={{ width: "100%", height: "100%" }}>
                        <div ref={quillRef_en} />
                      </div>
                    </div>
                  </FormGroup>
                </Col>
                <FormGroup>
                  <Row>
                    <Col lg="6">
                      <Button size="lg" className="btn-block" type="submit" color="primary" disabled={loading}>
                        {loading ? <Spinner size="sm" color="light" /> : "Simpan"}
                      </Button>
                    </Col>
                    <Col lg="6">
                      <NavLink to="/admin/training">
                        <Button size="lg" className="btn-block" type="reset" color="light">
                          Kembali
                        </Button>
                      </NavLink>
                    </Col>
                  </Row>
                </FormGroup>
              </Row>              
            </PreviewCard>
          </Form>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default TrainingAdd;
