// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dotted-section {
  background-image: radial-gradient(circle at 1px 1px, rgba(89, 110, 121, 0.51) 1px, transparent 0);
  background-size: 40px 40px;
}`, "",{"version":3,"sources":["webpack://./src/landing/assets/css/custom.css"],"names":[],"mappings":"AAAA;EACE,iGAAiG;EACjG,0BAA0B;AAC5B","sourcesContent":[".dotted-section {\r\n  background-image: radial-gradient(circle at 1px 1px, rgba(89, 110, 121, 0.51) 1px, transparent 0);\r\n  background-size: 40px 40px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
