import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import PaginationComponent from "../App/Pagination"
// import logo from "../../assets/images/logo-COE.png"
import api from "../../../jwtAuth"
// import moment from "moment"
import { useTranslation } from "react-i18next"

const Publikasi = () => {
  const { t } = useTranslation()
  const [data, setData] = useState([])
  const [datasets, setDatasets] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [itemPerPage] = useState(6)
  const [totalItems, setTotalItems] = useState(0)
  const [categories, setCategories] = useState([])
  const [onSearchText, setSearchText] = useState("")

  // Get Data
  async function getData() {
    try {
      const response = await api.get(
        "/public/publication/?page=" + currentPage + "&limit=" + itemPerPage
      )
      setDatasets(response.data.data.results)
      setTotalItems(response.data.data.count)
    } catch (error) {
      getData()
    }
  }

  // Get Categories
  async function getCategories() {
    try {
      const response = await api.get("/public/categories/")
      setCategories(response.data.data)
    } catch (error) {
      console.error("Error fetching categories: ", error)
    }
  }

  useEffect(() => {
    getData()
    getCategories()
  }, [currentPage]) // eslint-disable-line react-hooks/exhaustive-deps

  // Change Page
  const paginate = pageNumber => {
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = datasets.filter(item => {
        return item.title.toLowerCase().includes(onSearchText.toLowerCase())
      })
      setData([...filteredObject])
    } else {
      setData([...datasets])
    }
  }, [onSearchText, setData, datasets])

  const handleCatChange = async event => {
    const categoryId = event.target.value
    if (categoryId) {
      try {
        const response = await api.get(
          `/public/publication/category/${categoryId}`
        )
        setDatasets(response.data.data.results)
      } catch (error) {
        console.error("Error fetching categories: ", error)
      }
    } else {
      // Jika kategori yang dipilih adalah "Pilih Kategori", tampilkan semua data
      getData()
    }
  }

  const onFilterChange = e => {
    setSearchText(e.target.value)
  }

  // console.log(datasets)

  return (
    <div className="blog-area ">
      <div className="container">
        <div className="row">
          <div className="filters">
            {/* Display Category Buttons */}
            <div className="search-buttons">
              <p>Cari Publikasi</p>
              <div className="input-group input-group-lg">

              <input
                type="text"
                className="form-control"
                placeholder="Cari berdasarkan judul"
                value={onSearchText}
                onChange={e => onFilterChange(e)}
                />
                </div>
            </div>
            <div className="category-buttons">
              <p>Kategori</p>
              <select
                className="form-control form-select-lg col-auto"
                onChange={handleCatChange}
              >
                <option value="">Semua</option>
                {categories.map((category, index) => (
                  <option key={index} value={category._id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {data.map((item, index) => (
            <div className="" key={item._id}>
              <div className="pub">
                <div className="pub-content">
                  <h3>
                    <Link to={`publikasi/${item.slug}`}>
                      {localStorage.getItem("lang") === "en"
                        ? item.title_en
                        : item.title}
                    </Link>
                  </h3>
                  <div className="pub-meta">
                    <div className="pub-author d-flex align-items-center">
                      <span>{item.author}</span>
                    </div>
                  </div>
                  <span>
                    <i className="flaticon-calendar"></i> {item.year}
                  </span>
                </div>
              </div>
            </div>
          ))}

          <div className="col-lg-12 col-md-12">
            {/* Pagination */}
            <div className="pagination-area text-center">
              {datasets.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={totalItems}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">{t("dataNotFound")}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Publikasi
