import React from "react"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import Layout from "../../components/App/Layout"
import PageBanner from '../../components/Common/PageBanner'
import Kajian from '../../components/Index/Kajian'
import Partner from '../../components/AboutUs/Partner'
import { useTranslation } from "react-i18next";
const Profil = () => {
  const { t } = useTranslation();
  return (
    <Layout title={t("profile")}>
      <Navbar />
      <PageBanner
        pageTitle={t("profile.pageTitle")}
        homePageText={t("home")}
        homePageUrl="/"
        activePageText={t("profile")}
      />
      <Kajian />
      <Partner />
      <Footer />
    </Layout>
  )
}

export default Profil