const menu = [
  {
    heading: "Admin Panel",
    role: ["admin.access"],
  },
  {
    icon: "bag",
    text: "Dashboard Admin",
    link: "/admin/",
    role: ["admin.access"],
    panel: true,
    newTab: true,
    subPanel: [
      {
        icon: "dashboard",
        text: "Dashboard",
        link: "/admin/",
        role: ["admin.access"],
        active: true,
      },
      {
        icon: "file-text",
        text: "Hak Akses",
        active: false,
        link: "/admin/roles",
        role: ["admin.roles.view"],
      },
      {
        icon: "layers-fill",
        text: "Kategori",
        active: false,
        link: "/admin/news/categories",
        role: ["admin.categories.view"],
      },
      {
        icon: "file-text",
        text: "Berita",
        active: false,
        link: "/admin/news",
        role: ["admin.news.view"],
      },
      // {
      //   icon: "view-list-wd",
      //   text: "Berita",
      //   active: false,
      //   role: ["admin.news.view", "admin.categories.view"],
      //   subMenu: [
      //     {
      //       text: "Semua Berita",
      //       link: "/admin/news",
      //       role: ["admin.news.view"],
      //     },
      //     {
      //       text: "Kategori Berita",
      //       link: "/admin/news/categories",
      //       role: ["admin.categories.view"],
      //     },
      //   ],
      // },
      {
        icon: "layers-fill",
        text: "Dataset",
        active: false,
        link: "/admin/datasets",
        role: ["admin.datasets.view"],
      },
      {
        icon: "users-fill",
        text: "Pengguna",
        active: false,
        link: "/admin/users",
        role: ["admin.users.view"],
      },
      {
        icon: "video-fill",
        text: "Agenda",
        active: false,
        role: ["admin.webinars.view", "admin.visits.view"],
        subMenu: [
          {
            text: "Webinar",
            link: "/admin/webinars",
            role: ["admin.webinars.view"],
          },
          {
            text: "Kunjungan",
            link: "/admin/visits",
            role: ["admin.visits.view"],
          }
        ],
      },
      {
        icon: "masonry-fill",
        text: "Penelitian",
        active: false,
        subMenu: [
          {
            text: "Periode Pendaftaran",
            link: "/admin/research/periods",
            role: ["admin.researchPeriods.view"],
          },
          {
            text: "Semua Penelitian",
            link: "/admin/research",
            role: ["admin.research.view"],
          },
        ],
        role: ["admin.researchPeriods.view", "admin.research.view"],
      },
      {
        icon: "light-fill",
        text: "Pelatihan",
        active: false,
        link: "/admin/training",
        role: ["admin.training.view"],
      },
      {
        icon: "send",
        text: "Publikasi",
        active: false,
        link: "/admin/publication",
        role: ["admin.publication.view"],
      },
      {
        text: "Peminjaman Barang",
        icon: "bag",
        active: false,
        subMenu: [
          {
            text: "Barang Pinjaman",
            link: "/admin/borrow",
            role: ["admin.borrow_item.view"],
          },
          {
            text: "Daftar Peminjam",
            link: "/admin/borrowing",
            role: ["admin.borrow_item.view"],
          },
        ],
        role: ["admin.borrow_item.view"],
      },
      {
        icon: "building",
        text: "Magang",
        active: false,
        link: "/admin/internship",
        role: ["admin.internship.view"],
      },
    ],
  },
  {
    heading: "User Panel",
    role: ["users.access"],
  },
  {
    icon: "dashboard",
    text: "Dashboard",
    link: "/panel/",
    role: ["users.access"],
    active: true,
  },
  {
    icon: "masonry-fill",
    text: "Penelitian",
    active: false,
    role: ["user.research.view"],
    subMenu: [
      {
        text: "Semua Penelitian",
        link: "/panel/research",
        role: ["user.research.view"],
      },
      {
        text: "Riwayat Penelitian",
        link: "/panel/research/history",
        role: ["user.research.history"],
      }
    ],
  },
  {
    icon: "bag",
    text: "Peminjaman Barang",
    active: false,
    subMenu: [
      {
        text: "Barang Pinjaman",
        link: "/panel/borrow",
        role: ["user.borrow_item.view"],
      },
      {
        text: "Daftar Peminjaman",
        link: "/panel/borrow/history",
        role: ["user.borrow_item.history"],
      },
    ],
    role: ["user.borrow_item.view", "user.borrow_item.history"],
  },
  {
    icon: "light-fill",
    text: "Pelatihan",
    active: false,
    link: "/panel/training/history",
    role: ["users.access"],
  },
  {
    icon: "building",
    text: "Magang",
    active: false,
    link: "/panel/internship",
    role: ["users.access"],
  },
];
export default menu;