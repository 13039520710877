import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BackTo,
  PreviewCard,
} from "../../../components/Component";
import { Button, Col, Form, FormGroup, Label, Row, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { useQuill } from "react-quilljs";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../../../jwtAuth";

const NewsEdit = () => {
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const { errors, register, handleSubmit } = useForm();
  const [content, setContent] = useState('<p></p>');
  const [content_en, setContent_en] = useState('<p></p>');
  const { quill, quillRef } = useQuill();
  const { quill: quill2, quillRef: quillRef2 } = useQuill();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [news, setNews] = useState({
    title: '',
    title_en: '',
    content: '',
    content_en: '',
    category: '',
    image: '',
    status: '',
    createdBy: '',
  });
  const { id } = useParams();

  useEffect(() => {
    async function fetchData(){
      const response = api.get(`/admin/news/${id}`);
      response.then((res) => {
        if (res.data.success) {
          setNews(res.data.data);
          setSelectedOption(res.data.data.category);
        }
      });      
    }
    fetchData();
  }, [id]);
  
  useEffect(() => {
    if (quill) {
      quill.on('text-change', (delta, oldDelta, source) => {
        setContent(quill.root.innerHTML);
      });
      quill.clipboard.dangerouslyPasteHTML(news.content);
    }
  }, [quill, news.content]);

  useEffect(() => {
    if (quill2) {
      quill2.on('text-change', (delta, oldDelta, source) => {
        setContent_en(quill2.root.innerHTML);
      });
      if(news.content_en){
        quill2.clipboard.dangerouslyPasteHTML(news.content_en);
      }
      // quill2.clipboard.dangerouslyPasteHTML(news.content_en);
    }
    console.log(news.content_en);
  }, [quill2, news.content_en]);

  useEffect(() => {
    const category = api.get("/admin/categories");
    category.then((res) => {
      if (res.data.success) {
        setCategory(res.data.data);
      }
    });
  }, []);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const onFormSubmit = (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("title", e.title);
    formData.append("title_en", e.title_en);
    formData.append("content", content);
    formData.append("content_en", content_en);
    formData.append("category", selectedOption);
    formData.append("image", selectedFile);
    formData.append("status", "published");
    formData.append("createdBy", localStorage.getItem('email'));
    
    const response = api.put(`/admin/news/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    response.then((res) => {
      if (res.data.success) {
        Swal.fire({
          title: "Berhasil!",
          text: res.data.message,
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/news";
          }
        });
      } else {
        Swal.fire({
          title: "Gagal!",
          text: res.data.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    });
  };
  return (
    <React.Fragment>
      <Head title="Edit Berita" />
      <Content page="component">
        <BlockHead size="lg" wide="sm">
          <BlockHeadContent>
            <BackTo link="/admin/news" icon="arrow-left">
              Semua Berita
            </BackTo>
          </BlockHeadContent>
        </BlockHead>

        <Block size="lg">
          <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)} encType="multipart/form-data">
            <Row>
              <Col lg="9">
                <PreviewCard>
                  <FormGroup>
                    <Label htmlFor="title" className="form-label" required>Judul Berita</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="text" 
                        id="title" 
                        name="title" 
                        placeholder="Masukkan judul berita" 
                        defaultValue={news.title}
                        ref={register({ required: "Judul Berita wajib diisi" })}
                      />
                    </div>
                    {errors.title && <span className="text-danger">{errors.title.message}</span>}
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="title_en" className="form-label">Judul Berita (English)</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="text" 
                        id="title_en" 
                        name="title_en" 
                        placeholder="Masukkan judul berita (English)" 
                        defaultValue={news.title_en}
                        ref={register({ required: "Judul Berita wajib diisi (English)" })}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="content" className="form-label">Isi Berita</Label>
                    <div className="form-control-wrap">
                      <div style={{ width: "100%", height: "100%" }}>
                        <div ref={quillRef} />
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="content_en" className="form-label">Isi Berita (English)</Label>
                    <div className="form-control-wrap">
                      <div style={{ width: "100%", height: "100%" }}>
                        <div ref={quillRef2} />
                      </div>
                    </div>
                  </FormGroup>
                </PreviewCard>
              </Col>
              <Col lg="3">
                <PreviewCard>
                  <FormGroup>
                    <Label htmlFor="title" className="form-label" required>Kategori Berita</Label>
                    <div className="form-control-wrap">
                      <select value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)} className="form-control" id="category" name="category">
                        <option value="">Pilih Kategori</option>
                        {category.map((item, index) => (
                          <option key={index} value={`${item._id}`}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="title" className="form-label" required>Thumbnail Berita</Label>
                    <div className="form-control-wrap">
                      <input className="form-control" type="file" name="image" onChange={handleFileChange} />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Button size="lg" className="btn-block" type="submit" color="primary" disabled={loading}>
                      {loading ? <Spinner size="sm" color="light" /> : "Perbarui"}
                    </Button>
                  </FormGroup>
                </PreviewCard>
              </Col>
            </Row>
          </Form>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default NewsEdit;
