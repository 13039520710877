import React from 'react'
import { Helmet } from "react-helmet"

const SEO = ({title}) => {
    return (
        <div>
            <Helmet>
                <title>{title} - Center of Excellence Universitas Dian Nuswantoro</title>
                <meta name="description" content="Center of Excellence Universitas Dian Nuswantoro siap membantu dibidang pengajaran, pelatihan, penelitian, pengembangan produk dan hilirisasi pada smart city, material science, e-umkm, c-health dan e-CHNCH" />
                <meta name="og:title" property="og:title" content="Center of Excellence Universitas Dian Nuswantoro"></meta>
                <meta name="twitter:card" content="Center of Excellence Universitas Dian Nuswantoro"></meta>
                <link rel="canonical" href="https://coe.dinus.ac.id/"></link>
                <meta property="og:image" content="https://i.imgur.com/jHtE1EM.png" />
            </Helmet>
        </div>
    )
}

export default SEO
