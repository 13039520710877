import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import AboutUsContent from '../../components/Kerjasama/LuarNegeri/AboutUsContent'
import OurHistory from '../../components/Kerjasama/LuarNegeri/OurHistory'
import WhyChooseUs from '../../components/Kerjasama/LuarNegeri/WhyChooseUs'
import HowItWork from '../../components/Kerjasama/LuarNegeri/HowItWork'
import TeamMembers from '../../components/Kerjasama/LuarNegeri/TeamMembers'
import Testimonials from '../../components/Kerjasama/LuarNegeri/Testimonials'
import Partner from '../../components/Kerjasama/LuarNegeri/Partner'

const LuarNegeri = () => {
  return (
    <Layout title={'Kerjasama - Luar Negeri'}>
      <Navbar />
      <PageBanner
        pageTitle="Kerjasama - Luar Negeri"
        homePageText="Home"
        homePageUrl="/"
        activePageText="About Us"
      />
      <AboutUsContent />
      <OurHistory />
      <WhyChooseUs />
      <HowItWork />
      <TeamMembers />
      <Testimonials />
      <Partner />
      <Footer />
    </Layout>
  );
}

export default LuarNegeri;