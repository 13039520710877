import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BackTo,
  PreviewCard,
} from "../../../components/Component";
import { Button, Form, FormGroup, Label, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../../../jwtAuth";

const InternshipEdit = () => {
  const [loading, setLoading] = useState(false);
  const { errors, register, handleSubmit } = useForm();
  const [selectedStatus, setSelectedStatus] = useState('');
  const [internship, setInternship] = useState({
    name: '',
    email: '',
    nim: '',
    mbkmProgram: '',
    studyProgram: '',
    interest: '',
    phone: '',
    portfolio: '',
    transcript: '',
    status: '',
    reason: '',
  });
  const [mbkmProgram] = useState([
    { value: "Bangkit", label: "Bangkit" },
    { value: "MSIB", label: "MSIB" },
    { value: "Studi Independen", label: "Studi Independen" },
    { value: "Tidak Mengikuti", label: "Tidak Mengikuti" },
  ]);
  const [selectedMbkm, setSelectedMbkm] = useState('');
  const [studyProgram] = useState([
    { value: "Teknik Informatika", label: "Teknik Informatika" },
    { value: "Sistem Informasi", label: "Sistem Informasi" },
    { value: "Ilmu Komunikasi", label: "Ilmu Komunikasi" },
    { value: "Desain Komunikasi Visual", label: "Desain Komunikasi Visual" },
    { value: "Animasi", label: "Animasi" },
    { value: "Film dan Televisi", label: "Film dan Televisi" },
  ]);
  const [selectedStudyProgram, setSelectedStudyProgram] = useState('');
  const [interest] = useState([
    { value: "Data Science/Machine Learning", label: "Data Science/Machine Learning" },
    { value: "Mobile Apps", label: "Mobile Apps" },
    { value: "UI / UX", label: "UI / UX" },
    { value: "Web Development", label: "Web Development" },
    { value: "Database", label: "Database" },
    { value: "Cloud Computing", label: "Cloud Computing" },
    { value: "IoT", label: "IoT" },
  ]);
  const [selectedInterest, setSelectedInterest] = useState('');
  const [linkTranscript, setLinkTranscript] = useState('');
  const { id } = useParams();

  useEffect(() => {
    const response = api.get(`/admin/internship/${id}`);
    response.then((res) => {
      if (res.data.success) {
        setInternship(res.data.data);
        setSelectedMbkm(res.data.data.mbkmProgram);
        setSelectedStudyProgram(res.data.data.studyProgram);
        setSelectedInterest(res.data.data.interest);
        setSelectedStatus(res.data.data.status);
        setLinkTranscript(`${process.env.REACT_APP_API_URL}/${res.data.data.transcript}`);
      } else {
        Swal.fire({
          title: "Gagal",
          text: res.data.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFormSubmit = (e) => {
    setLoading(true);
    const response = api.put(`/admin/internship/${id}`, {
      status: selectedStatus,
      reason: e.reason,
    });
    response.then((res) => {
      if (res.data.success) {
        Swal.fire({
          title: "Berhasil",
          text: res.data.message,
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/internship";
          }
        });
      } else {
        Swal.fire({
          title: "Gagal",
          text: res.data.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    })
    .catch((err) => {
      Swal.fire({
        title: "Gagal",
        text: err,
        icon: "error",
        confirmButtonText: "OK",
      });
    })
    .finally(() => {
      setLoading(false);
    });
  };
  return (
    <React.Fragment>
      <Head title="Edit Peserta Magang" />
      <Content page="component">
        <BlockHead size="lg" wide="sm">
          <BlockHeadContent>
            <BackTo link="/admin/internship" icon="arrow-left">
              Semua Peserta Magang
            </BackTo>
          </BlockHeadContent>
        </BlockHead>

        <Block size="lg">
          <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)} encType="multipart/form-data">
            <PreviewCard>
              <h1 className="h3 mb-3">Form Mahasiswa Magang CoE</h1>
              <FormGroup>
                <Label htmlFor="email" className="form-label" required>Email</Label>
                <div className="form-control-wrap">
                  <input
                    className="form-control"
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Masukkan email"
                    defaultValue={internship.email}
                    readOnly
                    ref={register({ required: "Email wajib diisi" })}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="name" className="form-label" required>Nama Lengkap</Label>
                <div className="form-control-wrap">
                  <input
                    className="form-control"
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Masukkan nama lengkap"
                    defaultValue={internship.name}
                    readOnly
                    ref={register({ required: "Nama Lengkap wajib diisi" })}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="nim" className="form-label" required>NIM</Label>
                <div className="form-control-wrap">
                  <input
                    className="form-control"
                    type="text"
                    id="nim"
                    name="nim"
                    placeholder="Masukkan NIM"
                    defaultValue={internship.nim}
                    readOnly
                    ref={register({ required: "NIM wajib diisi" })}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="mbkmProgram" className="form-label" required>Program MBKM yang pernah diikuti</Label>
                <ul className="custom-control-group custom-control-vertical w-100">
                  {mbkmProgram.map((item, index) => (
                    <li key={item.value}>
                      <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="mbkmProgram"
                          id={`${item.value}`}
                          checked={selectedMbkm === item.value}
                          readOnly
                        />
                        <label className="custom-control-label" htmlFor={`${item.value}`}>
                          <span>{item.label}</span>
                        </label>
                      </div>
                    </li>
                  ))}
                </ul>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="studyProgram" className="form-label" required>Program Studi</Label>
                <ul className="custom-control-group custom-control-vertical w-100">
                  {studyProgram.map((item, index) => (
                    <li key={item.value}>
                      <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="studyProgram"
                          id={`${item.value}`}
                          checked={selectedStudyProgram === item.value}
                          readOnly
                        />
                        <label className="custom-control-label" htmlFor={`${item.value}`}>
                          <span>{item.label}</span>
                        </label>
                      </div>
                    </li>
                  ))}
                </ul>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="interest" className="form-label" required>Peminatan</Label>
                <ul className="custom-control-group custom-control-vertical w-100">
                  {interest.map((item, index) => (
                    <li key={item.value}>
                      <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="interest"
                          id={`${item.value}`}
                          checked={selectedInterest === item.value}
                          readOnly
                        />
                        <label className="custom-control-label" htmlFor={`${item.value}`}>
                          <span>{item.label}</span>
                        </label>
                      </div>
                    </li>
                  ))}
                </ul>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="phone" className="form-label" required>Nomor WhatsApp</Label>
                <div className="form-control-wrap">
                  <input
                    className="form-control"
                    type="text"
                    id="phone"
                    name="phone"
                    placeholder="Masukkan Nomor WhatsApp"
                    defaultValue={internship.phone}
                    readOnly
                    ref={register({ required: "Nomor WhatsApp wajib diisi" })}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="portfolio" className="form-label" required>Link Portfolio Github, LinkedIn, Website (pilih salah satu)</Label>
                <div className="form-control-wrap">
                  <input
                    className="form-control"
                    type="text"
                    id="portfolio"
                    name="portfolio"
                    placeholder="Masukkan Link Portfolio"
                    defaultValue={internship.portfolio}
                    readOnly
                    ref={register({ required: "Link Portfolio wajib diisi" })}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="transcript" className="form-label" required>Transkrip Nilai (PDF)</Label>
                <div className="input-group input-group-md">
                  <input
                    type="text"
                    className="form-control"
                    id="transcript"
                    name="transcript"
                    defaultValue={linkTranscript}
                    readOnly
                  />
                  <span className="input-group-append">
                    <a href={linkTranscript} target="_blank" rel="noreferrer" className="btn btn-info btn-flat">Lihat!</a>
                  </span>
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="status">Status</Label>
                <select
                  name="status"
                  id="status"
                  className="form-control"
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                  ref={register({ required: "Status wajib diisi" })}
                >
                  <option value="">Pilih Status</option>
                  <option value="accepted">Diterima</option>
                  <option value="rejected">Ditolak</option>
                </select>
                {errors.status && <span className="text-danger">{errors.status.message}</span>}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="reason" className="form-label" required>Alasan Penolakan</Label>
                <div className="form-control-wrap">
                  <input
                    className="form-control"
                    type="text"
                    id="reason"
                    name="reason"
                    placeholder="Masukkan Alasan Penolakan"
                    defaultValue={internship.reason}
                    ref={register({})}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Button size="lg" className="btn-block" type="submit" color="primary" disabled={loading}>
                  {loading ? <Spinner size="sm" color="light" /> : "Simpan"}
                </Button>
              </FormGroup>
            </PreviewCard>
          </Form>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default InternshipEdit;
