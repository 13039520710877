import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import api from '../../../jwtAuth';
import logo from '../../assets/images/logo-COE.png'
import moment from 'moment';
import { useTranslation } from "react-i18next";

const BlogSidebar = ({changeSlug}) => {
  const { t } = useTranslation();
  const [category, setCategory] = useState([]);
  const [news, setNews] = useState([]);

  // Get Data
  async function getNews() {
    try {
      const response = await api.get('/public/news/?limit=3');
      setNews(response.data.data.results);
    } catch (error) {
      if(error.response.status === 404) {
        getNews();
      }
    }
  }
  async function getCategory() {
    try {
      const response = await api.get('/public/categories');
      setCategory(response.data.data);
    } catch (error) {
      if(error.response.status === 404) {
        getCategory();
      }
    }
  }
  useEffect(() => {
    getNews();
    getCategory();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="widget-area">
      <div className="widget widget_tracer_posts_thumb">
        <h3 className="widget-title">{t("news.latest")}</h3>
        {news.map((item, index) => (
          <article className="item" key={item._id}>
            <NavLink 
              to={`${item.slug}`} 
              className="thumb"
              onClick={() => changeSlug(item.slug)}
            >
              {item.image !== "null" ? (
                <img className="fullimage cover" src={`${process.env.REACT_APP_API_URL + "/" + item.image}`} alt="bb" />
              ) : (
                <img className="fullimage cover" src={`${logo}`} alt="bb" />
              )}
            </NavLink>
            <div className="info">
              <span>{moment(item.updatedAt).format('LL')}</span>
              <h4 className="title usmall">
                <NavLink 
                  to={`${item.slug}`}
                  onClick={() => changeSlug(item.slug)}
                >
                  {localStorage.getItem("lang") === "en" ? item.title_en : item.title}
                </NavLink>
              </h4>
            </div>

            <div className="clear"></div>
          </article>
        ))}
      </div>

      <div className="widget widget_tag_cloud">
        <h3 className="widget-title">{t("category")}</h3>

        <div className="tagcloud">
          {category.map((item, index) => (
            <Link to="#" key={item._id}>
              {item.name}
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default BlogSidebar