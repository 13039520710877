import React from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { Card, CardHeader, CardBody, CardText } from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Row,
  Col,
  BlockBetween,
} from "../../components/Component";

const Homepage = () => {
  return (
    <React.Fragment>
      <Head title="Dashboard User"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                Dashboard
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row className="g-gs">
            <Col sm="12">
              <Card className="card-bordered" color="light">
                <CardHeader>Dashboard User</CardHeader>
                <CardBody className="card-inner">
                  <CardText>
                    Selamat datang di dashboard user Center of Excellence Universitas Dian Nuswantoro
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default Homepage;