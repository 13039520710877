import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Block,
  BlockBetween,
  BlockDes,
  BackTo,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  RSelect,
  PreviewCard
} from "../../../components/Component";
import { Button, Col, Form, FormGroup, Label, Row, Spinner,DropdownMenu, DropdownToggle, UncontrolledDropdown, DropdownItem, Modal, ModalBody, } from "reactstrap";
import { useForm } from "react-hook-form";
import { useQuill } from "react-quilljs";
import Swal from "sweetalert2";
import { NavLink, useParams } from "react-router-dom";
import api from "../../../../jwtAuth";
import moment from "moment";

const ResearchEdit = () => {
  const [loading, setLoading] = useState(false);
  const { errors, register, handleSubmit } = useForm();
  const [content, setContent] = useState('<p></p>');
  const [research, setResearch] = useState({
    name: "",
    quota: "",
    start_date: "",
    end_date: "",
    description: "",
  });
  const { quill, quillRef } = useQuill();
  const { id } = useParams();

  const [data, setData] = useState([]);
  const [registrationData, setRegistrationData] = useState([]);
  const [bulkActionNews, setBulkActionNews] = useState([]);
  const [modal, setModal] = useState(false);
  const [registrationID, setRegistrationID] = useState("");

  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [actionText, setActionText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");

  // Get Data
  const fetchDataRegistration = async () => {
    try {
      let response
      if(actionText === ""){
        response = await api.get(`/admin/research/${id}/registrations`);
      } else {
        response = await api.get(`/admin/research/${id}/registrations?researchPeriod=${actionText}`);
      }
      if(response.data.success){
        setRegistrationData(response.data.data);
        setData(response.data.data);
      }
    } catch (error) {
      if(error.response.status === 401) {
        Swal.fire({
          title: "Oops!",
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/";
          }
        });
      }
    }
  };

  const fetchDataRegistrationPeriod = async () => {
    try {
      const response = await api.get(`/admin/researchPeriods`);
      if(response.data.success){
        setBulkActionNews(response.data.data.map((item) => {
          return {
            value: item._id,
            label: item.name
          }
        }));
      } else {
        setBulkActionNews([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchDataRegistration();
    fetchDataRegistrationPeriod();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.name.localeCompare(b.name));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.name.localeCompare(a.name));
      setData([...sortedData]);
    }
  };

  // unselects the data on mount
  useEffect(() => {
    let newData;
    newData = registrationData.map((item) => {
      item.checked = false;
      return item;
    });
    setData([...newData]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = registrationData.filter((item) => {
        return (
          item.user_id.name.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.user_id.education.identity_number.toLowerCase().includes(onSearchText.toLowerCase())
        );
      });
      setData([...filteredObject]);
    } else {
      setData([...registrationData]);
    }
  }, [onSearchText, setData]); // eslint-disable-line react-hooks/exhaustive-deps

  // function to set the action to be taken in table header
  const onActionText = (e) => {
    setActionText(e.value);
  };

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  const onFormCancel = () => {
    setRegistrationID("");
    setModal(false);
  };

  // function to delete for an item
  const onChange = (id) => {
    setRegistrationID(id);
    setModal(true);
  };

  // function which fires on applying selected action
  const onActionClick = (e) => {
    setLoading(true);
    if (actionText !== "") {
      fetchDataRegistration();
    } else {
      Swal.fire({
        title: "Gagal!",
        text: "Pilih aksi yang akan dilakukan terhadap data",
        icon: "error",
        showCancelButton: false,
        confirmButtonText: "OK",
      });
    }
    setLoading(false);
  };

  // function to update status
  const onUpdateStatus = (e) => {
    setLoading(true);
    const response = api.put(`/admin/research/${id}/registrations/`, {
      registration_id: registrationID,
      status: e.status,
      reason: e.reason,
    });
    response.then((res) => {
      if (res.data.success) {
        Swal.fire({
          title: "Berhasil!",
          text: res.data.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            fetchDataRegistration();
            setModal(false);
          }
        });
      } else {
        Swal.fire({
          title: "Gagal!",
          text: res.data.message,
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "OK",
        });
      }
    })
    .catch((err) => {
      Swal.fire({
        title: "Gagal!",
        text: err.response.data.message,
        icon: "error",
        showCancelButton: false,
        confirmButtonText: "OK",
      });
    });
    setLoading(false);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    const response = api.get(`/admin/research/${id}`);
    response.then((res) => {
      setResearch(res.data.data);
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        name: "Gagal",
        text: err.response.data.message,
      });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (quill) {
      quill.on('text-change', (delta, oldDelta, source) => {
        setContent(quill.root.innerHTML);
      });
      quill.clipboard.dangerouslyPasteHTML(research.description);
    }
  }, [quill, research.description]);

  const onFormSubmit = (e) => {
    setLoading(true);
    const response = api.put(`/admin/research/${id}`, {
      name: e.name,
      quota: e.quota,
      start_date: e.start_date,
      end_date: e.end_date,
      description: content,
      status: "published",
    });
    response.then((res) => {
      if(res.data.success){
        Swal.fire({
          icon: "success",
          name: "Berhasil",
          text: res.data.message,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/research";
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          name: "Gagal",
          text: res.data.message,
        });
      }
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        name: "Gagal",
        text: err.response.data.message,
      });
    });
    setLoading(false);
  };
  return (
    <React.Fragment>
      <Head title="Edit Penelitian" />
      <Content page="component">
        <BlockHead size="lg" wide="sm">
          <BlockHeadContent>
            <BackTo link="/admin/research" icon="arrow-left">
              Semua Penelitian
            </BackTo>
          </BlockHeadContent>
        </BlockHead>

        <Block size="lg">
          <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)} encType="multipart/form-data">            
            <PreviewCard>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="name" className="form-label" required>Nama Penelitian</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="text" 
                        id="name" 
                        name="name" 
                        placeholder="Masukkan judul penelitian" 
                        defaultValue={research.name}
                        ref={register({ required: "Nama Penelitian wajib diisi" })}
                      />
                    </div>
                    {errors.name && <span className="text-danger">{errors.name.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="quota" className="form-label" required>Kuota Peserta</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="number" 
                        id="quota" 
                        name="quota" 
                        placeholder="Masukkan judul penelitian" 
                        defaultValue={research.quota}
                        ref={register({ required: "Kuota peserta wajib diisi" })}
                      />
                    </div>
                    {errors.quota && <span className="text-danger">{errors.quota.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="start_date" className="form-label" required>Tanggal Mulai</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="date" 
                        id="start_date" 
                        name="start_date" 
                        placeholder="Masukkan Tanggal Mulai" 
                        defaultValue={research.start_date && research.start_date.split("T")[0]}
                        ref={register({ required: "Tanggal Mulai wajib diisi" })}
                      />
                    </div>
                    {errors.start_date && <span className="text-danger">{errors.start_date.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="end_date" className="form-label" required>Tanggal Akhir</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="date" 
                        id="end_date" 
                        name="end_date" 
                        placeholder="Masukkan Tanggal Akhir" 
                        defaultValue={research.end_date && research.end_date.split("T")[0]}
                        ref={register({ required: "Tanggal Akhir wajib diisi" })}
                      />
                    </div>
                    {errors.end_date && <span className="text-danger">{errors.end_date.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <Label htmlFor="name" className="form-label">Deskripsi</Label>
                    <div className="form-control-wrap">
                      <div style={{ width: "100%", height: "100%" }}>
                        <div ref={quillRef} />
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col lg="6">
                        <Button size="lg" className="btn-block" type="submit" color="primary" disabled={loading}>
                          {loading ? <Spinner size="sm" color="light" /> : "Publikasi"}
                        </Button>
                      </Col>
                      <Col lg="6">
                        <NavLink to="/admin/research">
                          <Button size="lg" className="btn-block" type="reset" color="light">
                            Kembali
                          </Button>
                        </NavLink>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
            </PreviewCard>              
          </Form>
        </Block>

        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Pendaftar
              </BlockTitle>
              <BlockDes className="text-soft">
                {/* <p>You have total 2,595 users.</p> */}
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="card-tools">
                  <div className="form-inline flex-nowrap gx-3">
                    <div className="form-wrap">
                      <RSelect
                        options={bulkActionNews}
                        className="w-300px"
                        placeholder="Pilih Periode Pendaftaran"
                        onChange={(e) => onActionText(e)}
                      />
                    </div>
                    <div className="btn-wrap">
                      <span className="d-none d-md-block">
                        <Button
                          disabled={actionText === "" || loading ? true : false}
                          color="light"
                          outline
                          className="btn-dim"
                          onClick={(e) => onActionClick(e)}
                        >
                          {loading ? <Spinner size="sm" color="light" /> : "Terapkan"}
                        </Button>
                      </span>
                      <span className="d-md-none">
                        <Button
                          color="light"
                          outline
                          disabled={actionText === "" || loading ? true : false}
                          className="btn-dim btn-icon"
                          onClick={(e) => onActionClick(e)}
                        >
                          {loading ? <Spinner size="sm" color="light" /> : <Icon name="arrow-right"></Icon>}
                        </Button>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card-tools mr-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                                <Icon name="arrow-left"></Icon>
                              </Button>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle color="tranparent" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <Icon name="setting"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right className="dropdown-menu-xs">
                                  <ul className="link-check">
                                    <li>
                                      <span>Tampilkan</span>
                                    </li>
                                    <li className={itemPerPage === 10 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(10);
                                        }}
                                      >
                                        10
                                      </DropdownItem>
                                    </li>
                                    <li className={itemPerPage === 15 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(15);
                                        }}
                                      >
                                        15
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                  <ul className="link-check">
                                    <li>
                                      <span>Urutkan</span>
                                    </li>
                                    <li className={sort === "dsc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("dsc");
                                          sortFunc("dsc");
                                        }}
                                      >
                                        DESC
                                      </DropdownItem>
                                    </li>
                                    <li className={sort === "asc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("asc");
                                          sortFunc("asc");
                                        }}
                                      >
                                        ASC
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Cari berdasarkan nama / NIM"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <DataTableBody>
              <DataTableHead>
                <DataTableRow>
                  <span className="sub-text">Nama</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">NIM</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Email</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">IPK</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Status</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Tanggal Daftar</span>
                </DataTableRow>
                <DataTableRow size="sm">
                  <span className="sub-text">Aksi</span>
                </DataTableRow>
              </DataTableHead>
              {/*Head*/}
              {currentItems.length > 0
                ? currentItems.map((item) => {
                    return (
                      <DataTableItem key={item._id}>
                        <DataTableRow>
                          <span>
                            {item.user_id && item.user_id.name}
                          </span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span>{item.user_id && item.user_id.education && item.user_id.education.identity_number}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span>{item.user_id && item.user_id.email}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span>{item.user_id && item.user_id.education && item.user_id.education.ipk}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span
                            className={`tb-status text-${
                              item.status === "approved" ? "success" : item.status === "pending" ? "warning" : "danger"
                            }`}
                          >
                            {item.status === "approved" ? "Diterima" : item.status === "pending" ? "Dalam Seleksi" : "Ditolak"}
                          </span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span>{moment(item.createdAt).format("DD-MM-YYYY H:m")}</span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools">
                          <ul className="nk-tb-actions gx-1">
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <ul className="link-list-opt no-bdr">
                                    <React.Fragment>
                                      <li className="divider"></li>
                                      <li onClick={() => onChange(item._id)}>
                                        <DropdownItem
                                          tag="a"
                                          href="#change-status"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                          }}
                                        >
                                          <Icon name="edit"></Icon>
                                          <span>Ubah Status Pendaftaran</span>
                                        </DropdownItem>
                                      </li>
                                    </React.Fragment>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={data.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">Tidak ada data ditemukan</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>

        <Modal isOpen={modal} toggle={() => setModal(false)} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#close"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Ubah Status Pendaftaran</h5>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={handleSubmit(onUpdateStatus)}>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Status</label>
                      <select
                        className="form-control"
                        name="status"
                        ref={register({
                          required: "Status tidak boleh kosong",
                        })}
                      >
                        <option value="">Pilih Status</option>
                        <option value="pending">Dalam Seleksi</option>
                        <option value="approved">Diterima</option>
                        <option value="rejected">Ditolak</option>
                      </select>
                      {errors.status && <span className="invalid">{errors.status.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Alasan </label>
                      <input
                        className="form-control"
                        type="text"
                        name="reason"
                        placeholder="Masukkan Alasan untuk yang ditolak (Opsional)"
                        ref={register({ })}
                      />
                      {errors.reason && <span className="invalid">{errors.reason.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit" disabled={loading}>
                          {loading ? <Spinner size="sm" color="light" /> : "Ubah Status"}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Batal
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};

export default ResearchEdit;
