import React from 'react'
import { useTranslation } from "react-i18next";

const ContactInfo = () => {
    const { t } = useTranslation();
    return (
        <div className="contact-info-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-map'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-map'></i>
                            </div>
                            <h3>{t("address")}</h3>
                            <p>Jl. Yudistira No.05, Pendrikan Kidul, Kec. Semarang Tengah, Kota Semarang, Jawa Tengah 50131</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-phone-call'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-phone-call'></i>
                            </div>
                            <h3>{t("contact")}</h3>
                            <p>{t("phone")}: <a href="tel:+6285641447936">+62 856-4144-7936</a></p>
                            <p>{t("email")}: <a href="mailto:centerofexcellence@coe.dinus.ac.id">centerofexcellence@coe.dinus.ac.id</a></p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-time-five'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-time-five'></i>
                            </div>
                            <h3>{t("workingHours")}</h3>
                            <p>Monday - Friday: 08:00 - 16:00</p>
                            {/* <p>Sunday & Saturday: 10:30 - 22:00</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo