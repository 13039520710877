import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import starIcon from '../../assets/images/star-icon.png'
import caseStudy1 from '../../assets/images/case-study/case-study1.jpg'
import Loadable from '@loadable/component'
import api from '../../../jwtAuth'
import { ShowHtml } from '../../../admin/utils/Utils'
import { useTranslation } from "react-i18next";
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    items: 1,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ]
};

const Pelatihan = () => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);

    // Get Data
    async function getData() {
        try {
            const response = await api.get('/public/training');
            setData(response.data.data.results);
        } catch (error) {
            // console.error(error);
            if(error.response.status === 401) {
                getData();
            }
        }
    }

    useEffect(() => {
        getData();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="case-study-area bg-fffbf5">
            {data.length > 0 ? <OwlCarousel 
                className="case-study-slides owl-carousel owl-theme"
                {...options}
            > 
                {data.map((item, idx) => (
                <div className="single-case-study-item ptb-100" key={item._id}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12">
                                <div className="case-study-content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="case-study" /> 
                                        {t("training")}
                                    </span>
                                    <h2>{item.name}</h2>
                                    <ShowHtml html={item.description} maxLength={200} />

                                    <Link to={`pelatihan/${item.slug}`} className="default-btn">
                                        <i className="flaticon-view"></i>
                                        {t("readmore")}
                                        <span></span>
                                    </Link>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                                <div className="case-study-image">
                                    <Link to={`pelatihan/${item.slug}`} className="d-block">
                                        {item.banner ? (
                                            <img src={`${process.env.REACT_APP_API_URL + "/" + item.banner}`} alt="case-study" style={{height: "526px", width: "526px"}} />
                                        ) : (
                                            <img src={caseStudy1} alt="case-study" />
                                        )}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                ))}
            </OwlCarousel> : ''}
        </div>
    )
}

export default Pelatihan