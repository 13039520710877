import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BackTo,
  PreviewCard,
} from "../../../components/Component";
import { Button, Col, Form, FormGroup, Label, Row, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { NavLink, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../../../jwtAuth";

const QuillPreview = () => {
  const [loading, setLoading] = useState(false);
  const { errors, register, handleSubmit } = useForm();
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [users, setUsers] = useState({
    name: '',
    email: '',
    role: '',
    status: '',
  });
  const { id } = useParams();
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const response = api.get("/admin/roles");
    response.then((res) => {
      setRoles(res.data.data);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const response = api.get(`/admin/users/${id}`);
    response.then((res) => {
      if(res.data.success) {
        setUsers(res.data.data);
        setSelectedRole(res.data.data.role);
        setSelectedStatus(res.data.data.status);
      } else {
        Swal.fire({
          title: "Gagal",
          text: res.data.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFormSubmit = (e) => {
    setLoading(true);
    const response = api.put(`/admin/users/${id}`, {
      name: e.name,
      email: e.email,
      password: e.password,
      role: selectedRole,
      status: selectedStatus,
    });
    response.then((res) => {
      if (res.data.success) {
        Swal.fire({
          title: "Berhasil",
          text: res.data.message,
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/users";
          }
        });
      } else {
        Swal.fire({
          title: "Gagal",
          text: res.data.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    })
    .catch((err) => {
      Swal.fire({
        title: "Gagal",
        text: err,
        icon: "error",
        confirmButtonText: "OK",
      });
    })
    .finally(() => {
      setLoading(false);
    });
  };
  return (
    <React.Fragment>
      <Head title="Edit Pengguna" />
      <Content page="component">
        <BlockHead size="lg" wide="sm">
          <BlockHeadContent>
            <BackTo link="/admin/users" icon="arrow-left">
              Semua Pengguna
            </BackTo>
          </BlockHeadContent>
        </BlockHead>

        <Block size="lg">
          <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)} encType="multipart/form-data">
            <PreviewCard>
              <Row className="g-2">
                <Col lg="6">
                  <FormGroup>
                    <Label for="name">Nama Lengkap</Label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="form-control"
                      placeholder="Nama Lengkap"
                      defaultValue={users.name}
                      ref={register({ required: "Nama wajib diisi" })}
                    />
                    {errors.name && <span className="text-danger">{errors.name.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control"
                      placeholder="Email"
                      defaultValue={users.email}
                      ref={register({ required: "Email wajib diisi", pattern: { value: /dinus\.ac\.id$/i, message: "Email harus menggunakan email dari kampus" } })}
                    />
                    {errors.email && <span className="text-danger">{errors.email.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <Label for="password">Password</Label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      className="form-control"
                      placeholder="Isi jika ingin mengganti password"
                      ref={register({ minLength: { value: 6, message: "Password minimal 6 karakter" } })}
                    />
                    {errors.password && <span className="text-danger">{errors.password.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <Label for="role">Role</Label>
                    <select
                      name="role"
                      id="role"
                      className="form-control"
                      value={selectedRole}
                      onChange={(e) => setSelectedRole(e.target.value)}
                      ref={register({ required: "Role wajib diisi" })}
                    >
                      <option value="">Pilih Role</option>
                      {roles.map((role) => (
                        <option key={role._id} value={role._id}>
                          {role.name}
                        </option>
                      ))}
                    </select>
                    {errors.role && <span className="text-danger">{errors.role.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <Label for="status">Status</Label>
                    <select
                      name="status"
                      id="status"
                      className="form-control"
                      value={selectedStatus}
                      onChange={(e) => setSelectedStatus(e.target.value)}
                      ref={register({ required: "Status wajib diisi" })}
                    >
                      <option value="">Pilih Status</option>
                      <option value="active">Aktif</option>
                      <option value="blocked">Blokir</option>
                    </select>
                    {errors.status && <span className="text-danger">{errors.status.message}</span>}
                  </FormGroup>
                </Col>
                <FormGroup>
                  <Row>
                    <Col lg="6">
                      <Button size="lg" className="btn-block" type="submit" color="primary" disabled={loading}>
                        {loading ? <Spinner size="sm" color="light" /> : "Simpan"}
                      </Button>
                    </Col>
                    <Col lg="6">
                      <NavLink to="/admin/users">
                        <Button size="lg" className="btn-block" type="reset" color="light">
                          Kembali
                        </Button>
                      </NavLink>
                    </Col>
                  </Row>
                </FormGroup>
              </Row>
            </PreviewCard> 
          </Form>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default QuillPreview;
