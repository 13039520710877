import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BackTo,
  PreviewCard,
} from "../../../components/Component";
import { Button, Col, Form, FormGroup, Label, Row, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import api from "../../../../jwtAuth";

const InternshipRegister = () => {
  const [loading, setLoading] = useState(false);
  const { errors, register, handleSubmit } = useForm();
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorFile, setErrorFile] = useState("");
  const [mbkmProgram] = useState([
    { value: "Bangkit", label: "Bangkit" },
    { value: "MSIB", label: "MSIB" },
    { value: "Studi Independen", label: "Studi Independen" },
    { value: "Tidak Mengikuti", label: "Tidak Mengikuti" },
  ]);
  const [selectedMbkm, setSelectedMbkm] = useState('');
  const [errorMbkm, setErrorMbkm] = useState('');
  const [studyProgram] = useState([
    { value: "Teknik Informatika", label: "Teknik Informatika" },
    { value: "Sistem Informasi", label: "Sistem Informasi" },
    { value: "Ilmu Komunikasi", label: "Ilmu Komunikasi" },
    { value: "Desain Komunikasi Visual", label: "Desain Komunikasi Visual" },
    { value: "Animasi", label: "Animasi" },
    { value: "Film dan Televisi", label: "Film dan Televisi" },
  ]);
  const [selectedStudyProgram, setSelectedStudyProgram] = useState('');
  const [errorStudyProgram, setErrorStudyProgram] = useState('');
  const [interest] = useState([
    { value: "Data Science/Machine Learning", label: "Data Science/Machine Learning" },
    { value: "Mobile Apps", label: "Mobile Apps" },
    { value: "UI / UX", label: "UI / UX" },
    { value: "Web Development", label: "Web Development" },
    { value: "Database", label: "Database" },
    { value: "Cloud Computing", label: "Cloud Computing" },
    { value: "IoT", label: "IoT" },
  ]);
  const [selectedInterest, setSelectedInterest] = useState('');
  const [errorInterest, setErrorInterest] = useState('');
  const [profile, setProfile] = useState({});

  useEffect(() => {
    const response = api.get("/user/profile", {
      headers: {
        email: `${localStorage.getItem("email")}`,
      },
    });
    response.then((res) => {
      setProfile(res.data.user);
    })
    .catch((err) => {
      if(err.response.status === 404 || err.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
    });
  }, [setProfile]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setErrorFile('');
  };

  const handleMBKMChange = (selectedOption) => {
    setSelectedMbkm(selectedOption.target.id);
    setErrorMbkm('');
  };

  const handleStudyProgramChange = (selectedOption) => {
    setSelectedStudyProgram(selectedOption.target.id);
    setErrorStudyProgram('');
  };

  const handleInterestChange = (selectedOption) => {
    setSelectedInterest(selectedOption.target.id);
    setErrorInterest('');
  };

  const onFormSubmit = (e) => {
    setLoading(true);
    if (selectedMbkm === '') {
      setErrorMbkm('Program MBKM wajib diisi');
      setLoading(false);
      return;
    } 
    if (selectedStudyProgram === '') {
      setErrorStudyProgram('Program Studi wajib diisi');
      setLoading(false);
      return;
    }
    if (selectedInterest === '') {
      setErrorInterest('Peminatan wajib diisi');
      setLoading(false);
      return;
    }
    if (selectedFile === null) {
      setErrorFile('Transkrip nilai wajib diisi');
      setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("email", e.email);
    formData.append("name", e.name);
    formData.append("nim", e.nim);
    formData.append("mbkmProgram", selectedMbkm);
    formData.append("studyProgram", selectedStudyProgram);
    formData.append("interest", selectedInterest);
    formData.append("phone", e.phone);
    formData.append("portfolio", e.portfolio);
    formData.append("transcript", selectedFile);

    const response = api.post("/user/internship", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    response.then((res) => {
      if (res.data.success) {
        Swal.fire({
          title: "Berhasil!",
          text: res.data.message,
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/panel/internship";
          }
        });
      } else {
        Swal.fire({
          title: "Gagal!",
          text: res.data.message,
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
      setLoading(false);
    })
    .catch((err) => {
      console.log(err);
      Swal.fire({
        title: "Gagal!",
        text: err.response.data.message,
        icon: "error",
        confirmButtonText: "Ok",
      });
      setLoading(false);
    });
  };
  return (
    <React.Fragment>
      <Head title="Daftar Magang CoE" />
      <Content page="component">
        <BlockHead size="lg" wide="sm">
          <BlockHeadContent>
            <BackTo link="/panel/internship" icon="arrow-left">
              Kembali
            </BackTo>
          </BlockHeadContent>
        </BlockHead>

        <Block size="lg">
          <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)} encType="multipart/form-data">
            <Row>
              <Col lg="12">
                <PreviewCard>
                  <h1 className="h3 mb-3">Form Mahasiswa Magang CoE</h1>
                  <FormGroup>
                    <Label htmlFor="email" className="form-label" required>Email</Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="email"
                        name="email"
                        placeholder="Masukkan email"
                        defaultValue={profile.email}
                        readOnly
                        ref={register({ required: "Email wajib diisi" })}
                      />
                    </div>
                    {errors.email && <span className="text-danger">{errors.email.message}</span>}
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="name" className="form-label" required>Nama Lengkap</Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Masukkan nama lengkap"
                        defaultValue={profile.name}
                        readOnly
                        ref={register({ required: "Nama Lengkap wajib diisi" })}
                      />
                    </div>
                    {errors.name && <span className="text-danger">{errors.name.message}</span>}
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="nim" className="form-label" required>NIM</Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="nim"
                        name="nim"
                        placeholder="Masukkan NIM"
                        defaultValue={profile && profile.education && profile.education.identity_number}
                        readOnly
                        ref={register({ required: "NIM wajib diisi" })}
                      />
                    </div>
                    {errors.nim && <span className="text-danger">{errors.nim.message}</span>}
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="mbkmProgram" className="form-label" required>Program MBKM yang pernah diikuti</Label>
                    <ul className="custom-control-group custom-control-vertical w-100">
                      {mbkmProgram.map((item, index) => (
                        <li key={item.value}>
                          <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                            <input type="radio" className="custom-control-input" name="mbkmProgram" id={`${item.value}`} onChange={handleMBKMChange} />
                            <label className="custom-control-label" htmlFor={`${item.value}`}>
                              <span>{item.label}</span>
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                    {errorMbkm && <span className="text-danger">{errorMbkm}</span>}
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="studyProgram" className="form-label" required>Program Studi</Label>
                    <ul className="custom-control-group custom-control-vertical w-100">
                      {studyProgram.map((item, index) => (
                        <li key={item.value}>
                          <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                            <input type="radio" className="custom-control-input" name="studyProgram" id={`${item.value}`} onChange={handleStudyProgramChange} />
                            <label className="custom-control-label" htmlFor={`${item.value}`}>
                              <span>{item.label}</span>
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                    {errorStudyProgram && <span className="text-danger">{errorStudyProgram}</span>}
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="interest" className="form-label" required>Peminatan</Label>
                    <ul className="custom-control-group custom-control-vertical w-100">
                      {interest.map((item, index) => (
                        <li key={item.value}>
                          <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                            <input type="radio" className="custom-control-input" name="interest" id={`${item.value}`} onChange={handleInterestChange} />
                            <label className="custom-control-label" htmlFor={`${item.value}`}>
                              <span>{item.label}</span>
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                    {errorInterest && <span className="text-danger">{errorInterest}</span>}
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="phone" className="form-label" required>Nomor WhatsApp</Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="phone"
                        name="phone"
                        placeholder="Masukkan Nomor WhatsApp"
                        defaultValue={profile && profile.profile && profile.profile.phone}
                        readOnly
                        ref={register({ required: "Nomor WhatsApp wajib diisi" })}
                      />
                    </div>
                    {errors.phone && <span className="text-danger">{errors.phone.message}</span>}
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="portfolio" className="form-label" required>Link Portfolio Github, LinkedIn, Website (pilih salah satu)</Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="portfolio"
                        name="portfolio"
                        placeholder="Masukkan Link Portfolio"
                        ref={register({ required: "Link Portfolio wajib diisi" })}
                      />
                    </div>
                    {errors.portfolio && <span className="text-danger">{errors.portfolio.message}</span>}
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="transcript" className="form-label" required>Transkrip Nilai (PDF)</Label>
                    <div className="form-control-wrap">
                      <input className="form-control" type="file" name="transcript" onChange={handleFileChange} />
                    </div>
                    {errorFile && <span className="text-danger">{errorFile}</span>}
                  </FormGroup>
                  <FormGroup>
                    <Button size="lg" className="btn-block" type="submit" color="primary" disabled={loading}>
                      {loading ? <Spinner size="sm" color="light" /> : "Daftar Sekarang"}
                    </Button>
                  </FormGroup>
                </PreviewCard>
              </Col>
            </Row>
          </Form>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default InternshipRegister;
