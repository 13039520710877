import React from 'react'
import {Link} from 'react-router-dom'
import { useTranslation } from "react-i18next";

const Tentang = () => {
    const { t } = useTranslation();
    return (
        <section className="featured-services-area pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <h2>{t("aboutus")}</h2>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p> */}
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-featured-services-box" style={{marginTop:"0px"}}>
                            <div className="icon">
                                <i className="flaticon-analysis"></i>
                            </div>
                            <h3>
                                <Link to="/tentang-kami/profil">
                                    {t("home.about.head1")}
                                </Link>
                            </h3>
                            <p style={{fontSize:"20px"}} dangerouslySetInnerHTML={{ __html: t('home.about.desc1') }}></p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-featured-services-box" style={{marginTop:"0px"}}>
                            <div className="icon">
                                <i className="flaticon-structure"></i>
                            </div>
                            <h3>
                                <Link to="/tentang-kami/profil">
                                    {t("home.about.head2")}
                                </Link>
                            </h3>
                            <p style={{fontSize:"20px"}} dangerouslySetInnerHTML={{ __html: t('home.about.desc2') }}></p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-featured-services-box">
                            <div className="icon">
                                <i className="flaticon-idea"></i>
                            </div>
                            <h3>
                                <Link to="/tentang-kami/profil">
                                {t("home.about.head3")}
                                </Link>
                            </h3>
                            <p style={{fontSize:"20px"}} dangerouslySetInnerHTML={{ __html: t('home.about.desc3') }}></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Tentang