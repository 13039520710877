import React from "react";
import { Row, Col } from "../../components/Component";

const AuthFooter = () => {
  const year = new Date().getFullYear();
  return (
    <div className="nk-footer nk-auth-footer-full">
      <div className="container wide-lg">
        <Row className="">
          <Col lg="12">
            <div className="nk-block-content text-center text-lg">
              <p className="text-soft">&copy; {year} {process.env.REACT_APP_COPYRIGHT}. All Rights Reserved.</p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default AuthFooter;