import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import DatasetSidebar from './DatasetSidebar'
import logo from '../../assets/images/logo-COE.png'
import moment from 'moment'
import { useTranslation } from "react-i18next";

const DatasetDetail = ({ datasets, prevPage, nextPage, changeSlug }) => {
  const { t } = useTranslation();
  return (
    <section className="blog-details-area ptb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="blog-details-desc">
              <div className="article-image">
                {datasets.banner && datasets.banner !== "null" ? (
                  <img src={`${process.env.REACT_APP_API_URL + "/" + datasets.banner}`} alt="partner" />
                ) : (
                  <img src={logo} alt="partner" />
                )}
              </div>

              <div className="article-content">
                <div className="entry-meta">
                  <ul>
                    <li>
                      <i className='bx bx-calendar'></i>
                      <span>{t("lastUpdate")}</span>
                      <Link to="#">
                        {moment(datasets.updatedAt).format('LL')}
                      </Link>
                    </li>
                  </ul>
                </div>

                {localStorage.getItem('lang') === 'en' ? (
                  <div>
                    <h1>{datasets.title_en}</h1>
                    <div dangerouslySetInnerHTML={{ __html: datasets.description_en }} />
                  </div>
                ) : (
                  <div>
                    <h1>{datasets.title}</h1>
                    <div dangerouslySetInnerHTML={{ __html: datasets.description }} />
                  </div>
                )}
              </div>

              <div className="article-footer">
                <div className="article-tags">
                  {/* <span><i className='bx bx-purchase-tag'></i></span>

                  <Link to="/blog">Fashion</Link>
                  <Link to="/blog">Games</Link>
                  <Link to="/blog">Travel</Link> */}
                </div>

                <div className="article-share">
                  <ul className="social">
                    <li><span>{t("share")}</span></li>
                    <li>
                      <a href={"https://www.facebook.com/sharer.php?u=" + window.location.href} className="facebook" target="_blank" rel="noreferrer">
                        <i className='bx bxl-facebook'></i>
                      </a>
                    </li>
                    <li>
                      <a href={"https://twitter.com/intent/tweet?text=" + datasets.title + "&url=" + window.location.href} className="twitter" target="_blank" rel="noreferrer">
                        <i className='bx bxl-twitter'></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              {/* <div className="article-author">
                <div className="author-profile-header"></div>
                <div className="author-profile">
                  <div className="author-profile-title">
                    <img src={user1} className="shadow-sm" alt="uu" />
                    <h4>Chris Orwig</h4>
                    <span className="d-block">Photographer, Author, Writer</span>
                    <p>Chris Orwig is a celebrated photographer, author, and writer who brings passion to everything he does. Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod tempor.</p>
                  </div>
                </div>
              </div> */}

              <div className="tracer-post-navigation">
                <div className="prev-link-wrapper">
                  {prevPage && (
                    <div className="info-prev-link-wrapper">
                      <NavLink
                        to={`${prevPage.slug}`}
                        onClick={() => changeSlug(prevPage.slug)}
                      >
                        <span className="image-prev">
                          {prevPage.banner && prevPage.banner !== "null" ? (
                            <img src={`${process.env.REACT_APP_API_URL + "/" + prevPage.banner}`} alt="partner" />
                          ) : (
                            <img src={logo} alt="partner" />
                          )}
                          <span className="post-nav-title"><i className='bx bx-chevron-left'></i></span>
                        </span>

                        <span className="prev-link-info-wrapper">
                          <span className="prev-title">{localStorage.getItem('lang') === 'en' ? prevPage.title_en : prevPage.title}</span>
                          <span className="meta-wrapper">
                            <span className="date-post">{moment(prevPage.updatedAt).format('LL')}</span>
                          </span>
                        </span>
                      </NavLink>
                    </div>
                  )}
                </div>

                <div className="next-link-wrapper">
                  {nextPage && (
                    <div className="info-next-link-wrapper">
                      <NavLink
                        to={`${nextPage.slug}`}
                        onClick={() => changeSlug(nextPage.slug)}
                      >
                        <span className="next-link-info-wrapper">
                          <span className="next-title">{localStorage.getItem('lang') === 'en' ? nextPage.title_en : nextPage.title}</span>
                          <span className="meta-wrapper">
                            <span className="date-post">{moment(nextPage.updatedAt).format('LL')}</span>
                          </span>
                        </span>

                        <span className="image-next">
                          {nextPage.banner && nextPage.banner !== "null" ? (
                            <img src={`${process.env.REACT_APP_API_URL + "/" + nextPage.banner}`} alt="partner" />
                          ) : (
                            <img src={logo} alt="partner" />
                          )}
                          <span className="post-nav-title"><i className='bx bx-chevron-right'></i></span>
                        </span>
                      </NavLink>
                    </div>
                  )}
                </div>
              </div>

            </div>
          </div>

          <div className="col-lg-4 col-md-12">
            <DatasetSidebar changeSlug={changeSlug} datasets={datasets} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default DatasetDetail