import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import TreeView from "react-jstree-table";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BackTo,
  PreviewCard,
} from "../../../components/Component";
import { Button, Col, Form, FormGroup, Label, Row, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { NavLink, useParams } from "react-router-dom";
import api from "../../../../jwtAuth";

const RolesEdit = () => {
  const [loading, setLoading] = useState(false);
  const { errors, register, handleSubmit } = useForm();
  const [rolesData, setRolesData] = useState({});
  const [roles, setRoles] = useState([]);
  const [roleData, setRoleData] = useState({
    name: "",
    description: "",
    roles: [],
  });
  const { id } = useParams();

  useEffect(() => {
    const response = api.get(`/admin/roles/${id}`);
    response.then((res) => {
      if(res.data.success) {
        setRoleData(res.data.data);
        setRolesData({data: res.data.rolesTree});
      } else {
        Swal.fire({
          title: "Gagal",
          text: res.data.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onRolesChange = (e, data) => {
    setRoles(data.selected);
  };

  const onFormSubmit = (e) => {
    setLoading(true);
    const response = api.put(`/admin/roles/${id}`, {
      name: e.name,
      description: e.description,
      roles: roles,
    });
    response.then((res) => {
      if (res.data.success) {
        Swal.fire({
          title: "Berhasil",
          text: res.data.message,
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/roles";
          }
        });
      } else {
        Swal.fire({
          title: "Gagal",
          text: res.data.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    })
    .catch((err) => {
      Swal.fire({
        title: "Gagal",
        text: err.response.data.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    })
    .finally(() => {
      setLoading(false);
    });
  };
  return (
    <React.Fragment>
      <Head title="Ubah Hak Akses" />
      <Content page="component">
        <BlockHead size="lg" wide="sm">
          <BlockHeadContent>
            <BackTo link="/admin/roles" icon="arrow-left">
              Semua Hak Akses
            </BackTo>
          </BlockHeadContent>
        </BlockHead>

        <Block size="lg">
          <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)} encType="multipart/form-data">
            <Row>
              <Col lg="12">
                <PreviewCard>
                <Row>
                    <Col lg="6">
                      <FormGroup>
                        <Label htmlFor="name" className="form-label" required>Nama Hak Akses</Label>
                        <div className="form-control-wrap">
                          <input 
                            className="form-control" 
                            type="text" 
                            id="name" 
                            name="name" 
                            placeholder="Masukkan nama hak akses" 
                            defaultValue={roleData.name}
                            ref={register({ required: "Nama Hak Akses wajib diisi" })}
                          />
                        </div>
                        {errors.name && <span className="text-danger">{errors.name.message}</span>}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <Label htmlFor="name" className="form-label" required>Deskripsi</Label>
                        <div className="form-control-wrap">
                          <input 
                            className="form-control" 
                            type="text" 
                            id="description" 
                            name="description" 
                            placeholder="Masukkan deskripsi hak akses (opsional)" 
                            defaultValue={roleData.description}
                            ref={register({})}
                          />
                        </div>
                        {errors.description && <span className="text-danger">{errors.description.message}</span>}
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label htmlFor="roles" className="form-label" required>Hak Akses</Label>
                    {rolesData.data && rolesData.data != null && (
                    <PreviewCard>
                      <TreeView 
                        treeData={rolesData.data} 
                        treeSearchData={rolesData.data}
                        onChange={onRolesChange}
                      ></TreeView>
                    </PreviewCard>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col lg="6">
                        <Button size="lg" className="btn-block" type="submit" color="primary" disabled={loading}>
                          {loading ? <Spinner size="sm" color="light" /> : "Simpan"}
                        </Button>
                      </Col>
                      <Col lg="6">
                        <NavLink to="/admin/roles">
                          <Button size="lg" className="btn-block" type="reset" color="light">
                            Kembali
                          </Button>
                        </NavLink>
                      </Col>
                    </Row>
                  </FormGroup>
                </PreviewCard>
              </Col>
            </Row>
          </Form>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default RolesEdit;
