import React from "react";
import { Card } from "reactstrap";
import { Icon } from "../../../components/Component";

const WebStatistics = ({data}) => {
  return (
    <Card className="h-100">
      <div className="card-inner">
        <div className="card-title-group mb-2">
          <div className="card-title">
            <h6 className="title">Statistik Web</h6>
          </div>
        </div>
        <ul className="nk-store-statistics">
          <li className="item">
            <div className="info">
              <div className="title">Barang Pinjaman</div>
              <div className="count">{data.borrowItem}</div>
            </div>
            <Icon name="bag" className="bg-primary-dim"></Icon>
          </li>
          <li className="item">
            <div className="info">
              <div className="title">Pengguna</div>
              <div className="count">{data.user}</div>
            </div>
            <Icon name="users" className="bg-info-dim"></Icon>
          </li>
          <li className="item">
            <div className="info">
              <div className="title">Dataset</div>
              <div className="count">{data.dataset}</div>
            </div>
            <Icon name="box" className="bg-pink-dim"></Icon>
          </li>
          <li className="item">
            <div className="info">
              <div className="title">Hak akses</div>
              <div className="count">{data.roles}</div>
            </div>
            <Icon name="server" className="bg-purple-dim"></Icon>
          </li>
        </ul>
      </div>
    </Card>
  );
};
export default WebStatistics;
