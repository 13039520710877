import React, { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import { Link } from "react-router-dom"
import { useRecoilState } from "recoil"
import { collapsedState } from "../../utils/recoil-atoms"
import { useTranslation } from "react-i18next"
import api from "../../../jwtAuth"
import Swal from "sweetalert2"

const DatasetSidebar = ({ changeSlug, publications }) => {
  const { t } = useTranslation()
  const [profile, setProfile] = useState()
  // eslint-disable-next-line no-unused-vars
  const [collapsed, setCollapsed] = useRecoilState(collapsedState)
  useEffect(() => {
    const response = api.get("/user/profile", {
      headers: {
        email: `${localStorage.getItem("email")}`,
      },
    })
    response
      .then(res => {
        setProfile(res.data.user)
      })
      .catch(err => {})
  }, [])
  const handleDownloadFile = file => {
    const response = api.post(
      `/public/publication/${publications._id}/download`,
      { filename: file.filename },
      { responseType: "blob" },
      {
        headers: {
          "Content-Disposition": "attachment",
        },
      }
    )
    response.then(res => {
      if (res.status === 200) {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: res.headers["content-type"] })
        )
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", file.originalname)
        document.body.appendChild(link)
        link.click()
        link.remove()
      } else if (res.status === 401) {
        Swal.fire(
          "Gagal!",
          "Anda tidak memiliki akses untuk mengakses halaman ini.",
          "error"
        )
      } else {
        Swal.fire("Gagal!", "Terjadi kesalahan, silahkan coba lagi.", "error")
      }
    })
  }
  return (
    <div className="services-details-info">
      <div className="download-file">
        <h3>File</h3>

        <ul>
          {profile &&
            publications &&
            publications.files &&
            publications.files.map((item, index) => (
              <li key={index}>
                {item ? <Link to="#" onClick={() => handleDownloadFile(item)}>
                  {item.originalname.length > 25
                    ? item.originalname.substring(0, 25) + "..."
                    : item.originalname}{" "}
                  <i className="bx bxs-file-pdf"></i>
                </Link> : <span className="text-danger">File belum tersedia</span>}
              </li>
            ))}
          {!profile && (
            <div className="option-item">
              <NavLink
                exact
                to={`${process.env.PUBLIC_URL}/login`}
                activeClassName="active"
                onClick={() => setCollapsed(true)}
                className="default-btn"
              >
                <i className="flaticon-right"></i> {t("menu.login")}{" "}
                <span></span>
              </NavLink>
            </div>
          )}
        </ul>
      </div>
    </div>
  )
}

export default DatasetSidebar
