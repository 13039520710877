import React from 'react'
import starIcon from '../../../assets/images/star-icon.png'
import scientist1 from '../../../assets/images/team/research/zainal.png'
import scientist2 from '../../../assets/images/team/research/Ansarullah-Lawi-2.png'
import scientist3 from '../../../assets/images/team/research/muhammad-abrar-masril-2.png'
import { useTranslation } from "react-i18next";

const TeamMembers = () => {
    const { t } = useTranslation();
    return (
        <section className="scientist-area bg-color pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="about" /> 
                        {t("researchMember")}
                    </span>
                    <h2>{t("researcher")}</h2>
                </div>

                <div className="row">
                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={scientist1} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Prof. Zainal A. Hasibuan, Ph.D</h3>
                                <span>{t("chiefResearcher")}</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={scientist2} alt="about" />
                            </div>
                            <div className="content">
                                <h3>Dr. Eng. Ansarullah Lawi, M.Eng</h3>
                                <span>{t("member")} 1</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={scientist3} alt="about" />
                            </div>
                            <div className="content">
                                <h3>M. Abrar Masril, M.Kom</h3>
                                <span>{t("member")} 2</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TeamMembers