import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BackTo,
  PreviewCard,
} from "../../../components/Component";
import { Button, Col, Form, FormGroup, Label, Row, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { useQuill } from "react-quilljs";
import Swal from "sweetalert2";
import { NavLink } from "react-router-dom";
import api from "../../../../jwtAuth";

const ResearchAdd = () => {
  const [loading, setLoading] = useState(false);
  const { errors, register, handleSubmit } = useForm();
  const [content, setContent] = useState('<p></p>');
  const { quill, quillRef } = useQuill();

  useEffect(() => {
    if (quill) {
      quill.on('text-change', (delta, oldDelta, source) => {
        setContent(quill.root.innerHTML);
      });
    }
  }, [quill]);

  const onFormSubmit = (e) => {
    setLoading(true);
    const response = api.post("/admin/research", {
      name: e.name,
      quota: e.quota,
      start_date: e.start_date,
      end_date: e.end_date,
      description: content,
      status: "published",
    });
    response.then((res) => {
      if(res.data.success){
        Swal.fire({
          icon: "success",
          name: "Berhasil",
          text: res.data.message,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/research";
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          name: "Gagal",
          text: res.data.message,
        });
      }
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        name: "Gagal",
        text: err.response.data.message,
      });
    });
    setLoading(false);
  };
  return (
    <React.Fragment>
      <Head title="Tambahkan Penelitian Baru" />
      <Content page="component">
        <BlockHead size="lg" wide="sm">
          <BlockHeadContent>
            <BackTo link="/admin/research" icon="arrow-left">
              Semua Penelitian
            </BackTo>
          </BlockHeadContent>
        </BlockHead>

        <Block size="lg">
          <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)} encType="multipart/form-data">            
            <PreviewCard>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="name" className="form-label" required>Nama Penelitian</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="text" 
                        id="name" 
                        name="name" 
                        placeholder="Masukkan judul penelitian" 
                        ref={register({ required: "Nama Penelitian wajib diisi" })}
                      />
                    </div>
                    {errors.name && <span className="text-danger">{errors.name.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="quota" className="form-label" required>Kuota Peserta</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="number" 
                        id="quota" 
                        name="quota" 
                        placeholder="Masukkan judul penelitian" 
                        ref={register({ required: "Kuota peserta wajib diisi" })}
                      />
                    </div>
                    {errors.quota && <span className="text-danger">{errors.quota.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="name" className="form-label" required>Tanggal Mulai</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="date" 
                        id="start_date" 
                        name="start_date" 
                        placeholder="Masukkan Tanggal Mulai" 
                        ref={register({ required: "Tanggal Mulai wajib diisi" })}
                      />
                    </div>
                    {errors.start_date && <span className="text-danger">{errors.start_date.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label htmlFor="name" className="form-label" required>Tanggal Akhir</Label>
                    <div className="form-control-wrap">
                      <input 
                        className="form-control" 
                        type="date" 
                        id="end_date" 
                        name="end_date" 
                        placeholder="Masukkan Tanggal Akhir" 
                        ref={register({ required: "Tanggal Akhir wajib diisi" })}
                      />
                    </div>
                    {errors.end_date && <span className="text-danger">{errors.end_date.message}</span>}
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <Label htmlFor="name" className="form-label">Deskripsi</Label>
                    <div className="form-control-wrap">
                      <div style={{ width: "100%", height: "100%" }}>
                        <div ref={quillRef} />
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col lg="6">
                        <Button size="lg" className="btn-block" type="submit" color="primary" disabled={loading}>
                          {loading ? <Spinner size="sm" color="light" /> : "Publikasi"}
                        </Button>
                      </Col>
                      <Col lg="6">
                        <NavLink to="/admin/research">
                          <Button size="lg" className="btn-block" type="reset" color="light">
                            Kembali
                          </Button>
                        </NavLink>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
            </PreviewCard>              
          </Form>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default ResearchAdd;
