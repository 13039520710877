import React, { useState } from "react";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import Logo from "../../assets/images/logo-COE.png";
import LogoDark from "../../assets/images/logo-COE.png";
import { Spinner, FormGroup, Alert } from "reactstrap";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import api from "../../../jwtAuth"

const Register = () => {
  const [passState, setPassState] = useState(false);
  const [loading, setLoading] = useState(false);
  const { errors, register, handleSubmit } = useForm();
  const [response, setResponse] = useState({
    status: null,
    message: "",
  });

  const handleFormSubmit = (formData) => {
    setResponse({
      status: null,
      message: "",
    });
    setLoading(true);
    const name = formData.name;
    const email = formData.email;
    const password = formData.password;
    const response = api.post("/auth/register", {
      apikey: process.env.REACT_APP_API_KEY,
      name: name,
      email: email,
      password: password,
    });
    response.then((res) => {
      if (res.data.success) {
        setResponse({
          status: "success",
          message: res.data.message,
        });
      } else {
        setResponse({
          status: "danger",
          message: res.data.message,
        });
      }
    })
    .catch((err) => {
      setResponse({
        status: "danger",
        message: err.response.data.message,
      });
    })
    .finally(() => {
      setLoading(false);
    });
  };
  return (
    <React.Fragment>
      <Head title="Register" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
            </Link>
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Registrasi</BlockTitle>
                <BlockDes>
                  <p>Silakan lengkapi form registrasi di bawah ini.</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            {response.status !== null && (
              <div className="mb-3">
                <Alert color={`${response.status}`} className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {response.message}
                </Alert>
              </div>
            )}
            <form className="is-alter" onSubmit={handleSubmit(handleFormSubmit)}>
              <FormGroup>
                <label className="form-label" htmlFor="name">
                  Nama Lengkap
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Masukkan nama lengkap Anda"
                    ref={register({ required: "Nama wajib diisi" })}
                    className="form-control-lg form-control"
                  />
                  {errors.name && <p className="invalid">{errors.name.message}</p>}
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Email
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    bssize="lg"
                    id="default-01"
                    name="email"
                    ref={register({ required: "Email wajib diisi", pattern: { value: /dinus\.ac\.id$/i, message: "Email harus menggunakan email dari kampus" } })}
                    className="form-control-lg form-control"
                    placeholder="Masukkan email Anda"
                  />
                  {errors.email && <span className="invalid">{errors.email.message}</span>}
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                    Password
                  </label>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassState(!passState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                    <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                  </a>
                  <input
                    type={passState ? "text" : "password"}
                    id="password"
                    name="password"
                    ref={register({ required: "Password wajib diisi", minLength: { value: 6, message: "Password minimal 6 karakter" } })}
                    placeholder="Masukkan password Anda"
                    className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                  />
                  {errors.password && <span className="invalid">{errors.password.message}</span>}
                </div>
              </FormGroup>
              <FormGroup>
                <Button type="submit" color="primary" size="lg" className="btn-block" disabled={loading}>
                  {loading ? <Spinner size="sm" color="light" /> : "Register"}
                </Button>
              </FormGroup>
            </form>
            <div className="form-note-s2 text-center pt-4">
              {" "}
              Sudah punya akun?{" "}
              <Link to={`${process.env.PUBLIC_URL}/login`}>
                <strong>Login</strong>
              </Link>
            </div>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default Register;
