import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import { Modal, ModalBody, FormGroup, Spinner } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  Button,
  RSelect,
} from "../../../components/Component";
import moment from "moment";
import api from "../../../../jwtAuth";
import Swal from "sweetalert2";

const UserProfileRegularPage = ({ sm, updateSm, profile, setProfile }) => {
  const [modalTab, setModalTab] = useState("1");
  const [formData, setFormData] = useState({});
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const facultyOptions = [
    { value: "Fakultas Ilmu Komputer", label: "Fakultas Ilmu Komputer" },
    { value: "Fakultas Teknik", label: "Fakultas Teknik" },
    { value: "Fakultas Ekonomi dan Bisnis", label: "Fakultas Ekonomi dan Bisnis" },
    { value: "Fakultas Ilmu Budaya", label: "Fakultas Ilmu Budaya" },
    { value: "Fakultas Kesehatan", label: "Fakultas Kesehatan" },
    { value: "Fakultas Kedokteran", label: "Fakultas Kedokteran" },
  ];

  useEffect(() => {
    if(profile && profile.profile && profile.address && profile.education){
      setFormData({
        name: profile.name,
        email: profile.email,
        phone: profile.profile.phone,
        dob: profile.profile.dob,
        gender: profile.profile.gender,
        address: profile.address.address,
        district: profile.address.district,
        city: profile.address.city,
        province: profile.address.province,
        postalCode: profile.address.postalCode,
        identity_number: profile.education.identity_number,
        ipk: profile.education.ipk,
        study: profile.education.study,
        faculty: profile.education.faculty,
      });
      setSelectedOption({ value: profile.education.faculty, label: profile.education.faculty });
    }
  }, [profile]);

  useEffect(() => {
    if (selectedOption) {
      setFormData({ ...formData, faculty: selectedOption.value });
    }
  }, [selectedOption]); // eslint-disable-line react-hooks/exhaustive-deps

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitForm = () => {
    setLoading(true);
    const response = api.put("/user/profile", formData);
    response.then((res) => {
      if(res.data.success){
        Swal.fire({
          title: "Berhasil",
          text: res.data.message,
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            setProfile(res.data.user);
            setModal(false);
          }
        });
      } else {
        Swal.fire({
          title: "Gagal",
          text: res.data.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    })
    .catch((err) => {
      Swal.fire({
        title: "Gagal",
        text: err.response.data.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    })
    .finally(() => {
      setLoading(false);
    });
  };

  return (
    <React.Fragment>
      <Head title="Profil"></Head>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Informasi Pribadi</BlockTitle>
            <BlockDes>
              <p>Info dasar, seperti nama dan alamat Anda, yang Anda gunakan di website Center of Excellence.</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <div className="nk-data data-list">
          <div className="data-head">
            <h6 className="overline-title">Dasar</h6>
          </div>
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">Nama Lengkap</span>
              <span className="data-value">{profile && profile.name}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Email</span>
              <span className="data-value">{profile && profile.email}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more disable">
                <Icon name="lock-alt"></Icon>
              </span>
            </div>
          </div>
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">Jenis Kelamin</span>
              <span className="data-value">{profile && profile.profile && profile.profile.gender}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">Nomor HP / WhatsApp</span>
              <span className="data-value text-soft">{profile && profile.profile && profile.profile.phone}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">Tanggal Lahir</span>
              <span className="data-value">{profile && profile.profile && moment(profile.profile.dob).format("DD-MM-YYYY")}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
        </div>
        <div className="nk-data data-list">
          <div className="data-head">
            <h6 className="overline-title">Alamat</h6>
          </div>
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">Alamat</span>
              <span className="data-value">{profile && profile.address && profile.address.address}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">Kecamatan</span>
              <span className="data-value">{profile && profile.address && profile.address.district}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">Kab/Kota</span>
              <span className="data-value">{profile && profile.address && profile.address.city}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">Provinsi</span>
              <span className="data-value">{profile && profile.address && profile.address.province}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">Kode Pos</span>
              <span className="data-value">{profile && profile.address && profile.address.postalCode}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
        </div>
        <div className="nk-data data-list">
          <div className="data-head">
            <h6 className="overline-title">Pendidikan</h6>
          </div>
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">NIM / NPP</span>
              <span className="data-value">{profile && profile.education && profile.education.identity_number}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">IPK</span>
              <span className="data-value">{profile && profile.education && profile.education.ipk}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">Prodi</span>
              <span className="data-value">{profile && profile.education && profile.education.study}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">Fakultas</span>
              <span className="data-value">{profile && profile.education && profile.education.faculty}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
        </div>
      </Block>

      <Modal isOpen={modal} className="modal-dialog-centered" size="lg" toggle={() => setModal(false)}>
        <ModalBody>
          <a
            href="#dropdownitem"
            onClick={(ev) => {
              ev.preventDefault();
              setModal(false);
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Perbarui Profil</h5>
            <ul className="nk-nav nav nav-tabs">
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTab === "1" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTab("1");
                  }}
                  href="#personal"
                >
                  Data Pribadi
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTab === "2" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTab("2");
                  }}
                  href="#address"
                >
                  Alamat
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTab === "3" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTab("3");
                  }}
                  href="#education"
                >
                  Pendidikan
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className={`tab-pane ${modalTab === "1" ? "active" : ""}`} id="personal">
                <Row className="gy-4">
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label" htmlFor="full-name">
                        Nama Lengkap
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="form-control"
                        name="name"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={profile && profile.name}
                        placeholder="Masukkan Nama Lengkap"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label" htmlFor="email">
                        Jenis Kelamin
                      </label>
                      <div className="g-4 align-center flex-wrap">
                        <div className="g">
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              className="custom-control-input form-control"
                              name="gender"
                              onChange={(e) => onInputChange(e)}
                              defaultChecked={profile && profile.profile && profile.profile.gender === "Laki-laki"}
                              id="Laki-laki"
                              value="Laki-laki"
                            />
                            <label className="custom-control-label" htmlFor="Laki-laki">
                            Laki-laki
                            </label>
                          </div>
                        </div>
                        <div className="g">
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              className="custom-control-input form-control"
                              name="gender"
                              onChange={(e) => onInputChange(e)}
                              defaultChecked={profile && profile.profile && profile.profile.gender === "Perempuan"}
                              id="Perempuan"
                              value="Perempuan"
                            />
                            <label className="custom-control-label" htmlFor="Perempuan">
                            Perempuan
                            </label>
                          </div>
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label" htmlFor="phone-no">
                        Nomor HP / WhatsApp
                      </label>
                      <input
                        type="number"
                        id="phone"
                        className="form-control"
                        name="phone"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={profile && profile.profile && profile.profile.phone}
                        placeholder="Masukkan Nomor HP"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label" htmlFor="birth-day">
                        Tanggal Lahir
                      </label>
                      <div className="form-control-wrap">
                        <input 
                          className="form-control" 
                          type="date" 
                          id="dob" 
                          name="dob" 
                          placeholder="Masukkan Tanggal Lahir" 
                          onChange={(e) => onInputChange(e)}
                          defaultValue={profile && profile.profile && moment(profile.profile.dob).format("YYYY-MM-DD")}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button
                          color="primary"
                          size="lg"
                          onClick={(ev) => {
                            ev.preventDefault();
                            submitForm();
                          }}
                        >
                          {loading ? <Spinner size="sm" color="light" /> : "Ubah Profil"}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#dropdownitem"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setModal(false);
                          }}
                          className="link link-light"
                        >
                          Batal
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
              <div className={`tab-pane ${modalTab === "2" ? "active" : ""}`} id="address">
                <Row className="gy-4">
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label" htmlFor="address">
                        Alamat
                      </label>
                      <input
                        type="text"
                        id="address"
                        name="address"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={profile && profile.address && profile.address.address}
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label" htmlFor="district">
                        Kecamatan
                      </label>
                      <input
                        type="text"
                        id="district"
                        name="district"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={profile && profile.address && profile.address.district}
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label" htmlFor="city">
                        Kab/Kota
                      </label>
                      <input
                        type="text"
                        id="city"
                        name="city"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={profile && profile.address && profile.address.city}
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label" htmlFor="province">
                        Provinsi
                      </label>
                      <input
                        type="text"
                        id="province"
                        name="province"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={profile && profile.address && profile.address.province}
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label" htmlFor="postalCode">
                        Kode Pos
                      </label>
                      <input
                        type="text"
                        id="postalCode"
                        name="postalCode"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={profile && profile.address && profile.address.postalCode}
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="lg" onClick={() => submitForm()}>
                          Ubah Alamat
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#dropdownitem"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setModal(false);
                          }}
                          className="link link-light"
                        >
                          Batal
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
              <div className={`tab-pane ${modalTab === "3" ? "active" : ""}`} id="education">
                <Row className="gy-4">
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label" htmlFor="identity_number">
                        NIM / NPP
                      </label>
                      <input
                        type="text"
                        id="identity_number"
                        name="identity_number"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={profile && profile.education && profile.education.identity_number}
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label" htmlFor="ipk">
                        IPK
                      </label>
                      <input
                        type="text"
                        id="ipk"
                        name="ipk"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={profile && profile.education && profile.education.ipk}
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label" htmlFor="study">
                        Prodi
                      </label>
                      <input
                        type="text"
                        id="study"
                        name="study"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={profile && profile.education && profile.education.study}
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label" htmlFor="faculty">
                      Fakultas
                      </label>
                      <RSelect
                        id="faculty"
                        name="faculty"
                        onChange={setSelectedOption}
                        defaultValue={selectedOption}
                        options={facultyOptions}
                      />                      
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="lg" onClick={() => submitForm()}>
                          Ubah Pendidikan
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#dropdownitem"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setModal(false);
                          }}
                          className="link link-light"
                        >
                          Batal
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default UserProfileRegularPage;
